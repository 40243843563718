export const sampleData = {
    "dataArray": [
      {
        "officialUrl": "https://shopsassyboutique.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPSASSYBOUTIQUE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://shopsassyboutique.com/collections/all/products/jacket?variant=45112533614824",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 47,
        "isSponsored": true,
        "createdAt": 1702434960503,        
      },
      {
        "officialUrl": "https://dibel-gentleman.de",
        "isActive": true,
        "location": null,
        "storeName": "DIBEL-GENTLEMAN",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://dibel-gentleman.de/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 1.199,
        "isSponsored": true,
        "createdAt": 1702434960507
      },
      {
        "officialUrl": "https://store.paliinstitute.com",
        "isActive": true,
        "location": null,
        "storeName": "PALIINSTITUTE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://store.paliinstitute.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 720,
        "isSponsored": true,
        "createdAt": 1702434960508
      },
      {
        "officialUrl": "https://afterlightt.com",
        "isActive": true,
        "location": null,
        "storeName": "AFTERLIGHTT",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://afterlightt.com/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 109,
        "createdAt": 1702434960515
      },
      {
        "officialUrl": "https://afterlightt.com",
        "isActive": true,
        "location": null,
        "storeName": "AFTERLIGHTT",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://afterlightt.com/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 269,
        "createdAt": 1702434960518
      },
      {
        "officialUrl": "https://divasonadimeboutique-nicole.com",
        "isActive": true,
        "location": null,
        "storeName": "DIVASONADIMEBOUTIQUE-NICOLE",
        "keyword": "Dresses",
        "productTag": "annual dress up dresses preorder",
        "productUrl": "https://divasonadimeboutique-nicole.com/products/annual-dress-up-dresses-preorder?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 15,
        "createdAt": 1702434961028
      },
      {
        "officialUrl": "https://amazingfinds4u2.com",
        "isActive": true,
        "location": null,
        "storeName": "AMAZINGFINDS4U2",
        "keyword": "Dresses",
        "productTag": "ardm sexy spaghetti strap summer dress 2023 new slip black backless maxi dress vintage elegant woman party dress princess dress",
        "productUrl": "https://amazingfinds4u2.com/products/ardm-sexy-spaghetti-strap-summer-dress-2023-new-slip-black-backless-maxi-dress-vintage-elegant-woman-party-dress-princess-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 20.87,
        "createdAt": 1702434961032
      },
      {
        "officialUrl": "https://owcollection.com",
        "isActive": true,
        "location": null,
        "storeName": "OWCOLLECTION",
        "keyword": "Dresses",
        "productTag": "ella shirt dress dress",
        "productUrl": "https://owcollection.com/products/ella-shirt-dress-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 95,
        "createdAt": 1702434961035
      },
      {
        "officialUrl": "https://wonhundred.com",
        "isActive": true,
        "location": null,
        "storeName": "WONHUNDRED",
        "keyword": "Dresses",
        "productTag": "lucinda dress dress black",
        "productUrl": "https://wonhundred.com/products/lucinda-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 120,
        "createdAt": 1702434961043
      },
      {
        "officialUrl": "https://en.minus.dk",
        "isActive": true,
        "location": null,
        "storeName": "MINUS",
        "keyword": "Dresses",
        "productTag": "genia dress dress black",
        "productUrl": "https://en.minus.dk/collections/sale/products/genia-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 42,
        "createdAt": 1702434961045
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "alice dress kids dress",
        "productUrl": "https://tokreeshop.com/products/alice-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 6200,
        "createdAt": 1702434961047
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961244
      },
      {
        "officialUrl": "https://greentechrenewablesstore.com",
        "isActive": true,
        "location": null,
        "storeName": "GREENTECHRENEWABLESSTORE",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://greentechrenewablesstore.com/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 50,
        "createdAt": 1702434961258
      },
      {
        "officialUrl": "https://greentechrenewablesstore.com",
        "isActive": true,
        "location": null,
        "storeName": "GREENTECHRENEWABLESSTORE",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://greentechrenewablesstore.com/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 672,
        "createdAt": 1702434961261
      },
      {
        "officialUrl": "https://hidjabaya.com",
        "isActive": true,
        "location": {
          "country": "Canada",
          "dial_code": "+1"
        },
        "storeName": "HIDJABAYA",
        "keyword": "activewear ",
        "productTag": "layla half print activewear",
        "productUrl": "https://hidjabaya.com/products/layla-half-print-activewear",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 14.99,
        "createdAt": 1702434961279
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear collars yuletide check",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-collars-yuletide-check",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 25,
        "createdAt": 1702434961281
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "nubyen luminance activewear leggings",
        "productUrl": "https://nubyen.com/collections/all/products/nubyen-luminance-activewear-leggings",
        "currencyInfo": null,
        "symbol": "R$",
        "currencyCode": "BRL",
        "countryOfCurrency": "Brazil",
        "nameOfCurrency": "Real",
        "estimatedPrice": 10,
        "createdAt": 1702434961282
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi holiday tartan",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-holiday-tartan",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961284
      },
      {
        "officialUrl": "https://www.modandsoul.com",
        "isActive": true,
        "location": null,
        "storeName": "MODANDSOUL",
        "keyword": "activewear ",
        "productTag": "activewear set top and leggings",
        "productUrl": "https://www.modandsoul.com/collections/all/products/activewear-set-top-and-leggings",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 65,
        "createdAt": 1702434961286
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear collar coastal cabana",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-collar-coastal-cabana",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961287
      },
      {
        "officialUrl": "https://www.nu-nude.com",
        "isActive": true,
        "location": null,
        "storeName": "NU-NUDE",
        "keyword": "activewear ",
        "productTag": "activewear leggings blush brown",
        "productUrl": "https://www.nu-nude.com/collections/all/products/activewear-leggings-blush-brown",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 38,
        "createdAt": 1702434961289
      },
      {
        "officialUrl": "https://www.allaspectsapparel.com",
        "isActive": true,
        "location": null,
        "storeName": "ALLASPECTSAPPAREL",
        "keyword": "activewear ",
        "productTag": "oblique a activewear tee graphite",
        "productUrl": "https://www.allaspectsapparel.com/collections/all/products/oblique-a-activewear-tee-graphite",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 28,
        "createdAt": 1702434961291
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi london furberry",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-london-furberry",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.99,
        "createdAt": 1702434961292
      },
      {
        "officialUrl": "https://wayart.it",
        "isActive": true,
        "location": null,
        "storeName": "WAYART",
        "keyword": "Performance sneakers",
        "productTag": "sneakers in",
        "productUrl": "https://wayart.it/products/sneakers-in",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 119.9,
        "createdAt": 1702434961382
      },
      {
        "officialUrl": "https://thescarletflower.com",
        "isActive": true,
        "location": {
          "country": "Algeria",
          "dial_code": "+213"
        },
        "storeName": "THESCARLETFLOWER",
        "keyword": "Performance sneakers",
        "productTag": "performance",
        "productUrl": "https://thescarletflower.com/collections/all/products/performance",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 309.9,
        "createdAt": 1702434961395
      },
      {
        "officialUrl": "https://www.hautediggitydog.com",
        "isActive": true,
        "location": null,
        "storeName": "HAUTEDIGGITYDOG",
        "keyword": "Tennis shoes",
        "productTag": "dogior high top tennis shoe",
        "productUrl": "https://www.hautediggitydog.com/products/dogior-high-top-tennis-shoe",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 14.95,
        "createdAt": 1702434961509
      },
      {
        "officialUrl": "https://shoptrendsetters.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPTRENDSETTERS",
        "keyword": "Tennis shoes",
        "productTag": "alta white and black tennis shoes",
        "productUrl": "https://shoptrendsetters.com/collections/all/products/alta-white-and-black-tennis-shoes",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 51,
        "createdAt": 1702434961511
      },
      {
        "officialUrl": "https://www.more-pro.com",
        "isActive": true,
        "location": null,
        "storeName": "MORE-PRO",
        "keyword": "fitness trackers",
        "productTag": "v19 ecg monitor fitness tracker",
        "productUrl": "https://www.more-pro.com/products/v19-ecg-monitor-fitness-tracker",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.9,
        "createdAt": 1702434961674
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 2",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-2",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 9.99,
        "createdAt": 1702434961677
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime kids fitness tracker 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-kids-fitness-tracker-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434961690
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime kids fitness tracker 4",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-kids-fitness-tracker-4",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 10,
        "createdAt": 1702434961693
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime kids fitness tracker 4",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-kids-fitness-tracker-4",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434961713
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 199.99,
        "createdAt": 1702434961716
      },
      {
        "officialUrl": "https://johnnywalkerelectronics.com",
        "isActive": true,
        "location": null,
        "storeName": "JOHNNYWALKERELECTRONICS",
        "keyword": "fitness trackers",
        "productTag": "full touch fitness tracker smartwatch",
        "productUrl": "https://johnnywalkerelectronics.com/collections/all/products/full-touch-fitness-tracker-smartwatch",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 34.99,
        "createdAt": 1702434961719
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 159.99,
        "createdAt": 1702434961721
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 9.99,
        "createdAt": 1702434961724
      },
      {
        "officialUrl": "https://jponteprinting.com",
        "isActive": true,
        "location": null,
        "storeName": "JPONTEPRINTING",
        "keyword": "Wearables",
        "productTag": "wearables",
        "productUrl": "https://jponteprinting.com/collections/all/products/wearables",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961730
      },
      {
        "officialUrl": "https://jponteprinting.com",
        "isActive": true,
        "location": null,
        "storeName": "JPONTEPRINTING",
        "keyword": "Wearables",
        "productTag": "wearables",
        "productUrl": "https://jponteprinting.com/collections/all/products/wearables",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961732
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961735
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "auckland wearable",
        "productUrl": "https://thecrocheting.com/products/auckland-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434961737
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961765
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961770
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961774
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961779
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434961784
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "annada wearable",
        "productUrl": "https://thecrocheting.com/products/annada-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434961789
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage defender 1981 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-defender-1981-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961892
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage demon attack1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-demon-attack1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961895
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage tanks plus 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-tanks-plus-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961897
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage q bert 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-q-bert-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961900
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage night driver 1976 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-night-driver-1976-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961915
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage missile command 1980 ntsc vintage game video game console game atari vintage game vintage atari vintage consol",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-missile-command-1980-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-consol",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961922
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage cosmic ark 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-cosmic-ark-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961925
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage tanks plus 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-tanks-plus-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961928
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage tanks plus 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console 1",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-tanks-plus-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961932
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage tanks plus 1982 ntsc vintage game video game console game atari vintage game vintage atari vintage console 1",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-tanks-plus-1982-ntsc-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari-vintage-console-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434961936
      },
      {
        "officialUrl": "https://www.laviye.com",
        "isActive": true,
        "location": null,
        "storeName": "LAVIYE",
        "keyword": "Sneakers ",
        "productTag": "african print slip on sneakers laviye green kente sneakers accra",
        "productUrl": "https://www.laviye.com/products/african-print-slip-on-sneakers-laviye-green-kente-sneakers-accra?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434961972
      },
      {
        "officialUrl": "https://www.unclehector.com",
        "isActive": true,
        "location": null,
        "storeName": "UNCLEHECTOR",
        "keyword": "Sneakers ",
        "productTag": "designer skate shoes designer sneakers stylish sneakers game boy",
        "productUrl": "https://www.unclehector.com/products/designer-skate-shoes-designer-sneakers-stylish-sneakers-game-boy",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434961976
      },
      {
        "officialUrl": "https://www.laviye.com",
        "isActive": true,
        "location": null,
        "storeName": "LAVIYE",
        "keyword": "Sneakers ",
        "productTag": "african print slip on sneakers laviye green kente sneakers accra",
        "productUrl": "https://www.laviye.com/collections/all/products/african-print-slip-on-sneakers-laviye-green-kente-sneakers-accra",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434961982
      },
      {
        "officialUrl": "https://k-arole.com",
        "isActive": true,
        "location": null,
        "storeName": "K-AROLE",
        "keyword": "Sneakers ",
        "productTag": "k arole art blue sneakers high quality sneakers stylish and comfortable",
        "productUrl": "https://k-arole.com/collections/all/products/k-arole-art-blue-sneakers-high-quality-sneakers-stylish-and-comfortable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 149.99,
        "createdAt": 1702434961990
      },
      {
        "officialUrl": "https://creativekids.com",
        "isActive": true,
        "location": null,
        "storeName": "CREATIVEKIDS",
        "keyword": "Sneakers ",
        "productTag": "creative kids sneaker painting kit complete shoe paint kit for sneakers",
        "productUrl": "https://creativekids.com/products/creative-kids-sneaker-painting-kit-complete-shoe-paint-kit-for-sneakers",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 19.99,
        "createdAt": 1702434961994
      },
      {
        "officialUrl": "https://creativekids.com",
        "isActive": true,
        "location": null,
        "storeName": "CREATIVEKIDS",
        "keyword": "Sneakers ",
        "productTag": "creative kids sneaker painting kit complete shoe paint kit for sneakers",
        "productUrl": "https://creativekids.com/products/creative-kids-sneaker-painting-kit-complete-shoe-paint-kit-for-sneakers",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 19.99,
        "createdAt": 1702434962013
      },
      {
        "officialUrl": "https://www.we-rent-motorcycles.com",
        "isActive": true,
        "location": null,
        "storeName": "WE-RENT-MOTORCYCLES",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.we-rent-motorcycles.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 15,
        "createdAt": 1702434962202
      },
      {
        "officialUrl": "https://dibel-gentleman.de",
        "isActive": true,
        "location": null,
        "storeName": "DIBEL-GENTLEMAN",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://dibel-gentleman.de/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 5.11,
        "createdAt": 1702434962207
      },
      {
        "officialUrl": "https://store.paliinstitute.com",
        "isActive": true,
        "location": null,
        "storeName": "PALIINSTITUTE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://store.paliinstitute.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 720,
        "createdAt": 1702434962210
      },
      {
        "officialUrl": "https://afterlightt.com",
        "isActive": true,
        "location": null,
        "storeName": "AFTERLIGHTT",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://afterlightt.com/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 82,
        "createdAt": 1702434962225
      },
      {
        "officialUrl": "https://afterlightt.com",
        "isActive": true,
        "location": null,
        "storeName": "AFTERLIGHTT",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://afterlightt.com/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 89,
        "createdAt": 1702434962232
      },
      {
        "officialUrl": "https://kxlnewyork.com",
        "isActive": true,
        "location": null,
        "storeName": "KXLNEWYORK",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://kxlnewyork.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 879,
        "createdAt": 1702434962236
      },
      {
        "officialUrl": "https://kxlnewyork.com",
        "isActive": true,
        "location": null,
        "storeName": "KXLNEWYORK",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://kxlnewyork.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 879,
        "createdAt": 1702434962241
      },
      {
        "officialUrl": "https://divacatwalk.com",
        "isActive": true,
        "location": null,
        "storeName": "DIVACATWALK",
        "keyword": "Dresses",
        "productTag": "dress off shoulder pencil dress",
        "productUrl": "https://divacatwalk.com/en-us/collections/all/products/dress-off-shoulder-pencil-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 160,
        "createdAt": 1702434962747
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "bambi dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/bambi-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 1800,
        "createdAt": 1702434962752
      },
      {
        "officialUrl": "https://en.minus.dk",
        "isActive": true,
        "location": null,
        "storeName": "MINUS",
        "keyword": "Dresses",
        "productTag": "genia dress dress black",
        "productUrl": "https://en.minus.dk/collections/sale/products/genia-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 42,
        "createdAt": 1702434962756
      },
      {
        "officialUrl": "https://wonhundred.com",
        "isActive": true,
        "location": null,
        "storeName": "WONHUNDRED",
        "keyword": "Dresses",
        "productTag": "aurelia dress dress black",
        "productUrl": "https://wonhundred.com/products/aurelia-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 535,
        "createdAt": 1702434962765
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "anna dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/anna-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 2900,
        "createdAt": 1702434962768
      },
      {
        "officialUrl": "https://akaberboutique.com",
        "isActive": true,
        "location": null,
        "storeName": "AKABERBOUTIQUE",
        "keyword": "Dresses",
        "productTag": "dresses elegant akaber dresses",
        "productUrl": "https://akaberboutique.com/collections/all/products/dresses-elegant-akaber-dresses",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 64.99,
        "createdAt": 1702434962771
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "alice dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/alice-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 6200,
        "createdAt": 1702434962790
      },
      {
        "officialUrl": "https://gosun.co",
        "isActive": true,
        "location": null,
        "storeName": "GOSUN",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://gosun.co/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 349,
        "createdAt": 1702434962993
      },
      {
        "officialUrl": "https://www.nabatiicecream.com",
        "isActive": true,
        "location": null,
        "storeName": "NABATIICECREAM",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://www.nabatiicecream.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 15,
        "createdAt": 1702434962997
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 21,
        "createdAt": 1702434963009
      },
      {
        "officialUrl": "https://pinkgorillagames.com",
        "isActive": true,
        "location": null,
        "storeName": "PINKGORILLAGAMES",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://pinkgorillagames.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434963013
      },
      {
        "officialUrl": "https://enertor.com",
        "isActive": true,
        "location": null,
        "storeName": "ENERTOR",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://enertor.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 19.99,
        "createdAt": 1702434963023
      },
      {
        "officialUrl": "https://www.nabatiicecream.com",
        "isActive": true,
        "location": null,
        "storeName": "NABATIICECREAM",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://www.nabatiicecream.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 60,
        "createdAt": 1702434963028
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 9,
        "createdAt": 1702434963032
      },
      {
        "officialUrl": "https://www.abskincare.com",
        "isActive": true,
        "location": null,
        "storeName": "ABSKINCARE",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://www.abskincare.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 50,
        "createdAt": 1702434963036
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear collar coastal cabana",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-collar-coastal-cabana",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434963045
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear collars petal pop",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-collars-petal-pop",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 25,
        "createdAt": 1702434963048
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "nubyen celestial activewear top",
        "productUrl": "https://nubyen.com/collections/all/products/nubyen-celestial-activewear-top",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3,
        "createdAt": 1702434963073
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "nubyen ethereal activewear shorts",
        "productUrl": "https://nubyen.com/products/nubyen-ethereal-activewear-shorts?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 29,
        "createdAt": 1702434963077
      },
      {
        "officialUrl": "https://www.allaspectsapparel.com",
        "isActive": true,
        "location": null,
        "storeName": "ALLASPECTSAPPAREL",
        "keyword": "activewear ",
        "productTag": "oblique a activewear tee black",
        "productUrl": "https://www.allaspectsapparel.com/collections/all/products/oblique-a-activewear-tee-black",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 24,
        "createdAt": 1702434963080
      },
      {
        "officialUrl": "https://cowgirldustboutique.com",
        "isActive": true,
        "location": null,
        "storeName": "COWGIRLDUSTBOUTIQUE",
        "keyword": "activewear ",
        "productTag": "activewear set top and leggings",
        "productUrl": "https://cowgirldustboutique.com/products/activewear-set-top-and-leggings",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 78,
        "createdAt": 1702434963084
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi holiday tartan",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-holiday-tartan",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434963088
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi london furberry",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-london-furberry",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.99,
        "createdAt": 1702434963092
      },
      {
        "officialUrl": "https://www.closetandbotts.com",
        "isActive": true,
        "location": null,
        "storeName": "CLOSETANDBOTTS",
        "keyword": "Tennis shoes",
        "productTag": "bensimon womens tennis shoes matcha",
        "productUrl": "https://www.closetandbotts.com/collections/all/products/bensimon-womens-tennis-shoes-matcha",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 29,
        "createdAt": 1702434963311
      },
      {
        "officialUrl": "https://www.closetandbotts.com",
        "isActive": true,
        "location": null,
        "storeName": "CLOSETANDBOTTS",
        "keyword": "Tennis shoes",
        "productTag": "bensimon womens tennis shoes curry",
        "productUrl": "https://www.closetandbotts.com/products/bensimon-womens-tennis-shoes-curry",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 34,
        "createdAt": 1702434963330
      },
      {
        "officialUrl": "https://www.closetandbotts.com",
        "isActive": true,
        "location": null,
        "storeName": "CLOSETANDBOTTS",
        "keyword": "Tennis shoes",
        "productTag": "bensimon womens tennis shoes curry",
        "productUrl": "https://www.closetandbotts.com/collections/all/products/bensimon-womens-tennis-shoes-curry",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 34,
        "createdAt": 1702434963336
      },
      {
        "officialUrl": "https://www.closetandbotts.com",
        "isActive": true,
        "location": null,
        "storeName": "CLOSETANDBOTTS",
        "keyword": "Tennis shoes",
        "productTag": "bensimon womens tennis shoes indigo",
        "productUrl": "https://www.closetandbotts.com/products/bensimon-womens-tennis-shoes-indigo",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 29,
        "createdAt": 1702434963342
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 89.99,
        "createdAt": 1702434963507
      },
      {
        "officialUrl": "https://ozthentic.com",
        "isActive": true,
        "location": null,
        "storeName": "OZTHENTIC",
        "keyword": "fitness trackers",
        "productTag": "new fitness tracker smart watch",
        "productUrl": "https://ozthentic.com/collections/all/products/new-fitness-tracker-smart-watch",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 25,
        "createdAt": 1702434963514
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 89.99,
        "createdAt": 1702434963529
      },
      {
        "officialUrl": "https://www.more-pro.com",
        "isActive": true,
        "location": null,
        "storeName": "MORE-PRO",
        "keyword": "fitness trackers",
        "productTag": "v19 ecg monitor fitness tracker",
        "productUrl": "https://www.more-pro.com/products/v19-ecg-monitor-fitness-tracker",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 57.9,
        "createdAt": 1702434963547
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 199.99,
        "createdAt": 1702434963552
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 159.99,
        "createdAt": 1702434963556
      },
      {
        "officialUrl": "https://www.more-pro.com",
        "isActive": true,
        "location": null,
        "storeName": "MORE-PRO",
        "keyword": "fitness trackers",
        "productTag": "v19 ecg monitor fitness tracker",
        "productUrl": "https://www.more-pro.com/products/v19-ecg-monitor-fitness-tracker",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.9,
        "createdAt": 1702434963560
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime kids fitness tracker 4",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-kids-fitness-tracker-4",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 10,
        "createdAt": 1702434963564
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963596
      },
      {
        "officialUrl": "https://thecrochetproject.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETPROJECT",
        "keyword": "Wearables",
        "productTag": "everyday wearables",
        "productUrl": "https://thecrochetproject.com/collections/all/products/everyday-wearables",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 24,
        "createdAt": 1702434963600
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963608
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963611
      },
      {
        "officialUrl": "https://thecrochetproject.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETPROJECT",
        "keyword": "Wearables",
        "productTag": "everyday wearables",
        "productUrl": "https://thecrochetproject.com/collections/all/products/everyday-wearables",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 8,
        "createdAt": 1702434963616
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963620
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963652
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434963657
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "auckland wearable",
        "productUrl": "https://thecrocheting.com/products/auckland-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434963661
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "abilene wearable",
        "productUrl": "https://thecrocheting.com/products/abilene-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434963665
      },
      {
        "officialUrl": "https://anbernic.com",
        "isActive": true,
        "location": null,
        "storeName": "ANBERNIC",
        "keyword": "Gaming console",
        "productTag": "anbernic rg350 rg350m rg350p protection bag for retro game console game player rg351p handheld retro game console bag",
        "productUrl": "https://anbernic.com/products/anbernic-rg350-rg350m-rg350p-protection-bag-for-retro-game-console-game-player-rg351p-handheld-retro-game-console-bag",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 79.99,
        "createdAt": 1702434963773
      },
      {
        "officialUrl": "https://shopberakiah.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPBERAKIAH",
        "keyword": "Gaming console",
        "productTag": "portable video game console 4k 2 4g wire less control wireless retro classic video game console including 10000 games",
        "productUrl": "https://shopberakiah.com/collections/all/products/portable-video-game-console-4k-2-4g-wire-less-control-wireless-retro-classic-video-game-console-including-10000-games",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 11.97,
        "createdAt": 1702434963784
      },
      {
        "officialUrl": "https://www.wcs-worldwide.com",
        "isActive": true,
        "location": {
          "country": "Singapore",
          "dial_code": "+65"
        },
        "storeName": "WCS-WORLDWIDE",
        "keyword": "Gaming console",
        "productTag": "4k hd tv video game console stick built in 3000 games ps1 arcade emulators double wireless controller u8 3d game console",
        "productUrl": "https://www.wcs-worldwide.com/collections/all/products/4k-hd-tv-video-game-console-stick-built-in-3000-games-ps1-arcade-emulators-double-wireless-controller-u8-3d-game-console",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 85,
        "createdAt": 1702434963789
      },
      {
        "officialUrl": "https://3dbalang.com",
        "isActive": true,
        "location": null,
        "storeName": "3DBALANG",
        "keyword": "Sneakers ",
        "productTag": "3d shoes sneaker clock 3d mini shoes sneaker watch with gift box",
        "productUrl": "https://3dbalang.com/products/3d-shoes-sneaker-clock-3d-mini-shoes-sneaker-watch-with-gift-box",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 19.67,
        "createdAt": 1702434963825
      },
      {
        "officialUrl": "https://rewind.com.ua",
        "isActive": true,
        "location": null,
        "storeName": "COM",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://rewind.com.ua/products/jacket",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 290,
        "createdAt": 1702434964048
      },
      {
        "officialUrl": "https://www.facebook.com",
        "isActive": true,
        "location": null,
        "storeName": "SOFITCR",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.facebook.com/sharer.php?u=https://www.sofitcr.com/products/jacket",
        "currencyInfo": null,
        "symbol": "₡",
        "currencyCode": "CRC",
        "countryOfCurrency": "Costa Rica",
        "nameOfCurrency": "Colon",
        "estimatedPrice": 17.5,
        "createdAt": 1702434964052
      },
      {
        "officialUrl": "https://www.shopfouronetwo.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPFOURONETWO",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.shopfouronetwo.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 60,
        "createdAt": 1702434964057
      },
      {
        "officialUrl": "https://www.facebook.com",
        "isActive": true,
        "location": null,
        "storeName": "SOFITCR",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.facebook.com/sharer.php?u=https://www.sofitcr.com/products/jacket",
        "currencyInfo": null,
        "symbol": "₡",
        "currencyCode": "CRC",
        "countryOfCurrency": "Costa Rica",
        "nameOfCurrency": "Colon",
        "estimatedPrice": 26,
        "createdAt": 1702434964076
      },
      {
        "officialUrl": "https://afterlightt.com",
        "isActive": true,
        "location": null,
        "storeName": "AFTERLIGHTT",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://afterlightt.com/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 89,
        "createdAt": 1702434964085
      },
      {
        "officialUrl": "https://wonhundred.com",
        "isActive": true,
        "location": null,
        "storeName": "WONHUNDRED",
        "keyword": "Dresses",
        "productTag": "lucinda dress dress black",
        "productUrl": "https://wonhundred.com/products/lucinda-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 120,
        "createdAt": 1702434964588
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "anna dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/anna-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 2900,
        "createdAt": 1702434964594
      },
      {
        "officialUrl": "https://akaberboutique.com",
        "isActive": true,
        "location": null,
        "storeName": "AKABERBOUTIQUE",
        "keyword": "Dresses",
        "productTag": "dresses elegant akaber dresses",
        "productUrl": "https://akaberboutique.com/collections/all/products/dresses-elegant-akaber-dresses",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 64.99,
        "createdAt": 1702434964608
      },
      {
        "officialUrl": "https://owcollection.com",
        "isActive": true,
        "location": null,
        "storeName": "OWCOLLECTION",
        "keyword": "Dresses",
        "productTag": "ella shirt dress dress",
        "productUrl": "https://owcollection.com/products/ella-shirt-dress-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 110,
        "createdAt": 1702434964623
      },
      {
        "officialUrl": "https://en.minus.dk",
        "isActive": true,
        "location": null,
        "storeName": "MINUS",
        "keyword": "Dresses",
        "productTag": "genia dress dress black",
        "productUrl": "https://en.minus.dk/collections/sale/products/genia-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 42,
        "createdAt": 1702434964628
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "alice dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/alice-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 6200,
        "createdAt": 1702434964632
      },
      {
        "officialUrl": "https://vagabondstitch.com",
        "isActive": true,
        "location": null,
        "storeName": "VAGABONDSTITCH",
        "keyword": "Dresses",
        "productTag": "basic dress and tunic dress",
        "productUrl": "https://vagabondstitch.com/collections/all-products/products/basic-dress-and-tunic-dress",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 6.3,
        "createdAt": 1702434964637
      },
      {
        "officialUrl": "https://en.minus.dk",
        "isActive": true,
        "location": null,
        "storeName": "MINUS",
        "keyword": "Dresses",
        "productTag": "genia dress dress black",
        "productUrl": "https://en.minus.dk/collections/sale/products/genia-dress-dress-black",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 42,
        "createdAt": 1702434964642
      },
      {
        "officialUrl": "https://www.canadianwoodworks.com",
        "isActive": true,
        "location": null,
        "storeName": "CANADIANWOODWORKS",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://www.canadianwoodworks.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 27,
        "createdAt": 1702434964832
      },
      {
        "officialUrl": "https://deepintheheartwildlife.com",
        "isActive": true,
        "location": null,
        "storeName": "DEEPINTHEHEARTWILDLIFE",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://deepintheheartwildlife.com/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 20,
        "createdAt": 1702434964837
      },
      {
        "officialUrl": "https://theboilboss.com",
        "isActive": true,
        "location": null,
        "storeName": "THEBOILBOSS",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://theboilboss.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 375,
        "createdAt": 1702434964847
      },
      {
        "officialUrl": "https://abuvdakloudz.com",
        "isActive": true,
        "location": null,
        "storeName": "ABUVDAKLOUDZ",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://abuvdakloudz.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 24.99,
        "createdAt": 1702434964851
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 9,
        "createdAt": 1702434964866
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 21,
        "createdAt": 1702434964871
      },
      {
        "officialUrl": "https://bufashion.ca",
        "isActive": true,
        "location": null,
        "storeName": "BUFASHION",
        "keyword": "activewear ",
        "productTag": "activewear set 7245 7246",
        "productUrl": "https://bufashion.ca/collections/all/products/activewear-set-7245-7246",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 216,
        "createdAt": 1702434964881
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "nubyen luminance activewear leggings",
        "productUrl": "https://nubyen.com/collections/all/products/nubyen-luminance-activewear-leggings",
        "currencyInfo": null,
        "symbol": "R$",
        "currencyCode": "BRL",
        "countryOfCurrency": "Brazil",
        "nameOfCurrency": "Real",
        "estimatedPrice": 10,
        "createdAt": 1702434964885
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "nubyen ethereal activewear top",
        "productUrl": "https://nubyen.com/collections/all/products/nubyen-ethereal-activewear-top",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 98,
        "createdAt": 1702434964888
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear st patty tartan",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-st-patty-tartan",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 25,
        "createdAt": 1702434964892
      },
      {
        "officialUrl": "https://cowgirldustboutique.com",
        "isActive": true,
        "location": null,
        "storeName": "COWGIRLDUSTBOUTIQUE",
        "keyword": "activewear ",
        "productTag": "basic seamless activewear set",
        "productUrl": "https://cowgirldustboutique.com/products/basic-seamless-activewear-set",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 34.99,
        "createdAt": 1702434964931
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear collars frosty paws",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-collars-frosty-paws",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 25,
        "createdAt": 1702434964934
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/collections/all/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 7.25,
        "createdAt": 1702434965028
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 16,
        "createdAt": 1702434965039
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 7.25,
        "createdAt": 1702434965042
      },
      {
        "officialUrl": "https://www.jgrackets.co.nz",
        "isActive": true,
        "location": {
          "country": "New Zealand",
          "dial_code": "+64"
        },
        "storeName": "JGRACKETS",
        "keyword": "Tennis shoes",
        "productTag": "asics court ff3 mens tennis shoe",
        "productUrl": "https://www.jgrackets.co.nz/collections/all/products/asics-court-ff3-mens-tennis-shoe",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 199,
        "createdAt": 1702434965140
      },
      {
        "officialUrl": "https://www.jgrackets.co.nz",
        "isActive": true,
        "location": {
          "country": "New Zealand",
          "dial_code": "+64"
        },
        "storeName": "JGRACKETS",
        "keyword": "Tennis shoes",
        "productTag": "babolat propulse ac mens tennis shoe",
        "productUrl": "https://www.jgrackets.co.nz/collections/all/products/babolat-propulse-ac-mens-tennis-shoe",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 119,
        "createdAt": 1702434965148
      },
      {
        "officialUrl": "https://grandstandsports.ie",
        "isActive": true,
        "location": null,
        "storeName": "GRANDSTANDSPORTS",
        "keyword": "Tennis shoes",
        "productTag": "adidas gamecourt 2 tennis shoe 1",
        "productUrl": "https://grandstandsports.ie/collections/all/products/adidas-gamecourt-2-tennis-shoe-1",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 18,
        "createdAt": 1702434965161
      },
      {
        "officialUrl": "https://grandstandsports.ie",
        "isActive": true,
        "location": null,
        "storeName": "GRANDSTANDSPORTS",
        "keyword": "Tennis shoes",
        "productTag": "adidas barricade mens clay tennis shoe",
        "productUrl": "https://grandstandsports.ie/collections/all/products/adidas-barricade-mens-clay-tennis-shoe",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 57,
        "createdAt": 1702434965170
      },
      {
        "officialUrl": "https://courtsidetennis.com",
        "isActive": true,
        "location": null,
        "storeName": "COURTSIDETENNIS",
        "keyword": "Tennis shoes",
        "productTag": "adidas adizero ubersonic 4 tennis shoes",
        "productUrl": "https://courtsidetennis.com/products/adidas-adizero-ubersonic-4-tennis-shoes",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 45,
        "createdAt": 1702434965175
      },
      {
        "officialUrl": "https://www.jgrackets.co.nz",
        "isActive": true,
        "location": {
          "country": "New Zealand",
          "dial_code": "+64"
        },
        "storeName": "JGRACKETS",
        "keyword": "Tennis shoes",
        "productTag": "asics court ff3 mens tennis shoe",
        "productUrl": "https://www.jgrackets.co.nz/collections/all/products/asics-court-ff3-mens-tennis-shoe",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 198.99,
        "createdAt": 1702434965178
      },
      {
        "officialUrl": "https://www.jgrackets.co.nz",
        "isActive": true,
        "location": {
          "country": "New Zealand",
          "dial_code": "+64"
        },
        "storeName": "JGRACKETS",
        "keyword": "Tennis shoes",
        "productTag": "asics court ff3 mens tennis shoe",
        "productUrl": "https://www.jgrackets.co.nz/collections/all/products/asics-court-ff3-mens-tennis-shoe",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 62.99,
        "createdAt": 1702434965182
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 159.99,
        "createdAt": 1702434965327
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 229.99,
        "createdAt": 1702434965334
      },
      {
        "officialUrl": "https://johnnywalkerelectronics.com",
        "isActive": true,
        "location": null,
        "storeName": "JOHNNYWALKERELECTRONICS",
        "keyword": "fitness trackers",
        "productTag": "full touch fitness tracker smartwatch",
        "productUrl": "https://johnnywalkerelectronics.com/collections/all/products/full-touch-fitness-tracker-smartwatch",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 34.99,
        "createdAt": 1702434965340
      },
      {
        "officialUrl": "https://ozthentic.com",
        "isActive": true,
        "location": null,
        "storeName": "OZTHENTIC",
        "keyword": "fitness trackers",
        "productTag": "new fitness tracker smart watch",
        "productUrl": "https://ozthentic.com/collections/all/products/new-fitness-tracker-smart-watch",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 25,
        "createdAt": 1702434965359
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 229.99,
        "createdAt": 1702434965364
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime kids fitness tracker 4",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-kids-fitness-tracker-4",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 10,
        "createdAt": 1702434965368
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 1",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 199.99,
        "createdAt": 1702434965373
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 2",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-2",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 89.99,
        "createdAt": 1702434965378
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 2",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-2",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 159.99,
        "createdAt": 1702434965382
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965414
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965417
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965422
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "annada wearable",
        "productUrl": "https://thecrocheting.com/products/annada-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434965426
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965430
      },
      {
        "officialUrl": "https://thecrochetproject.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETPROJECT",
        "keyword": "Wearables",
        "productTag": "everyday wearables",
        "productUrl": "https://thecrochetproject.com/collections/all/products/everyday-wearables",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 16,
        "createdAt": 1702434965435
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965467
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965473
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965476
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434965480
      },
      {
        "officialUrl": "https://shopberakiah.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPBERAKIAH",
        "keyword": "Gaming console",
        "productTag": "retro game console super console x2 pro for ps1 dc ss psp plug play game box build in 100 000 classic games support tv system",
        "productUrl": "https://shopberakiah.com/collections/all/products/retro-game-console-super-console-x2-pro-for-ps1-dc-ss-psp-plug-play-game-box-build-in-100-000-classic-games-support-tv-system",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 16.14,
        "createdAt": 1702434965576
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage swordquest fireworld 1982 vintage game video game console game atari vintage game vintage atari",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-swordquest-fireworld-1982-vintage-game-video-game-console-game-atari-vintage-game-vintage-atari",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434965583
      },
      {
        "officialUrl": "https://winterparkcollectibles.com",
        "isActive": true,
        "location": {
          "country": "Puerto Rico",
          "dial_code": "+1939"
        },
        "storeName": "WINTERPARKCOLLECTIBLES",
        "keyword": "Gaming console",
        "productTag": "atari 2600 game vintage demons to diamonds 1982 vintage game video game console game atari vintage ame vintage atari",
        "productUrl": "https://winterparkcollectibles.com/products/atari-2600-game-vintage-demons-to-diamonds-1982-vintage-game-video-game-console-game-atari-vintage-ame-vintage-atari",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 3.99,
        "createdAt": 1702434965596
      },
      {
        "officialUrl": "https://shopberakiah.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPBERAKIAH",
        "keyword": "Gaming console",
        "productTag": "super console x cube retro video game consoles pre load up to 117 000 games 70 emulators support multi players",
        "productUrl": "https://shopberakiah.com/collections/all/products/super-console-x-cube-retro-video-game-consoles-pre-load-up-to-117-000-games-70-emulators-support-multi-players",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 84.49,
        "createdAt": 1702434965599
      },
      {
        "officialUrl": "https://www.shopfouronetwo.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPFOURONETWO",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.shopfouronetwo.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 50,
        "createdAt": 1702434965871
      },
      {
        "officialUrl": "https://www.beadstore.com",
        "isActive": true,
        "location": null,
        "storeName": "BEADSTORE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.beadstore.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 2.5,
        "createdAt": 1702434965874
      },
      {
        "officialUrl": "https://store.paliinstitute.com",
        "isActive": true,
        "location": null,
        "storeName": "PALIINSTITUTE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://store.paliinstitute.com/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 720,
        "createdAt": 1702434965890
      },
      {
        "officialUrl": "https://www.face.com.gr",
        "isActive": true,
        "location": null,
        "storeName": "FACE",
        "keyword": "Jacket",
        "productTag": "jacket",
        "productUrl": "https://www.face.com.gr/collections/all/products/jacket",
        "currencyInfo": null,
        "symbol": "€",
        "currencyCode": "EUR",
        "countryOfCurrency": "Euro Member",
        "nameOfCurrency": "Euro",
        "estimatedPrice": 99,
        "createdAt": 1702434965905
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "alice dress kids dress",
        "productUrl": "https://tokreeshop.com/collections/all/products/alice-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 6200,
        "createdAt": 1702434966471
      },
      {
        "officialUrl": "https://owcollection.com",
        "isActive": true,
        "location": null,
        "storeName": "OWCOLLECTION",
        "keyword": "Dresses",
        "productTag": "ella shirt dress dress",
        "productUrl": "https://owcollection.com/products/ella-shirt-dress-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 95,
        "createdAt": 1702434966478
      },
      {
        "officialUrl": "https://tokreeshop.com",
        "isActive": true,
        "location": null,
        "storeName": "TOKREESHOP",
        "keyword": "Dresses",
        "productTag": "anna dress kids dress",
        "productUrl": "https://tokreeshop.com/products/anna-dress-kids-dress",
        "currencyInfo": null,
        "symbol": "₹",
        "currencyCode": "INR",
        "countryOfCurrency": "India",
        "nameOfCurrency": "Rupee",
        "estimatedPrice": 2900,
        "createdAt": 1702434966502
      },
      {
        "officialUrl": "https://owcollection.com",
        "isActive": true,
        "location": null,
        "storeName": "OWCOLLECTION",
        "keyword": "Dresses",
        "productTag": "ella shirt dress dress",
        "productUrl": "https://owcollection.com/products/ella-shirt-dress-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 110,
        "createdAt": 1702434966508
      },
      {
        "officialUrl": "https://divasonadimeboutique-nicole.com",
        "isActive": true,
        "location": null,
        "storeName": "DIVASONADIMEBOUTIQUE-NICOLE",
        "keyword": "Dresses",
        "productTag": "annual dress up dresses preorder",
        "productUrl": "https://divasonadimeboutique-nicole.com/products/annual-dress-up-dresses-preorder?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 15,
        "createdAt": 1702434966511
      },
      {
        "officialUrl": "https://divacatwalk.com",
        "isActive": true,
        "location": null,
        "storeName": "DIVACATWALK",
        "keyword": "Dresses",
        "productTag": "dress off shoulder pencil dress",
        "productUrl": "https://divacatwalk.com/en-us/collections/all/products/dress-off-shoulder-pencil-dress",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 160,
        "createdAt": 1702434966516
      },
      {
        "officialUrl": "https://www.abskincare.com",
        "isActive": true,
        "location": null,
        "storeName": "ABSKINCARE",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://www.abskincare.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 50,
        "createdAt": 1702434966719
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 21,
        "createdAt": 1702434966723
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434966739
      },
      {
        "officialUrl": "https://pinkgorillagames.com",
        "isActive": true,
        "location": null,
        "storeName": "PINKGORILLAGAMES",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://pinkgorillagames.com/collections/all/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 14.99,
        "createdAt": 1702434966748
      },
      {
        "officialUrl": "https://biketowleash.com",
        "isActive": true,
        "location": null,
        "storeName": "BIKETOWLEASH",
        "keyword": "Hats",
        "productTag": "hat",
        "productUrl": "https://biketowleash.com/collections/dog-biking-attachments/products/hat",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 9,
        "createdAt": 1702434966755
      },
      {
        "officialUrl": "https://nubyen.com",
        "isActive": true,
        "location": null,
        "storeName": "NUBYEN",
        "keyword": "activewear ",
        "productTag": "luminance nubyen activewear top",
        "productUrl": "https://nubyen.com/products/luminance-nubyen-activewear-top?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 59,
        "createdAt": 1702434966764
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi luck of the irish",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-luck-of-the-irish",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.99,
        "createdAt": 1702434966770
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi lots of love",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-lots-of-love",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.99,
        "createdAt": 1702434966796
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear fi watercolor hearts",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-fi-watercolor-hearts",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.99,
        "createdAt": 1702434966799
      },
      {
        "officialUrl": "https://luckyplusdog.com",
        "isActive": true,
        "location": null,
        "storeName": "LUCKYPLUSDOG",
        "keyword": "activewear ",
        "productTag": "activewear luck of the irish 1",
        "productUrl": "https://luckyplusdog.com/collections/all/products/activewear-luck-of-the-irish-1",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 25,
        "createdAt": 1702434966803
      },
      {
        "officialUrl": "https://goingflat.com",
        "isActive": true,
        "location": null,
        "storeName": "GOINGFLAT",
        "keyword": "activewear ",
        "productTag": "size m activewear jacket",
        "productUrl": "https://goingflat.com/products/size-m-activewear-jacket",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 30,
        "createdAt": 1702434966807
      },
      {
        "officialUrl": "https://bufashion.ca",
        "isActive": true,
        "location": null,
        "storeName": "BUFASHION",
        "keyword": "activewear ",
        "productTag": "activewear set 7126 7127",
        "productUrl": "https://bufashion.ca/collections/all/products/activewear-set-7126-7127",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 216,
        "createdAt": 1702434966810
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/collections/all/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 16,
        "createdAt": 1702434966904
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 7.25,
        "createdAt": 1702434966908
      },
      {
        "officialUrl": "https://grangers.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Performance sneakers",
        "productTag": "performance wash performance repel plus eco twin pack",
        "productUrl": "https://grangers.co.uk/products/performance-wash-performance-repel-plus-eco-twin-pack",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 16,
        "createdAt": 1702434966911
      },
      {
        "officialUrl": "https://www.laviye.com",
        "isActive": true,
        "location": null,
        "storeName": "LAVIYE",
        "keyword": "Performance sneakers",
        "productTag": "african print slip on sneakers laviye green kente sneakers accra",
        "productUrl": "https://www.laviye.com/collections/all/products/african-print-slip-on-sneakers-laviye-green-kente-sneakers-accra",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434966923
      },
      {
        "officialUrl": "https://www.laviye.com",
        "isActive": true,
        "location": null,
        "storeName": "LAVIYE",
        "keyword": "Performance sneakers",
        "productTag": "african print slip on sneakers laviye green kente sneakers accra",
        "productUrl": "https://www.laviye.com/products/african-print-slip-on-sneakers-laviye-green-kente-sneakers-accra?view=quick",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 99.99,
        "createdAt": 1702434966926
      },
      {
        "officialUrl": "https://www.unclehector.com",
        "isActive": true,
        "location": null,
        "storeName": "UNCLEHECTOR",
        "keyword": "Performance sneakers",
        "productTag": "designer skate shoes designer sneakers stylish sneakers game boy",
        "productUrl": "https://www.unclehector.com/products/designer-skate-shoes-designer-sneakers-stylish-sneakers-game-boy",
        "currencyInfo": null,
        "symbol": null,
        "currencyCode": null,
        "countryOfCurrency": null,
        "nameOfCurrency": null,
        "estimatedPrice": null,
        "createdAt": 1702434966944
      },
      {
        "officialUrl": "https://www.smitnbebe.com",
        "isActive": true,
        "location": null,
        "storeName": "SMITNBEBE",
        "keyword": "Tennis shoes",
        "productTag": "baby non slip toddler shoes baby shoes baby shoes",
        "productUrl": "https://www.smitnbebe.com/collections/all/products/baby-non-slip-toddler-shoes-baby-shoes-baby-shoes",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 6.24,
        "createdAt": 1702434967046
      },
      {
        "officialUrl": "https://www.findtimewatch.com",
        "isActive": true,
        "location": null,
        "storeName": "FINDTIMEWATCH",
        "keyword": "fitness trackers",
        "productTag": "findtime fitness tracker pro 3",
        "productUrl": "https://www.findtimewatch.com/collections/all/products/findtime-fitness-tracker-pro-3",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 159.99,
        "createdAt": 1702434967233
      },
      {
        "officialUrl": "https://www.more-pro.com",
        "isActive": true,
        "location": null,
        "storeName": "MORE-PRO",
        "keyword": "fitness trackers",
        "productTag": "v19 green ecg monitor fitness tracker",
        "productUrl": "https://www.more-pro.com/products/v19-green-ecg-monitor-fitness-tracker",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 59.9,
        "createdAt": 1702434967238
      },
      {
        "officialUrl": "https://www.more-pro.com",
        "isActive": true,
        "location": null,
        "storeName": "MORE-PRO",
        "keyword": "fitness trackers",
        "productTag": "v19 blue ecg monitor fitness tracker",
        "productUrl": "https://www.more-pro.com/products/v19-blue-ecg-monitor-fitness-tracker",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 39.9,
        "createdAt": 1702434967256
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967268
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "abilene wearable",
        "productUrl": "https://thecrocheting.com/products/abilene-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434967272
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967276
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967280
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967308
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "amherst wearable",
        "productUrl": "https://thecrocheting.com/products/amherst-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434967312
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967315
      },
      {
        "officialUrl": "https://thecrocheting.com",
        "isActive": true,
        "location": null,
        "storeName": "THECROCHETING",
        "keyword": "Wearables",
        "productTag": "annada wearable",
        "productUrl": "https://thecrocheting.com/products/annada-wearable",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 48,
        "createdAt": 1702434967319
      },
      {
        "officialUrl": "https://apolloneuro.com",
        "isActive": true,
        "location": null,
        "storeName": "APOLLONEURO",
        "keyword": "Wearables",
        "productTag": "apollo wearable",
        "productUrl": "https://apolloneuro.com/products/apollo-wearable?variant=41447576436933",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 70,
        "createdAt": 1702434967322
      },
      {
        "officialUrl": "https://thewoollybrew.co.uk",
        "isActive": true,
        "location": null,
        "storeName": "CO",
        "keyword": "Wearables",
        "productTag": "everyday wearables",
        "productUrl": "https://thewoollybrew.co.uk/products/everyday-wearables",
        "currencyInfo": null,
        "symbol": "£",
        "currencyCode": "GBP",
        "countryOfCurrency": "United Kingdom",
        "nameOfCurrency": "Pound",
        "estimatedPrice": 6.35,
        "createdAt": 1702434967325
      },
      {
        "officialUrl": "https://shopberakiah.com",
        "isActive": true,
        "location": null,
        "storeName": "SHOPBERAKIAH",
        "keyword": "Gaming console",
        "productTag": "sf900 retro game console hd video game stick with 1500 games for snes wireless controller 16 bit consolas de videojuegos for nes",
        "productUrl": "https://shopberakiah.com/collections/all/products/sf900-retro-game-console-hd-video-game-stick-with-1500-games-for-snes-wireless-controller-16-bit-consolas-de-videojuegos-for-nes",
        "currencyInfo": null,
        "symbol": "$",
        "currencyCode": "USD",
        "countryOfCurrency": "United States",
        "nameOfCurrency": "Dollar",
        "estimatedPrice": 2.4,
        "createdAt": 1702434967419
      }
    ]
  };