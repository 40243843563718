export const marketSummaryUS =  {    
        "marketSummaryResponse": {
            "result": [
                {
                    "fullExchangeName": "SNP",
                    "symbol": "^GSPC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720205,
                        "fmt": "2:43PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.31190652,
                        "fmt": "-0.31%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 4100.6,
                        "fmt": "4,100.60"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -12.790039,
                        "fmt": "-12.79"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": -1325583000000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 4087.81,
                        "fmt": "4,087.81"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "SNP",
                    "region": "US",
                    "shortName": "S&P 500",
                    "triggerable": false,
                    "longName": "S&P 500"
                },
                {
                    "fullExchangeName": "DJI",
                    "symbol": "^DJI",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720205,
                        "fmt": "2:43PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.24088386,
                        "fmt": "0.24%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 33402.38,
                        "fmt": "33,402.38"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 80.46094,
                        "fmt": "80.46"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 694362600000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 33482.84,
                        "fmt": "33,482.84"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 120,
                    "exchange": "DJI",
                    "region": "US",
                    "shortName": "Dow 30",
                    "triggerable": false,
                    "longName": "Dow Jones Industrial Average"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^IXIC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720203,
                        "fmt": "2:43PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.1277277,
                        "fmt": "-1.13%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 12126.327,
                        "fmt": "12,126.33"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -136.75195,
                        "fmt": "-136.75"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 34612200000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 11989.575,
                        "fmt": "11,989.58"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "NIM",
                    "region": "US",
                    "shortName": "Nasdaq",
                    "triggerable": false,
                    "longName": "NASDAQ Composite"
                },
                {
                    "fullExchangeName": "Chicago Options",
                    "symbol": "^RUT",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719305,
                        "fmt": "2:28PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.2300605,
                        "fmt": "-1.23%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 1769.6548,
                        "fmt": "1,769.65"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -21.767822,
                        "fmt": "-21.77"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 15,
                    "firstTradeDateMilliseconds": 558279000000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 1747.887,
                        "fmt": "1,747.89"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "WCB",
                    "region": "US",
                    "shortName": "Russell 2000",
                    "triggerable": false,
                    "longName": "Russell 2000"
                },
                {
                    "fullExchangeName": "NY Mercantile",
                    "symbol": "CL=F",
                    "headSymbolAsString": "CL=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719605,
                        "fmt": "2:33PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.11150581,
                        "fmt": "-0.11%"
                    },
                    "quoteType": "FUTURE",
                    "headSymbol": true,
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 80.71,
                        "fmt": "80.71"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.08999634,
                        "fmt": "-0.09"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": 967003200000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 80.62,
                        "fmt": "80.62"
                    },
                    "customPriceAlertConfidence": "NONE",
                    "marketState": "REGULAR",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "sourceInterval": 30,
                    "exchange": "NYM",
                    "region": "US",
                    "shortName": "Crude Oil",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "COMEX",
                    "symbol": "GC=F",
                    "headSymbolAsString": "GC=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719601,
                        "fmt": "2:33PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.044157807,
                        "fmt": "0.04%"
                    },
                    "quoteType": "FUTURE",
                    "headSymbol": true,
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 2038.2,
                        "fmt": "2,038.20"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 0.9000244,
                        "fmt": "0.90"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": 967608000000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 2039.1,
                        "fmt": "2,039.10"
                    },
                    "customPriceAlertConfidence": "NONE",
                    "marketState": "REGULAR",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "sourceInterval": 15,
                    "exchange": "CMX",
                    "region": "US",
                    "shortName": "Gold",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "COMEX",
                    "symbol": "SI=F",
                    "headSymbolAsString": "SI=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719603,
                        "fmt": "2:33PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.13545436,
                        "fmt": "0.14%"
                    },
                    "quoteType": "FUTURE",
                    "headSymbol": true,
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 25.101,
                        "fmt": "25.10"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 0.034000397,
                        "fmt": "0.03"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": 967608000000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 25.135,
                        "fmt": "25.14"
                    },
                    "customPriceAlertConfidence": "NONE",
                    "marketState": "REGULAR",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "sourceInterval": 15,
                    "exchange": "CMX",
                    "region": "US",
                    "shortName": "Silver",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "EURUSD=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720199,
                        "fmt": "7:43PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.49078116,
                        "fmt": "-0.49%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "USD",
                    "regularMarketPreviousClose": {
                        "raw": 1.0960106,
                        "fmt": "1.0960"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.005379081,
                        "fmt": "-0.0054"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1070236800000,
                    "exchangeTimezoneShortName": "BST",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1.0906315,
                        "fmt": "1.0906"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "exchange": "CCY",
                    "sourceInterval": 15,
                    "shortName": "EUR/USD",
                    "region": "US",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "ICE Futures",
                    "symbol": "^TNX",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719303,
                        "fmt": "2:28PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.4084415,
                        "fmt": "-1.41%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 3.337,
                        "fmt": "3.3370"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.046999693,
                        "fmt": "-0.0470"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": -252356400000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 3.2900002,
                        "fmt": "3.2900"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us24_market",
                    "priceHint": 4,
                    "sourceInterval": 30,
                    "exchange": "NYB",
                    "region": "US",
                    "shortName": "10-Yr Bond",
                    "triggerable": false,
                    "longName": "Treasury Yield 10 Years"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "GBPUSD=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720199,
                        "fmt": "7:43PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.33516705,
                        "fmt": "-0.34%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "USD",
                    "regularMarketPreviousClose": {
                        "raw": 1.2501563,
                        "fmt": "1.2502"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.0041900873,
                        "fmt": "-0.0042"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1070236800000,
                    "exchangeTimezoneShortName": "BST",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1.2459662,
                        "fmt": "1.2460"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "exchange": "CCY",
                    "sourceInterval": 15,
                    "shortName": "GBP/USD",
                    "region": "US",
                    "triggerable": false,
                    "longName": "USD/GBP"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "JPY=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720205,
                        "fmt": "7:43PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.4533478,
                        "fmt": "-0.45%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "JPY",
                    "regularMarketPreviousClose": {
                        "raw": 131.687,
                        "fmt": "131.6870"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.5970001,
                        "fmt": "-0.5970"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 846633600000,
                    "exchangeTimezoneShortName": "BST",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 131.09,
                        "fmt": "131.0900"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "exchange": "CCY",
                    "sourceInterval": 15,
                    "shortName": "USD/JPY",
                    "region": "US",
                    "triggerable": false,
                    "longName": "USD/JPY"
                },
                {
                    "fullExchangeName": "CCC",
                    "symbol": "BTC-USD",
                    "gmtOffSetMilliseconds": 0,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720060,
                        "fmt": "6:41PM UTC"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.6060655,
                        "fmt": "-0.61%"
                    },
                    "quoteType": "CRYPTOCURRENCY",
                    "typeDisp": "Cryptocurrency",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 28166.416,
                        "fmt": "28,166.42"
                    },
                    "exchangeTimezoneName": "UTC",
                    "regularMarketChange": {
                        "raw": -170.62305,
                        "fmt": "-170.62"
                    },
                    "cryptoTradeable": true,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1410912000000,
                    "exchangeTimezoneShortName": "UTC",
                    "regularMarketPrice": {
                        "raw": 27981.887,
                        "fmt": "27,981.89"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "ccc_market",
                    "quoteSourceName": "CoinMarketCap",
                    "sourceInterval": 15,
                    "exchange": "CCC",
                    "region": "US",
                    "shortName": "Bitcoin USD",
                    "triggerable": false,
                    "longName": "Bitcoin USD"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^CMC200",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720073,
                        "fmt": "2:41PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.6593931,
                        "fmt": "-1.66%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 634.2952,
                        "fmt": "634.30"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -10.525452,
                        "fmt": "-10.53"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1546266600000,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 623.7698,
                        "fmt": "623.77"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "NIM",
                    "region": "US",
                    "shortName": "CMC Crypto 200",
                    "triggerable": false,
                    "longName": "CMC Crypto 200 Index by Solacti"
                },
                {
                    "fullExchangeName": "FTSE Index",
                    "symbol": "^FTSE",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708930,
                        "fmt": "4:35PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.37225553,
                        "fmt": "0.37%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 7634.52,
                        "fmt": "7,634.52"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": 28.419922,
                        "fmt": "28.42"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 15,
                    "firstTradeDateMilliseconds": 441964800000,
                    "exchangeTimezoneShortName": "BST",
                    "regularMarketPrice": {
                        "raw": 7662.94,
                        "fmt": "7,662.94"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "POSTPOST",
                    "market": "gb_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "FGI",
                    "region": "US",
                    "shortName": "FTSE 100",
                    "triggerable": false,
                    "longName": "FTSE 100"
                },
                {
                    "fullExchangeName": "Osaka",
                    "symbol": "^N225",
                    "gmtOffSetMilliseconds": 32400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680675303,
                        "fmt": "3:15PM JST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.6762227,
                        "fmt": "-1.68%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 28287.42,
                        "fmt": "28,287.42"
                    },
                    "exchangeTimezoneName": "Asia/Tokyo",
                    "regularMarketChange": {
                        "raw": -474.16016,
                        "fmt": "-474.16"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 20,
                    "firstTradeDateMilliseconds": -157420800000,
                    "exchangeTimezoneShortName": "JST",
                    "regularMarketPrice": {
                        "raw": 27813.26,
                        "fmt": "27,813.26"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "PREPRE",
                    "market": "jp_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 20,
                    "exchange": "OSA",
                    "region": "US",
                    "shortName": "Nikkei 225",
                    "triggerable": false,
                    "longName": "Nikkei 225"
                }
            ],            
        }    
};