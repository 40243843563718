import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/index'; 

// ===========================================================================================================================================================================================
// import all components that should be shown globally throughout the app: 
import User from './User';
import Navbar from './Navbar';
import FinalLayout from './FinalLayout'; 
import DisplayQuotes from './DisplayQuotes'; 
// import FullPageLoading from './FullPageLoading'; 

// ===========================================================================================================================================================================================
// import global CSS here:
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Custom Global CSS
import './App.css';

// ===========================================================================================================================================================================================
// header for entire application 
// set meta data for header here
// refer to react helmet if needed

const Header = ({    
}) => {      
  useEffect(() => {          
  },[])    
  return (
      <>         
          {/* <div className="App">        
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Edit <code>src/App.js</code> and save to reload.
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
            </a>
        </header>                    
       </div>  */}               
      </>
)}; 

function App() {  
  // learning points:
  // cannot use useSelector in App.js
  // Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>
  // so the solution is to use conditional rendering in the actual component itself where they listen to useSelector
  // or best to follow previous structure to have <Layout> then <Landing> and <Dashboard>    
  return (
    <>        
    <Provider store={store}>      
      <Header/>
      <User/>
      {/* {          
        // learning points:
        // we should NOT need to wrap FullPageLoading component with loading boolean here 
        // instead it will listen to redux itself 
        // loading={loading} // loading is handled within the ccomponent no need to pass in props
        <FullPageLoading          
          type={'circles'}              
        />
      } */}
      <Navbar/>                       
      {/* <DisplayQuotes/>         */}
      <FinalLayout/>         
    </Provider>
    </>        
  );
}

export default App;
