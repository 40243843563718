export const marketSummaryJP =  {    
        "marketSummaryResponse": {
            "result": [
                {
                    "fullExchangeName": "SNP",
                    "symbol": "^GSPC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720266,
                        "fmt": "2:44PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.34605142,
                        "fmt": "-0.35%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 4100.6,
                        "fmt": "4,100.60"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -14.190186,
                        "fmt": "-14.19"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": -1325583000000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 4086.41,
                        "fmt": "4,086.41"
                    },
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "exchange": "SNP",
                    "sourceInterval": 15,
                    "shortName": "S&P 500",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "S&P 500"
                },
                {
                    "fullExchangeName": "DJI",
                    "symbol": "^DJI",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720266,
                        "fmt": "2:44PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.2151325,
                        "fmt": "0.22%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 33402.38,
                        "fmt": "33,402.38"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 71.859375,
                        "fmt": "71.86"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 694362600000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 33474.24,
                        "fmt": "33,474.24"
                    },
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "exchange": "DJI",
                    "sourceInterval": 120,
                    "shortName": "Dow 30",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "Dow Jones Industrial Average"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^IXIC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720265,
                        "fmt": "2:44PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.169379,
                        "fmt": "-1.17%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 12126.327,
                        "fmt": "12,126.33"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -141.80273,
                        "fmt": "-141.80"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 34612200000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 11984.524,
                        "fmt": "11,984.52"
                    },
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "exchange": "NIM",
                    "sourceInterval": 15,
                    "shortName": "Nasdaq",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "NASDAQ Composite"
                },
                {
                    "fullExchangeName": "Chicago Options",
                    "symbol": "^RUT",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719365,
                        "fmt": "2:29PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.2465672,
                        "fmt": "-1.25%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 1769.6548,
                        "fmt": "1,769.65"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -22.059937,
                        "fmt": "-22.06"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 15,
                    "firstTradeDateMilliseconds": 558279000000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1747.5948,
                        "fmt": "1,747.59"
                    },
                    "market": "us_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "exchange": "WCB",
                    "sourceInterval": 15,
                    "shortName": "Russell 2000",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "Russell 2000"
                },
                {
                    "fullExchangeName": "NY Mercantile",
                    "symbol": "CL=F",
                    "headSymbolAsString": "CL=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719662,
                        "fmt": "2:34PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.09912258,
                        "fmt": "-0.10%"
                    },
                    "headSymbol": true,
                    "quoteType": "FUTURE",
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 80.71,
                        "fmt": "80.71"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.08000183,
                        "fmt": "-0.08"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 967003200000,
                    "exchangeDataDelayedBy": 10,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 80.63,
                        "fmt": "80.63"
                    },
                    "marketState": "REGULAR",
                    "customPriceAlertConfidence": "NONE",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "exchange": "NYM",
                    "sourceInterval": 30,
                    "shortName": "Crude Oil",
                    "region": "JP",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "COMEX",
                    "symbol": "GC=F",
                    "headSymbolAsString": "GC=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719661,
                        "fmt": "2:34PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.014721266,
                        "fmt": "0.01%"
                    },
                    "headSymbol": true,
                    "quoteType": "FUTURE",
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 2038.2,
                        "fmt": "2,038.20"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 0.30004883,
                        "fmt": "0.30"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 967608000000,
                    "exchangeDataDelayedBy": 10,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 2038.5,
                        "fmt": "2,038.50"
                    },
                    "marketState": "REGULAR",
                    "customPriceAlertConfidence": "NONE",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "exchange": "CMX",
                    "sourceInterval": 15,
                    "shortName": "Gold",
                    "region": "JP",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "COMEX",
                    "symbol": "SI=F",
                    "headSymbolAsString": "SI=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719662,
                        "fmt": "2:34PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.13545436,
                        "fmt": "0.14%"
                    },
                    "headSymbol": true,
                    "quoteType": "FUTURE",
                    "typeDisp": "Futures",
                    "tradeable": false,
                    "contractSymbol": false,
                    "regularMarketPreviousClose": {
                        "raw": 25.101,
                        "fmt": "25.10"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 0.034000397,
                        "fmt": "0.03"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 967608000000,
                    "exchangeDataDelayedBy": 10,
                    "exchangeTimezoneShortName": "EDT",
                    "regularMarketPrice": {
                        "raw": 25.135,
                        "fmt": "25.14"
                    },
                    "marketState": "REGULAR",
                    "customPriceAlertConfidence": "NONE",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "exchange": "CMX",
                    "sourceInterval": 15,
                    "shortName": "Silver",
                    "region": "JP",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "EURUSD=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720259,
                        "fmt": "7:44PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.49078116,
                        "fmt": "-0.49%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "USD",
                    "regularMarketPreviousClose": {
                        "raw": 1.0960106,
                        "fmt": "1.0960"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.005379081,
                        "fmt": "-0.0054"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 1070236800000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "BST",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1.0906315,
                        "fmt": "1.0906"
                    },
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "sourceInterval": 15,
                    "exchange": "CCY",
                    "region": "US",
                    "shortName": "EUR/USD",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "ICE Futures",
                    "symbol": "^TNX",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680719363,
                        "fmt": "2:29PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.4084415,
                        "fmt": "-1.41%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 3.337,
                        "fmt": "3.3370"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.046999693,
                        "fmt": "-0.0470"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": -252356400000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 3.2900002,
                        "fmt": "3.2900"
                    },
                    "market": "us24_market",
                    "priceHint": 4,
                    "exchange": "NYB",
                    "sourceInterval": 30,
                    "shortName": "10-Yr Bond",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "Treasury Yield 10 Years"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "GBPUSD=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720259,
                        "fmt": "7:44PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.33764654,
                        "fmt": "-0.34%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "USD",
                    "regularMarketPreviousClose": {
                        "raw": 1.2501563,
                        "fmt": "1.2502"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.0042210817,
                        "fmt": "-0.0042"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 1070236800000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "BST",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1.2459352,
                        "fmt": "1.2459"
                    },
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "sourceInterval": 15,
                    "exchange": "CCY",
                    "region": "US",
                    "shortName": "GBP/USD",
                    "triggerable": false,
                    "longName": "USD/GBP"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "JPY=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720266,
                        "fmt": "7:44PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.44879407,
                        "fmt": "-0.45%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "JPY",
                    "regularMarketPreviousClose": {
                        "raw": 131.687,
                        "fmt": "131.6870"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.5910034,
                        "fmt": "-0.5910"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 846633600000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "BST",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 131.096,
                        "fmt": "131.0960"
                    },
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "sourceInterval": 15,
                    "exchange": "CCY",
                    "region": "US",
                    "shortName": "USD/JPY",
                    "triggerable": false,
                    "longName": "USD/JPY"
                },
                {
                    "fullExchangeName": "CCC",
                    "symbol": "BTC-USD",
                    "gmtOffSetMilliseconds": 0,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720120,
                        "fmt": "6:42PM UTC"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.6870777,
                        "fmt": "-0.69%"
                    },
                    "quoteType": "CRYPTOCURRENCY",
                    "typeDisp": "Cryptocurrency",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 28166.416,
                        "fmt": "28,166.42"
                    },
                    "exchangeTimezoneName": "UTC",
                    "regularMarketChange": {
                        "raw": -193.56445,
                        "fmt": "-193.56"
                    },
                    "cryptoTradeable": true,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1410912000000,
                    "exchangeTimezoneShortName": "UTC",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 27978.332,
                        "fmt": "27,978.33"
                    },
                    "market": "ccc_market",
                    "quoteSourceName": "CoinMarketCap",
                    "exchange": "CCC",
                    "sourceInterval": 15,
                    "shortName": "Bitcoin USD",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "Bitcoin USD"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^CMC200",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680720243,
                        "fmt": "2:44PM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.7804829,
                        "fmt": "-1.78%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 634.2952,
                        "fmt": "634.30"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -11.293518,
                        "fmt": "-11.29"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1546266600000,
                    "exchangeTimezoneShortName": "EDT",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 623.0017,
                        "fmt": "623.00"
                    },
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "exchange": "NIM",
                    "sourceInterval": 15,
                    "shortName": "CMC Crypto 200",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "CMC Crypto 200 Index by Solacti"
                },
                {
                    "fullExchangeName": "FTSE Index",
                    "symbol": "^FTSE",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708930,
                        "fmt": "4:35PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.37225553,
                        "fmt": "0.37%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 7634.52,
                        "fmt": "7,634.52"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": 28.419922,
                        "fmt": "28.42"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 15,
                    "firstTradeDateMilliseconds": 441964800000,
                    "exchangeTimezoneShortName": "BST",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "POSTPOST",
                    "regularMarketPrice": {
                        "raw": 7662.94,
                        "fmt": "7,662.94"
                    },
                    "market": "gb_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "exchange": "FGI",
                    "sourceInterval": 15,
                    "shortName": "FTSE 100",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "FTSE 100"
                },
                {
                    "fullExchangeName": "Osaka",
                    "symbol": "^N225",
                    "gmtOffSetMilliseconds": 32400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680675303,
                        "fmt": "3:15PM JST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.6762227,
                        "fmt": "-1.68%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 28287.42,
                        "fmt": "28,287.42"
                    },
                    "exchangeTimezoneName": "Asia/Tokyo",
                    "regularMarketChange": {
                        "raw": -474.16016,
                        "fmt": "-474.16"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 20,
                    "firstTradeDateMilliseconds": -157420800000,
                    "exchangeTimezoneShortName": "JST",
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "PREPRE",
                    "regularMarketPrice": {
                        "raw": 27813.26,
                        "fmt": "27,813.26"
                    },
                    "market": "jp_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "exchange": "OSA",
                    "sourceInterval": 20,
                    "shortName": "Nikkei 225",
                    "region": "JP",
                    "triggerable": false,
                    "longName": "Nikkei 225"
                }
            ],
            "error": null
        }    
}