import { ACTION_TYPES } from './actionTypes';

// Methods to ensure immutability 
// The following table outlines the basic array methods:
// Safe methods	Mutating methods
// concat()	    push()
// slice()	    splice()
// map()	      pop()
// reduce()	    shift()
// reduceRight()	unshift()
// filter()	    fill()
// reverse()
// sort()
// copyWithin()

export const initialState = {        
    // user    
    uuid: null,
    profileUrl: null,
    username: null,
    loginTime: null,
    providerId: null,    
    // profile    
    profile: null,
    // learning points: 
    // it is better to use flat data structure nnot nested objects as 
    // there were issues recognising updates to nested objects in redux
    // user preferences
    // const steps = [`companyType`, `location`, `companySize`, `sector`, `industry`, `subIndustry`, `operationType`];
    // userPref: {
    //   "companyType": [],
    //   "location": [],
    //   "companySize": [],
    //   "sector": [],
    //   "industry": [],      
    //   "subIndustry": [],
    //   "operationType": [],
    // },    
    // learning points:
    // dont us elocation as keyword is taken
    companyType: [],
    companyLocation: [],
    companySize: [],
    revenueSize: 0,
    staffSize: 0,    
    sector: [],
    industry: [],      
    subIndustry: [],
    operationType: [],
    operationKeywords: [],
    otherKeywords: [],
    companyNames: [],
    companyDomains: [],
    // payment
    memberSince: null,
    isMonthlyMember: null,
    subDays: 0,
    // user interface UI     
    closeProfileModal: false, // replaces /profile page
    closePricingModal: false, // replaces /pricing page
    closeAllModals: false,
    loading: false, // for page loading 
    loadingTypes: [], // for component level loading eg GithubLoginButton will listen for this to check if the string 'loginPopup' is inside the array    
    // clear notifications message eevrytime user clicks away notification modal
    notifyMessage: ``,
    isNewUser: false,
};

export const reducer = (state = initialState, action) => {
  const { payload } = action; 
  console.log("log action in reducer", action, payload);
  switch (action.type) {        
    // UI reducers ================================================================================================================
    // DO NOT PERSIST any UI reducers!!!
    // each modal component should be listenening for this slice of the reducer to change
    // START_PAGE_LOADING will see this between pages 
    // START_LOADING records the part of the application that is currently in loading state 
    // for example, state.loadingTypes contains all the sections of the applications that is currently in loading state
    case ACTION_TYPES.START_PAGE_LOADING:      
      return {
          ...state,
          loading: payload,          
      };  
      case ACTION_TYPES.FINISHED_PAGE_LOADING:
      return {
          ...state,
          loading: payload,
      };
      // each modal component should be listenening for this slice of reducer to change
    case ACTION_TYPES.START_LOADING:
      return {
          ...state,          
          loadingTypes: state.loadingTypes.concat([action.meta.label])
      };  
      case ACTION_TYPES.FINISHED_LOADING:
      return {
          ...state,          
          loadingTypes: state.loadingTypes.length > 0 ? state.loadingTypes.filter(label => label !== action.meta.label) : state.loadingTypes
      };      
      // each modal component should be listenening for this slice of reducer to change      
      // all modals listening oto this will close immediately 
      // note: on page refresh all modals will be closed anyways           
      case ACTION_TYPES.CLOSE_ALL_MODALS:      
      return {
        ...state,
        closeAllModals: payload, 
      };
    // imported from closet oracle Auth reducers =======================================================================================================
    // payment will be handled by listening to the member status in user profile    
    // dashboard will display different message based on whether user is new or not 
    // update 14feb23: 
    // just persist whole object of payload for now in local storage 
    case ACTION_TYPES.REGISTER_SUCCESS:
    case ACTION_TYPES.LOGIN_SUCCESS:  
      return {
        ...state,
        ...payload,
        // user: payload,
        // is_new_user: payload.isNewUser, 
        // sign_in_method: payload.signInMethod,
        // sign_in_time: payload.loginTime,
        // member_since: payload.creationTime,
        // display_name: payload.displayName,
        // photo_url: payload.profilePhotoURL,
        // email: payload.email,
        // current_user_id: payload.firebaseID,
        // is_authenticated: payload.firebaseID ? true : false,
        // provider_id: payload.providerId,
      };          
    case ACTION_TYPES.REGISTER_FAIL:
    case ACTION_TYPES.LOGIN_FAIL:
      localStorage.clear();
      return {
        ...initialState,
      };
    case ACTION_TYPES.LOGOUT:
      // optimisation point: 
      // in a future version we could save this in localstorage AFTER localStorage.clear() 
      // then use rememberLastUrlPath to take user back to where they left off 
      // isLogout: true,
      // loggedOutAt: loggedOutAt,
      // rememberLastUrlPath: rememberLastUrlPath
        localStorage.clear();
        return {
          ...initialState,
        };
    // profile reducer ========================================================================================================================
    // get profile, listen for updates?    
    // use getServerSideProps to go to payment success page with no caching header so every page refresh will get new data from firebase
    // or add delay button on client side - better to use getServerSide    
    case ACTION_TYPES.GET_PROFILE:       
    return {        
      ...state,      
      profile: payload,          
    };     
    // user preference reducer ========================================================================================================================       
    case ACTION_TYPES.ADD_COMPANY_TYPE:
    return {        
      ...state,      
      companyType: state.companyType.concat(payload),
    };            
    case ACTION_TYPES.REMOVE_COMPANY_TYPE:
    return {        
      ...state,      
      companyType: payload,
    };
    // ========================================================================================================================    
    case ACTION_TYPES.ADD_COMPANY_LOCATION:
    return {        
      ...state,      
      companyLocation: state.companyLocation.concat(payload),
    };            
    case ACTION_TYPES.REMOVE_COMPANY_LOCATION:
    return {        
      ...state,      
      companyLocation: payload,
    };
    // ========================================================================================================================    
    // add operation keywords
    case ACTION_TYPES.ADD_COMPANY_OPERATION_KEYWORDS:
    return {        
      ...state,      
      operationKeywords: state.operationKeywords.concat(payload),
    };            
    case ACTION_TYPES.REMOVE_COMPANY_OPERATION_KEYWORDS:
    return {        
      ...state,      
      operationKeywords: payload,
    };        
    // ========================================================================================================================    
    // add other keywords
    case ACTION_TYPES.ADD_COMPANY_OTHER_KEYWORDS:
    return {        
      ...state,      
      otherKeywords: state.otherKeywords.concat(payload),
    };            
    // when updating an array we are simply replacing the whole array
    case ACTION_TYPES.REMOVE_COMPANY_OTHER_KEYWORDS:
    return {        
      ...state,      
      otherKeywords: payload,
    };        
    // ========================================================================================================================    
    // this will include sector, indujstry and subindustry keywords 
    case ACTION_TYPES.ADD_COMPANY_SECTOR:
    return {        
      ...state,      
      sector: state.sector.concat(payload),
    };            
    case ACTION_TYPES.REMOVE_COMPANY_SECTOR:
    return {        
      ...state,      
      sector: payload,
    };    
    // ========================================================================================================================    
    case ACTION_TYPES.ADD_COMPANY_REV_SIZE:
    return {        
      ...state,      
      revenueSize: payload,
    };    
    case ACTION_TYPES.ADD_COMPANY_STAFF_SIZE:
    return {        
      ...state,      
      staffSize: payload,
    };      
    // ========================================================================================================================    
    case ACTION_TYPES.ADD_COMPANY_NAMES:
    return {        
      ...state,      
      companyNames: state.companyNames.concat(payload),
    };    
    case ACTION_TYPES.REMOVE_COMPANY_NAMES:
    return {        
      ...state,      
      companyNames: payload,
    };      
    // ========================================================================================================================    
    case ACTION_TYPES.ADD_COMPANY_DOMAINS:
      return {        
        ...state,      
        companyDomains: state.companyDomains.concat(payload),
      };    
      case ACTION_TYPES.REMOVE_COMPANY_DOMAINS:
      return {        
        ...state,      
        companyDomains: payload,
      };      
    // clear all filters ========================================================================================================================    
    case ACTION_TYPES.CLEAR_ALL_FILTERS:
    return {        
      ...state,     
      companyType: [], 
      companyLocation: [],
      companySize: [],
      revenueSize: 0,
      staffSize: 0,    
      sector: [],
      industry: [],      
      subIndustry: [],
      operationType: [],
      operationKeywords: [],
      otherKeywords: [],
      companyNames: [],
      companyDomains: [],
    };            
    // ========================================================================================================================            
    // notifcation modal actions
    case ACTION_TYPES.ADD_NOTIFICATION:
      return {        
        ...state,      
        notifyMessage: payload,
      }; 
    case ACTION_TYPES.REMOVE_NOTIFICATION:
    return {        
      ...state,      
      notifyMessage: payload,
    };          
    // replenish reducers ========================================================================================================================    
    // replenish redux data from local storage
    case ACTION_TYPES.REPLENISH_REDUX:
      return {
        ...state,
        ...payload, // replenish all data
      };    
    default:
        return state; 
  };
}; 
