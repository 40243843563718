import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { escape } from 'html-escaper';

class ValidateService {
  constructor() {    
    this.whiteListObj = {
      "firebaseID": "KAOGFxkIJRNF5k2qYvp1mGHzvZX2",      
      "username": "team@gmail.com",                              
      "isMonthlyMember": false,
      "isNewUser": true,                        
      "customerID": null,                  
      "providerId": "password",
      "creationTime": "1678695264009",                                        
      "lastLogInTime": "1678695264009",
      "location": null,
      "userPref": {
          companyType: [],
          companyLocation: [],
          companySize: [],
          revenueSize: 0,
          staffSize: 0,    
          sector: [],
          industry: [],      
          subIndustry: [],
          operationType: [],
          operationKeywords: [],
          otherKeywords: [],
          companyNames: [],
          companyDomains: [],
      },            
    };   
}

removeAllPunctuations = (str) => {
  let funcName = `removeAllPunctuations`;
  try {      
      if(!str) return 'empty args';                                
      let punctuationless = str.replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '');
      let finalString = punctuationless.replace(/\s{2,}/g," ");
      console.log(`log finalString ${funcName}`, finalString);
      return finalString;
  } catch (err) {
      console.log('error', `At ${Date.now()} in ${funcName} error code: ${err}`);
  }
};

extractRootDomain = async (url) => {  
  let funcName = `extractRootDomain`;
  return new Promise(async (resolve, reject) => {
    try {      
        if(isEmpty(url)) return resolve('url arg empty');
        let internetProtocol = `https://`;      
        let re = /(?:https?):\/\/((?:www\.)?(?:[a-z-]+)\.\w*)(?:\/[a-z\.]+)?/ig // https only      
        // =================================================================
        // if user has not pasted in a full url then rootDomain1 would be null
        let rootDomain1 = url.match(re);      
        console.log(`log rootDomain1`, rootDomain1);
        if(!rootDomain1) return resolve(rootDomain1);
        // =================================================================
        let rootDomain2 = rootDomain1[0].split('/');
        if(!rootDomain2.length) return resolve(false);
        let rootDomain = internetProtocol.concat(rootDomain2[2]);      
        return resolve(rootDomain);      
    } catch (err) {                                            
        console.log('error', `At ${Date.now()} in ${funcName} error code: ${err}`);
        reject(err);
    };        
  }); 
}; 

isValidEmail = (str) => {
  let funcName = `isValidEmail`;
  try {      
    let re = /^[\w"]+[\w.!#$%&’*+\/=?^_`{|}~-].@[\w-\[]+(?:\.[\w-\]]+)+$/;      
    let re2 = /(.com)/gi;
    let test1 = str.match(re);
    let test2 = str.match(re2);
    if (!test1) {        
      return false;
    }
    if (!test2) {        
      return false;
    }      
    return true;
  } catch (err) {      
    console.error(`err in ${funcName}: ${err}`);
  }
}; 

validateString = (data, key) => {
    let funcName = `validateString`;
    try {
        if(isEmpty(key)) return 'empty args';
        let goodData                    
        // ============================================
        // check if it is an empty string first        
        if(!data) {                                        
            let validateMsg = `dataType for key: ${key} is an empty string`;
            let res = false 
            return {res, validateMsg, key, data};          
        }                   
        // ============================================
        // check if it is email or username
          if(key === 'username') {            
            let res = this.isValidEmail(data);            
            if(!res) {
              let validateMsg = `dataType for key: ${key} is an invalid email`;
              let res = false 
              console.log('log isValidEmail res', res, validateMsg, key, data);
              return {res, validateMsg, key, data};
            } else {
              // ============================================
              // Finish validation here for email as we do not want to remove . and @ from the email this will cause an invalidation                            
              let validateMsg = `dataType for key: ${key} is ok`;
              let res = true
              console.log('log isValidEmail res', res, validateMsg, key, data);
              return {res, validateMsg, key, data};
            }          
          }                     
          // ============================================
          // finally check for weird symbols and punctuations which we remove all 
          let cleanedData = this.removeAllPunctuations(data);
          console.log('log removeAllPunctuations cleanedData', cleanedData);
          if(!cleanedData) {
              let validateMsg = `dataType for key: ${key} is an empty string after removing all punctuations`;
              let res = false               
              return {res, validateMsg, key, data};
          }                    
          goodData = escape(cleanedData);          
          console.log('log goodData cleanedData equality check', goodData, cleanedData, isEqual(goodData, cleanedData));
          if(!isEqual(goodData, cleanedData)) {            
            let validateMsg = `dataType for key: ${key} is not the same after escaping please remove all symbols`;
            let res = false
            return {res, validateMsg, key, data};
          };
          let validateMsg = `dataType for key: ${key} is ok`;
          let res = true
          // goodData = `goodData` // for testing if function really reaplces all data
          return {res, validateMsg, key, goodData};
      } catch (err) {
        console.log('error', `At ${Date.now()} in ${funcName} error code: ${err}`);
    }
}; 

// ==========================================================================================
// whitelist data 

whiteListDataObj = (dataObj) => {
  let funcName = `whiteListDataObj`;
  try {      
      if(isEmpty(dataObj)) return 'empty args';      
      let data = {};            
      let whiteList = Object.keys(this.whiteListObj);
      console.log(`log data ${funcName}`, dataObj, whiteList);      
      for (let property in dataObj) {
        if (dataObj.hasOwnProperty(property) &&
            whiteList.indexOf(property) !== -1) {                
            data[property] = dataObj[property];
        }
      };
      return data;
  } catch (err) {
      console.log('error', `At ${Date.now()} in ${funcName} error code: ${err}`);
  }
};

  validateObj(key, dataType, dataObj) {        
    let funcName = `validateObj`;
    try {
      console.log(`log inmput data for validateObj`, key, dataType);
        // ============================================================        
        if(isEmpty(key)) return 'empty args';
        // ============================================================        
        // handle null values:
        if(typeof dataType === 'object' && dataType === null) {          
          let validateMsg = `dataType for key: ${key} is null and ok`;
          let res = true
          return {res, validateMsg, key, dataType};
        }
        // ============================================================
        // handle undefined values:
        if(typeof dataType === 'undefined' && dataType === undefined) {          
          let validateMsg = `dataType for key: ${key} is undefined and ok`;
          let res = true          
          return {res, validateMsg, key, dataType};
        }
        // ============================================================
        // handle NaNs:        
        if(Number.isNaN(dataType)) {            
            let validateMsg = `dataType for key: ${key} is NaN and ok`;
            let res = true            
            return {res, validateMsg, key, dataType};
        }        
        // ============================================================
        // handle numbers:         
        if(typeof dataType === 'number') {
          console.log(`log dataType is number`, dataType, key);
            // return false;
        }
        // ================================================================
        // start boolean validation
        if(typeof data === 'boolean') {          
          if(key === "isMonthlyMember" || key === "isNewUser") {            
            console.log("isMonthlyMember or isNewUser validated ok");
          } else {                        
            if(key === "isMonthlyMember") {              
              dataObj["isMonthlyMember"] = false;
            }
            if(key === "isNewUser") {
              dataObj["isNewUser"] = true;
            }
          }
        }
        // ============================================================
        // handle strings and strings that are just numbers:         
        if(typeof dataType === 'string') {          
          // ================================================================
          if(key === "creationTime" || key === "lastLogInTime") {
            let parsedStr = parseInt(dataType);
            if(typeof parsedStr !== 'number') {                 
              if(key === "creationTime") {
                dataObj["creationTime"] = `${Date.now()}`;
                let goodData = escape(dataObj["creationTime"]);
                dataObj["creationTime"] = goodData;
              }
              if(key === "lastLogInTime") {
                dataObj["lastLogInTime"] = `${Date.now()}`;
                let goodData = escape(dataObj["lastLogInTime"]);
                dataObj["lastLogInTime"] = goodData;
              }                
            }             
          }
          // ================================================================
          if(key === "username") {                
            // for usernames
            let validateStringRes = this.validateString(dataType, key);
            let { res, validateMsg, data } = validateStringRes;
            dataObj[key] = data;
            return {res, validateMsg, key, dataType};
          }        
          // ================================================================
            // validate general strings here:
            // let validateStringRes = this.validateString(dataType, key);
            // let { res, validateMsg, goodData } = validateStringRes;
            // console.log(`log validateString`, validateStringRes, res, validateMsg, goodData);
            // dataObj[key] = goodData;
            // return {res, validateMsg, key, dataType};
          // ================================================================
          // validate general strings here:          
          let validateStringRes = this.validateString(dataType, key);
          let { res, validateMsg, goodData } = validateStringRes;          
          console.log(`log validateString`, validateStringRes, res, validateMsg, goodData);          
          if(!res) { 
            return {res, validateMsg, key, dataType};
          } else {                                    
            dataObj[key] = goodData;
            return {res, validateMsg, key, dataType};
          }      
        }         
        // ============================================================
        // handle arrays:        
        if(typeof dataType === 'object' && Array.isArray(dataType)) {          
          for(let j = 0; j < dataType.length; j++) {
            let item = dataType[j]
            console.log(`log each item in array`, item);            
            if(Array.isArray(item)) {
              let isItArray = Array.isArray(item);
              if(!isItArray) {
                let validateMsg = `dataType for key: ${key} is not an array`;
                let res = false 
                return {res, validateMsg, key, dataType};
              }
              let isLenTwo = item.length === 3;
              if(!isLenTwo) {
                let validateMsg = `dataType for key: ${key} is not of length 2`;
                let res = false 
                return {res, validateMsg, key, dataType};
              }                            
              for(let i = 0; i < item.length; i++) {
                let value = item[i];
                let isTypeString = typeof value === 'string';
                if(!isTypeString) {
                  let validateMsg = `dataType for key: ${key} is not of type string`;
                  let res = false 
                  return {res, validateMsg, key, dataType};
                }                
                let validateStringRes = this.validateString(value, key);
                let { res, validateMsg, goodData } = validateStringRes;
                console.log(`result of checking companyDomains!`, validateStringRes, res, validateMsg, goodData);
                if(!res) {                  
                  return {res, validateMsg, key, dataType};
                } else {
                  item[i] = goodData;
                }
              }                          
            } 
            // ================================================================================================================================================
            if(typeof item === 'string') {
              let validateStringRes = this.validateString(item, key);
              let { res, validateMsg, goodData } = validateStringRes;
              console.log(`result of checking companyDomains!`, validateStringRes, res, validateMsg, goodData);
              if(!res) {                  
                return {res, validateMsg, key, dataType};
              } else {
                dataType[j] = goodData;
              }
            }                                         
          }  
          console.log(`assume add data inside array is okay for now !`);                    
          let validateMsg = `all inner arrays for key: ${key} is ok`;
          let res = true
          return {res, validateMsg, key, dataType};
        }
        // ============================================================
        // handle objects:         
        if(typeof dataType === 'object' && !Array.isArray(dataType)) {
          console.log(`log nested object`, dataType);
          for(const validateKey in dataType) {
            let validateObjRes =  this.validateObj(validateKey, dataType[validateKey]);
            console.log(`log nested object validateKey ${validateKey}`);
            console.log(`log nested object results`, validateObjRes);
            let { res , validateMsg } = validateObjRes;
            if(!res) {
                return { res, validateMsg, validateKey, dataType };
            }
          }
        }
        // ============================================================
        // this code block should NOT be executed !
        let everythingOkObj = {
          res: true,
          validateMsg: 'ok',
          validateKey: key,
          dataType: dataType
        }     
        return everythingOkObj;
      } catch (err) {
        console.error(`err in ${funcName}: ${err}`);
      }
  };

  validateObjRecursiveWrapper(dataObj) {      
      let funcName = `validateObjRecursiveWrapper`;
      try {
        let dataObjKeys = Object.keys(dataObj);
        for(let key of dataObjKeys) {          
          let validateObjRes = this.validateObj(key, dataObj[key], dataObj);
          console.log(`log validateObjRes results in ${funcName}`, validateObjRes);
          let {res, validateMsg, validateKey, dataType} = validateObjRes;          
            if(!res) {
                return {res, validateMsg, validateKey, dataType};
            }
        }        
        let res=true
        return {res, dataObj};
      } catch (err) {
        console.error(`err in ${funcName}: ${err}`);
      }
  }; 
}; 

export default new ValidateService();