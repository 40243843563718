export const appleCoMajorShareHolderBreakdown = {                        
    "majorHoldersBreakdown": {        
        "insidersPercentHeld": {
            "raw": 0.00072999997,
            "fmt": "0.07%"
        },
        "institutionsPercentHeld": {
            "raw": 0.61294997,
            "fmt": "61.29%"
        },
        "institutionsFloatPercentHeld": {
            "raw": 0.6134,
            "fmt": "61.34%"
        },
        "institutionsCount": {
            "raw": 5719,
            "fmt": "5.72k",
            "longFmt": "5,719"
        }
    }            
}