import React, { useEffect, useState } from 'react';
import questionData from './mixed-100-questions.json';
import BootstrapScrollingModal from './BootstrapScrollingModal'; 
import HelperService from './HelperService';
const helperSer = new HelperService();
// ===========================================================================================================================================================================================
// each product category contains the snippet of information introducing the plan 
// each snippet of information contains its own unique PAYMENT LINK
// ===========================================================================================================================================================================================
// this object provides the main information for each product category 
let productCategories = {
    
};
// ===========================================================================================================================================================================================

export default function SmallTilesGrid({questions, props}) {    
    const [tiles, setTiles] = useState([]); 
    const [viewMode, setViewMode] = useState('table');
    const [modalShow, setModalShow] = useState(false);    
    const [theQuestion, setQuestion] = useState(null);      
    const [questionObj, setQuestionObj] = useState({}); 
    const [topicArr, setTopicArr] = useState([]);    
    let {myRef} = props;          
    useEffect(() => {
        console.log('log SmallTilesGrid props', props);         
        sortQuestions();            
        async function setEvtListeners() {
            let tilesEleDomNodes = await document.getElementsByClassName('topic-tiles'); 
            let tilesEleArr = Array.from(tilesEleDomNodes); 
            let loopCount = 0;            
            let randomInt = helperSer.getRandomInt(1, tilesEleArr.length);
            for(let ele of tilesEleArr) {                
                loopCount +=1; 
                console.log('small tile textcontext', ele.innerHTML);
                if(loopCount === randomInt) ele.style.backgroundColor = "green";                
                
                // optimisation point: 
                // hightlights the element in a different color when user hovers mouse over element 
                // can use later on for a game logic 
                // ele.addEventListener("mouseover", function( event ) {                    
                //     event.target.style.backgroundColor = "orange";                                    
                // }, false);

                ele.addEventListener("mouseleave", async function( event ) {                        
                    // learning points:
                    // in future if want to change css effects for gird in light mode after hover
                    // let localStore = await helperFuncs.getDataInLocalStorage(['mode']); 
                    // let {mode} = localStore;  
                    // if(mode === 'light') console.log('light mode')
                    event.target.style.backgroundColor = "#23232e";
                    event.target.style.color = "#fff";                                    
                  }, false);                  
            };             
            // await newHelperObj.detectMobileView(window, document, 'topic-tiles', window.location.href);             
        };         
        // setEvtListeners();
    }, [props]);     

    async function openBox(e, ele, questionObj) {
        try {
            console.log('exec openBox')            
            console.log(questionObj)                                            
            setQuestion(questionObj)
            setModalShow(true)            
        } catch(err) {
            console.log(`log err in openBox: ${err}`);
        }; 
    }; 

    async function sortQuestions() {
        console.log('log sortQuestions')
        console.log(questionData);  
        console.log(topicArr)
        console.log(questionObj)              
        let obj = {}; 
        let arr =[];
        let justQuestionsArr = []; 
        for(let topics of questionData) {
            let topic = Object.keys(topics)[0]; 
            let questionsArr = Object.values(topics)[0];    
            obj[topic] = questionsArr;   
            arr.push(topic);             
            justQuestionsArr.push(questionsArr);             
        }; 
        let finalArr = justQuestionsArr.flat(); 
        setQuestionObj(obj); 
        setTopicArr(arr);   
        setTiles(finalArr);   
    };     

    async function transformtiles(btn) {
        // remove question text if in grid view        
        setViewMode(btn);
        console.log(`log viewMode`, viewMode);         
        var allTopicTiles = document.querySelectorAll('.topic-tiles');        
        let arr = Array.from(allTopicTiles);             
        if(arr.length > 0) {                              
            for(let tile of arr) {                                
                if(btn === 'list') tile.classList.add("change-to-list-form");    
                if(btn === 'table') tile.classList.remove("change-to-list-form");                    
            };                         
        };     
    }; 
    
    return <>                   
        <BootstrapScrollingModal props={theQuestion} show={modalShow} onHide={() => setModalShow(false)} />                
        <div           
            id="view-form" 
            className="d-flex justify-content-around mt-3 mb-2"
        >    
            <h1 
                style={{marginTop:'10rem'}}
                className="display-5 fw-bold lh-1 mb-3"
            >
                {`Find the Best Shopping Deals`}
            </h1>            
        </div>   
        <div           
            id="view-form" 
            className="d-flex justify-content-center mt-3 mb-2"
        >    
            <small>{`From around the web.`}</small>
            <small
                style={{
                    marginLeft: '1rem',
                    color: 'teal',
                }}                
            >{`Just click on the rectangles!`}</small>
        </div>   
        
        {/* <div           
            id="view-form" 
            className="d-flex justify-content-around mt-3 mb-2"
        >    
            <i                 
                className="table-view-icon fa fa-table" 
                aria-hidden="true"
                onClick={() => transformtiles('table')}
                ></i>
            <i                 
                className="list-view-icon fa fa-list" 
                aria-hidden="true"
                onClick={() => transformtiles('list')}
                ></i>            
        </div> */}

    <div 
        className="d-flex flex-column align-items-center mt-3 mb-5"
    >            
        <div 
        ref={myRef}
        id={'tiles-outer-wrapper-id'}
        className="wrapper"        
        style={{
            display: 'inline-block',            
            maxHeight: 'auto',            
            margin: '0 auto',
            color: '#fff',         
            fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
            // padding: '1rem',
            // border: '2px solid red'
            // flex: '0 0 10rem',
            // backgroundColor: '#0e2439',
        }}        
        >
            <ul
            className="tiles-wrapper"
            id={'tiles-wrapper-id'}            
            style={{
                display: 'flex',
                justifyContent: 'center',
                listStyle: 'none',
                flexWrap: 'wrap',
                maxHeight: 'fit-content',
                marginRight: '2rem'
            }}        
            >
                {
                    tiles.map((obj, index) => (
                        <li
                        key={index}
                        onClick={(e) => openBox(e, `topic-tile-${index}`, obj)}                                                
                        className="topic-tiles"
                        id={`topic-tile-${index}`}
                        // to make borders round
                        // border-top-left-radius: 0.5rem;
                        // border-top-right-radius: 0.5rem;
                        // border-bottom-left-radius: 0.5rem;
                        // border-bottom-right-radius: 0.5rem;
                        // learning points:
                        // what is the best way to set css?
                        // we should always try to set css in the style sheet
                        // if we want to modify an element css we shuld add class to the element 
                        // we should do customise css for a component that does not change with style={{}} 
                        // we could use ids for css to set for elements that are unique on the website in that no matter where it shows up it willl ook the same
                        style={{
                            width: '200px',
                            // height: '50px',
                            // height: '200px',
                            // height: 'max-content',
                            maxHeight: 'auto',
                            // backgroundColor: '#0e2439',
                            // backgroundColor: '#23232e',
                            border: '1px solid #fff',                                                        
                            // margin: '2px',
                            // margin: '2rem',
                            padding: '0.5rem',
                            textAlign: 'center'                    
                        }}  
                        >
                        {/* {tile} */}                        
                        <p className="lead">
                        {viewMode === 'table' ? <>Topic: Javascript</> : <>Topic: Javascript Question: {index+1}</>}                            
                        </p>
                        {
                            viewMode === 'table' ? 
                            <>
                            </> : 
                            <>
                                <p
                                id="topic-tile-question-text" // hide this when in grid view 
                                style={{
                                    padding: '1rem',
                                    textAlign: 'left'
                                }}
                                >
                                    we could use ids for css to set for elements that are unique on the website in that no matter where it shows up it willl ook the same
                                </p>
                                <button 
                                className="btn btn-outline-success mb-3" 
                                type="button"   
                                // onClick={() => showAnswers(`search-result-answer-${index}`)}         
                                >
                                    Show Answers
                                </button>
                            </>
                        }                        
                    </li>
                    ))
                }                
            </ul>            
        </div>
        </div>    
    </>
};  

