import axios from 'axios'
import isEmpty from 'lodash.isempty'

class SearchService {
    constructor() {    
        this.headers = {      
          'Content-Type': 'application/json',      
        }          
      }  

      removeSymbols = (searchString) => {
        let funcName = `removeSymbols`;
        try {      
            if(isEmpty(searchString)) return `empty args in ${funcName}`;        
            // tested and works
            // let str = "<< >> <br> 67 8976 68796 Limited Liability Company (LLC) | Internal Revenue Serviceirs.govhttps://www.irs.gov › businessesirs.govhttps://www.irs.gov › businessesEm cacheSemelhantesTraduzir esta página";
            let regexpWordsOnly = /\b\w+\b/g;
            let isMatch = searchString.match(regexpWordsOnly);
            let cleanedStr = isMatch.join(' ');
            console.log(cleanedStr)
            return cleanedStr;
        } catch (e) {
            console.error(`unable to connect in ${funcName}: ${e}`);
        }
    };
    
    removeNumbers = (searchString) => {
        let funcName = `removeNumbers`;
        try {      
            if(isEmpty(searchString)) return `empty args in ${funcName}`;
            let cleanedStr = searchString.replace(/[0-9]/g, '');
            console.log(cleanedStr)        
            return cleanedStr;
        } catch (e) {
            console.error(`unable to connect in ${funcName}: ${e}`);
        }
    };

      cleanSearchString = (searchString) => {
        let funcName = `cleanSearchString`;
        try {
            // clean search string here:
        // remove symbols
        let cleanedStr1 = this.removeSymbols(searchString);
        console.log(`log cleanedStr1`, cleanedStr1)
        if(!cleanedStr1) {
            return null;
        }
        // remove numbers
        let cleanedStr2 = this.removeNumbers(cleanedStr1);
        console.log(`log cleanedStr2`, cleanedStr2)
        if(!cleanedStr2) {
            return null;
        }        
        return cleanedStr2;
        } catch (e) {
            console.log(`err in ${funcName}`, e);
        }
      };      
          
      hitSearchApi = (url, headers, searchObj) => {
        if(isEmpty(searchObj)) {
            return null;
        }        
        const {
            searchString,
            isCompanyProfileOnly, // search for company name as text search
            searchType,
            limitResults,
            period,
        } = searchObj;
        console.log(`log items in searchObj object`, searchString, isCompanyProfileOnly, period, searchType, limitResults);
        let params = {};
        return axios({
          method: 'get',
          headers: Object.assign({}, this.headers, headers),
          url,
          params: params || {},
          responseType: 'json',      
        });
      }
};

export default new SearchService();