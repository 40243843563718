import { createStore, applyMiddleware, compose } from 'redux';
import { reducer, initialState } from './reducer';
import persistMiddleware from './persistMiddleware';
import apiMiddleware from './apiMiddleware';
// import logger from 'redux-logger';
// const middleware = [logger, persistMiddleware, apiMiddleware]; 
const middleware = [persistMiddleware, apiMiddleware]; 
const store = createStore(
  reducer,
  initialState,    
  compose(applyMiddleware(...middleware))
);

export default store
