import isEmpty from 'lodash.isempty';
// import * as gtag from '../../lib/gtag';
import firebase from '../../firebase/firebaseConfig';
import {  
  startPageLoading,
  finishPageLoading,
  startLoading, 
  finishLoading,
  onLoginSuccess,
  onRegisterSuccess,
  openCustomModal,
  updateProfile,
  onLogout,
  persistProfile,
  addNotification,
} from '../../redux/actions';

// onLoginSuccess
// onRegisterSuccess

class FirebaseService {
  constructor() {
    this.unsub = null;
    this.firebase = firebase;
    this.auth = this.firebase.auth();
    this.db = this.firebase.firestore();    
    this.profiles = this.db.collection('profiles');    
  }  
  // ================================================================================================================================================================================
  // all profile functions:   
  // createProfile
  // getProfile
  // createProfileObj  

  createProfile2(user) {
    let funcName = `createProfile2`;
    try {
      console.log("exec createProfile2!", user);
      if (isEmpty(user)) {
        console.log(`user is empty in ${funcName}`);
        return `user is empty in ${funcName}`;
      }
      let { firebaseID } = user;                  
      this.profiles
      .doc(firebaseID)
      .set(user, { merge: true })
      .then(() => {        
        console.log("createProfile Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
      return true;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  getProfile(dispatch, user, loadingType) {
    let funcName = `getProfile`;
    try {      
      if (isEmpty(user)) {
        console.log(`user is empty in ${funcName}`);
        return `user is empty in ${funcName}`;
      }
      let { firebaseID } = user;      
      this.profiles
        .doc(firebaseID)
        .get()
        .then(async (doc) => {
          if (!doc.exists) {   
            console.log(`profile obj does NOT exist !!! ${funcName}`, doc);
          } else {            
            let profileObj = doc.data();
            console.log(`log profileObj doc.data() ${funcName}`, doc.data());
            console.log(`log complete profileObj in ${funcName}`, profileObj);
            dispatch(onLoginSuccess(profileObj)); 
            // moved finishLoading to listenAuthChange()
            // dispatch(finishLoading(loadingType));
          }
      }).catch((error) => {
        console.log(`log error in ${funcName}`, error); 
      });
      return true;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  createProfileObj(loginProvider, additionalUserInfo, metadata, firebaseID, credential) {    
    let funcName = `createProfileObj`;
    try {      
      if (
        isEmpty(loginProvider) ||
        isEmpty(additionalUserInfo) ||
        isEmpty(metadata) ||
        isEmpty(firebaseID)
      ) {
        console.log(`some args is empty in ${funcName}`); 
        return `some args is empty in ${funcName}`;
      }
      console.log(`log input args in ${funcName}`, loginProvider, additionalUserInfo, metadata, firebaseID);       
      // set default keys for profileObj applies to all loginProvider
      // prepare initial profile obj
      let userProfile = {
        firebaseID: firebaseID ? firebaseID : null,
        isMonthlyMember: false,
        flaggedStatus: false,
        blockedStatus: false,
        customerID: null,
        subscriptionState: null, // handling changes in subscriptions
        invoicePaidState: null, // handling changes in invoice paid
        subs_items_list: {
          item_count: 0,
          subs_items_list: [],
        },
        invoice_items_list: {
          item_count: 0,
          invoice_items_list: [], // get hosted invoice if invoicePaidState = open
        },
        purchased_items_list: {
          item_count: 0,
          purchased_items_list: [],
        },
        refund_items_list: {
          item_count: 0,
          refund_items_list: [], // store just a list of paymentIntent (charge objects)
        },
      };
      // sign up with email =======================================================================================================      
      if (loginProvider === 'password') {
        userProfile.loginTime = Date.now() ? Date.now() : null;        
        // metadata
        userProfile.creationTime = metadata.a ? metadata.a : null;
        userProfile.lastLogInTime = metadata.b ? metadata.b : null;
        userProfile.isAnonymous = metadata.isAnonymous
          ? metadata.isAnonymous
          : null;
        userProfile.phoneNumber = metadata.phoneNumber
          ? metadata.phoneNumber
          : null;
        // additionalUserInfo
        userProfile.username = additionalUserInfo.name
          ? additionalUserInfo.name
          : null;
        userProfile.displayName = additionalUserInfo.name
          ? additionalUserInfo.name
          : null;
        userProfile.signInMethod = additionalUserInfo.providerId
          ? additionalUserInfo.providerId
          : null;
        userProfile.providerId = additionalUserInfo.providerId
          ? additionalUserInfo.providerId
          : null;
        userProfile.email = additionalUserInfo.email
          ? additionalUserInfo.email
          : null;
        userProfile.verifiedEmail = additionalUserInfo.verified_email
          ? additionalUserInfo.verified_email
          : null;
        userProfile.profilePhotoURL = additionalUserInfo.picture
          ? additionalUserInfo.picture
          : null;
        userProfile.location = additionalUserInfo.locale
          ? additionalUserInfo.locale
          : null;
      };      
      return userProfile;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  // ================================================================================================================================================================================
  // login with own email and password 
  // below are the firebase functions used with signin up with their own email and password  
  // functions are applicable for most products such as pure newsletter and pure book app
  // createAccountWithEmailAndPassword
  // loginWithEmailAndPassword
  // doPasswordReset
  // doPasswordUpdate  

  async createAccountWithEmailAndPassword(userEmail, password, dispatch, router) {    
    let funcName = `createAccountWithEmailAndPassword`;
    try {   
      // log website event with google tags =======================================================================================================
      // gtag.event({
      //   action: 'sign_in_with_google',
      //   category: 'auth',
      //   label: 'sign_in',
      //   value: 'auth_google',
      // });
      // ui actions =========================================================================================================================
      // the flow of how the UI changes?
      // the related components will listen for changes in reducer ie AuthButtons and LoginModal
      // when user clicks "create account", this function will execute 
      // createAccountWithEmailAndPassword will be added to the loadingTypes array in reducer 
      // both AuthButtons and LoginModal will be listening for changes to loadingTypes in the component using useSelector from redux library 
      // both compoenents can then have their own individual behaviour when loading is happening        
      // in this case, auth buttons will disappear from UI and loginModal will show loading and cover the entire modal  
      // both components will be loading until routeCHangeListener comnponent detects page change 
      // on page change, the FullPageLoadingComponent which covers the entire web page will pop up from bottom 
      // FullPageLoadingComponent will be imported into routeCHangeListener so the full page overlay element can popup on any page like DTJ      
      // routeCHangeListener will be responsible for clearing all types of loading once on new page 
      // what if there was err fetching data? redirect user to the start of the user flow      
      // dispatch(closeAllModals(funcName)); 
      dispatch(startLoading(funcName));
      // create a delay for user to see loading effects in all components:
      setTimeout(() => {
        dispatch(startPageLoading());
      }, 1000); 
      // auth actions ========================================================================================================================      
      // learning points:      
      // the correct way is to use .then() for all api calls
      this.auth.createUserWithEmailAndPassword(userEmail, password)
      .then(res => {
        console.log(`log res in ${funcName}`, res);
        let { user, additionalUserInfo } = res;
        let { emailVerified, isNewUser, name, } = additionalUserInfo;
        let {uid, metadata, isAnonymous, phoneNumber, email, photoURL, refreshToken } = user;
        // custom add fields to metadata manually 
        metadata.isAnonymous = isAnonymous;
        metadata.phoneNumber = phoneNumber;
        additionalUserInfo.name = email;
        additionalUserInfo.email = email;
        additionalUserInfo.emailVerified = emailVerified;
        let loginProvider = additionalUserInfo.providerId;            
        if (!isEmpty(user)) {
          console.log('log user from result in signInWithPopup', user);           
          console.log('log additionalUserInfo from result in signInWithPopup', additionalUserInfo); 
          let { uid, metadata, isAnonymous, phoneNumber, photoURL, refreshToken } = user;            
          metadata.isAnonymous = isAnonymous;
          metadata.phoneNumber = phoneNumber;               
          // ==========================================================================================================================================
          // create new redux action to show user is a new user
          // Dashboard checks for this variable and shows user User Pref flow 
          // onLogout update user's profile or 3 mins after user has selected their user pref
          // here baby
          
          // ==========================================================================================================================================              
          // added on 11mar23:
          // it's ok for profile to have this, when we update profile in backend we exclude isNewUser
          // localStorage.setItem(`isNewUser`, JSON.stringify(isNewUser));       
          // ==========================================================================================================================================      
          // transform data into appropriate profile object            
          let profileObj = this.createProfileObj(loginProvider, additionalUserInfo, metadata, uid);                 
          // ==========================================================================================================================================      
          // we should add this to profile for first time users only
          // so that we can check that all users in s3 has isNEwUser top true
          // add to profileObj as we are now checking profile object           
          profileObj['isNewUser'] = additionalUserInfo.isNewUser; 
          console.log('log complete profileObj in createAccountWithEmailAndPassword', profileObj);
          // ==========================================================================================================================================                  
          // we do not need to check if user is new or not as users running createUserWithEmailAndPassword is by nature new
          // if email already exists then will throw err see err handling below
          // if user is registered, they should use loginWithEmailAndPassword()
          // can dispatch onRegisterSuccess here as latest user profile is the profileObj created from this.createProfileObj
          // this.createProfile(dispatch, profileObj); // old
          this.createProfile2(profileObj); // updated on 26feb23          
          dispatch(onRegisterSuccess(profileObj));          
          // ==========================================================================================================================================      
          // persist new user profile here: 
          dispatch(persistProfile(profileObj));
          // move finishLoading into listenAuthChange
          // 3mar23:
          // 2 cases where loading should be finished and they are: 
          // listenAuthChange(); 
          // if any errors occur           
          // dispatch(finishLoading(funcName));          
        } else {          
          console.error(`some unknown err user is empty in ${funcName}`);
          dispatch(finishLoading(funcName));            
          // go back to landing 
        }        
      }).catch((err) => {
        // learning points:
        // err is also handled below in the try catch block 
        // if user closes popup without loggin in we need catch err:                        
        dispatch(finishLoading(funcName));        
        // "message": "A network error (such as timeout, interrupted connection or unreachable host) has occurred."
        // "message": "There is no user record corresponding to this identifier. The user may have been deleted."
        // "message": "The password is invalid or the user does not have a password."
        // message: 'The popup has been closed by the user before finalizing the operation.'
        // 'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'                
        const { code, message } = err;
        console.error(`err in ${funcName}:`, code, message);
        if(message !== 'The popup has been closed by the user before finalizing the operation.') {
          dispatch(addNotification(message, funcName));
        }
        // if (err === 'Error: An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.') {
        //   alert(
        //     'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
        //   );
        // };        
      });               
      return true;
    } catch (err) {      
      // learning points:
      // we may have to handle the error here and in .catch() above
      console.error(`err in ${funcName}: ${err}`);
      dispatch(finishLoading(funcName));      
      if (err === 'Error: An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.') {
        alert(
          'An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.'
        );
      };             
    }
  }; 

  loginWithEmailAndPassword(email, password, dispatch) {
    // this flow should be the same as auser logging back in again with loginPopup        
    // so after we successfully get user object, we execute getProfile() 
    // only dispatch onLoginSuccess in getProfile after successfully getting latest user profile from firebase
    // new change 9feb23:
    // need to handle sign in error    
    // FirebaseService.jsx?30a8:376 err in loginWithEmailAndPassword: Error: There is no user record corresponding to this identifier. The user may have been deleted.
    // FirebaseService.jsx?30a8:377 err in loginWithEmailAndPassword: Error: There is no user record corresponding to this identifier. The user may have been deleted.    
    let funcName = `loginWithEmailAndPassword`;
    try {      
      // ==========================================================================================================================================              
      // start loading 
      dispatch(startLoading(funcName));
      // create a delay for user to see loading effects in all components:
      setTimeout(() => {
        dispatch(startPageLoading());
      }, 1000); 
      // ==========================================================================================================================================              
      this.auth.signInWithEmailAndPassword(email, password)
      .then(async (res) => {
        console.log(`log res in ${funcName}`, res); 
        let { user, additionalUserInfo } = res;         
        let { emailVerified, isNewUser, name, providerId } = additionalUserInfo;                
        let { uid, metadata, isAnonymous, phoneNumber, email, photoURL, refreshToken } = user;        
        metadata.isAnonymous = isAnonymous;
        metadata.phoneNumber = phoneNumber;
        additionalUserInfo.name = email;        
        additionalUserInfo.emailVerified = emailVerified;
        let loginProvider = additionalUserInfo.providerId;
        if (!isEmpty(user)) {
          console.log('log user from result in loginWithEmailAndPassword', user, emailVerified, isNewUser, name, additionalUserInfo);
          // ==========================================================================================================================================              
          // added on 11mar23:
          // localStorage.setItem(`isNewUser`, JSON.stringify(isNewUser));
          // ==========================================================================================================================================              
          // transform data into appropriate profile object    
          // does this.createProfileObj need to be synchronous?
          let profileObj = this.createProfileObj(loginProvider, additionalUserInfo, metadata, uid);          
          // ==========================================================================================================================================
          // only dispatch onLoginSuccess in getProfile after successfully getting latest user profile from firebase 
          this.getProfile(dispatch, profileObj, funcName);
          // ==========================================================================================================================================
          // persist new user profile here:           
          profileObj['isNewUser'] = isNewUser;
          dispatch(persistProfile(profileObj));
          // ==========================================================================================================================================          
          // as profile data is updated in reducer above it is slower to reach redux 
          // therefore when user signs in the login buttons will show for a moment then turn to logout 
          // we need to finish loading after getProfile and logTime is updated in reducer           
          // used to be here:
          // dispatch(finishLoading(funcName));
        } else {          
          dispatch(finishLoading(funcName));        
          // dispatch(finishPageLoading());
          console.error(`some unknown err user is empty in ${funcName}`);          
        }
      }).catch((error) => {      
        // learning points:
        // the error is caught here
        // not in the try and catch block 
        // "code": "auth/user-not-found",
        // "message": "There is no user record corresponding to this identifier. The user may have been deleted."
        dispatch(finishLoading(funcName));  
        // learning points:
        // dont use full page loading as it causes some blank white screen
        // dispatch(finishPageLoading());
        let { code, message } = error;        
        // if(code === "auth/user-not-found") {
        //   setTimeout(() => {
        //     dispatch(finishPageLoading());
        //     alert(`There is no user record corresponding to this identifier. The user may have been deleted.`);        
        //   }, 1500);
        // }        
        console.error(`err in ${funcName}:`, code, message);
        if(message !== 'The popup has been closed by the user before finalizing the operation.') {
          dispatch(addNotification(message, funcName));
        }                
      });                
      return true;
    } catch (err) {      
      dispatch(finishLoading(funcName));  
      // dispatch(finishPageLoading());
      console.error(`err in ${funcName}: ${err}`);      
      return err;
    }
};    

// ==========================================================================================================================================
// log out function applies to all types of authentication 

async logout(dispatch) {  
  let funcName = `logout`; 
      try {                 
          this.firebase.auth()
          .signOut()
          .then((res) => {              
              // learning points:
              // here is where we shuld add the unsub function if using listenLiveUpdates
              if (!isEmpty(this.unsub)) {        
                console.log(`exec unsub in ${funcName}`, this.unsub);
                return this.unsub();
              }; 
              console.log(`sign out success`, res);
              dispatch(onLogout(Date.now(), window.location.pathname));              
              return true; 
          }).catch((err) => {                    
            console.log(`err in ${funcName}: ${err}`); 
          });
      } catch(err) {          
          console.error(`err in ${funcName}: ${err}`); 
      }
};  

// ==========================================================================================================================================
// passowrd functions 
// doPasswordReset
// doPasswordUpdate

doPasswordReset(dispatch, email) {
  // notify user email has been sent to his register email      
  // use custom modal so open modal with dispatch here
  let funcName = `doPasswordReset`;
    try {
        this.auth.sendPasswordResetEmail(email)
        .then((doc) => {
          console.log(`log doc ${funcName}`, doc);  
          // this opens a message modal to show user password reset was successful ! 
          let message = `Your password for ${email} has been reset.`;
          dispatch(openCustomModal(funcName, message)); 
        }).catch((error) => {
          console.log(`log error in ${funcName}`, error); 
        });     
        return true;
    } catch (err) {    
      console.error(`err in ${funcName}: ${err}`);
      return err.message;
    }
};   

doPasswordUpdate(dispatch, password, is_authenticated, router, email) {
  // only allow user to use this feature of they are logged in   
  // need to create a new page at /auth to let user login or register again 
  let funcName = `doPasswordUpdate`;
    try {
      if (!is_authenticated) {
        // alert('Please sign in again!');
        let message = `Please sign in again`;
        dispatch(openCustomModal(funcName, message));         
        router.push('/auth');
        return 'user is not authenticated';
      }      
      this.auth.currentUser.updatePassword(password)        
        .then((res) => {
          console.log(`log res ${funcName}`, res);
          let message = `Your password for ${email} has been updated.`;
          dispatch(openCustomModal('type', message)); 
        }).catch((error) => {
          console.log(`log error in ${funcName}`, error); 
        });     
        return true;
    } catch (err) {    
      console.error(`err in ${funcName}: ${err}`);
      return err.message;
    }
};     

// ==========================================================================================================================================
// all listening functions 
// use listenAuthChange if using loginPopup or  loginWithEmailAndPassword to log in 
// listenAuthChange
// listenRedirect
// listenLiveUpdates

async listenAuthChange(dispatch, loadingTypes) {
  // firebaseSer.listenAuthChange(router, dispatch, window);  
  // learning points:   
  // this function runs every time page refreshes and will redirect user to dashbaord
  // we should therefore add contional wrraper around this in User component if authenticated no need to execute
  // th conditional if check will make sure onAuthStateChanged only executes when firebase auth has changed 
  // but will not execute on page refresh so sue useSelector in User component 
  // if redirect here then will not see a splash of landing page before redirecting to dashbaord like BM logic 
  // learning points:
  // onAuthStateChanged has no .then()
  // err in listenAuthChange: TypeError: _this.firebase.auth(...).onAuthStateChanged(...).then is not a function
  let funcName = `listenAuthChange`;
  try {
    this.firebase
    .auth()
    .onAuthStateChanged((user) => {
      try {
        console.log('log onAuthStateChanged', user);
        if (!isEmpty(user)) {
          // learning points: 
          // when <Loading/> component is placed inside RouteChangeListener.js
          // the loading element does not seem to pop up properly 
          // this is why RouteChangeListener.js should ONLY be responsible for communicating with redux          
          // start full page loading
          // move dispatch(startPageLoading()); to same place as START_LOADING
          // instead we do finishPageLoading here:                            
          // save firebase profile to s3 here? 
          // no should wait until user has finished updating their profile settings
          // axios to lamda s3          
          // const { uid, displayName, email, emailVerified, photoURL, providerData, metadata } = user;
          const { uid } = user;                             
          // use redux loadinTypes ot find which type of loading is happening: 
          const listeningForEvents = [`createAccountWithEmailAndPassword`, `loginWithEmailAndPassword`];
          let findLoadingEvent = loadingTypes.filter(x => listeningForEvents.includes(x));
          if(!isEmpty(findLoadingEvent)) {
            console.log(`log findLoadingEvent ${funcName}`, findLoadingEvent);
            let loadingType = findLoadingEvent[0];
            console.log(`exec finishLoading`);
            dispatch(finishLoading(loadingType));
          }                    
          setTimeout(()=> {
            console.log(`exec finishPageLoading`);
            dispatch(finishPageLoading());  
            // if nextjs then here we go to new page
            // router.push(`/dashboard`);                        
            // 5000 seems too long
          }, 2000);           
        } else {            
          // direct user to homepage for signing in again          
          // router.push(`/`);            
        }
      } catch (err) {
        console.log(`Most probably a connection error ${err}`);        
      } finally {
        // setLoadingUser(false)
      }
    });     
  } catch (err) {      
    dispatch(finishPageLoading());
    dispatch(finishPageLoading());
    console.error(`err in ${funcName}: ${err}`);
  }
}; 

  listenLiveUpdates(docId, dispatch) {
    // learning points:
    // index.js:1210 err in listenLiveUpdates: FirebaseError: [code=invalid-argument]: Function CollectionReference.doc() cannot be called with an empty path.
    // add listenLiveUpdates in <User/>
    // listen to live updates   
    // new changes: 
    // how to handle when user logs out
    // we can execute unsub in logout()
    // if we do not allow this to execute on page refresh would this affect latest values coming through?
    // use local storage to limit the times it is executed because everytime it is refreshed it is executed    
    let funcName = `listenLiveUpdates`;
    try {      
      // if user is logged out unsub from snapshot
      // use this.unsub as we need a gloabl variable to execute in logout()      
      // added unsub to logout instead
      if(isEmpty(docId)) {
        return true; 
      }
      console.log('log this.unsub in listenLiveUpdates before snapshot', this.unsub)
      let doc = this.profiles.doc(docId);
      this.unsub = doc.onSnapshot((docSnapshot) => {
        console.log('log this.unsub in listenLiveUpdates', this.unsub)
        console.log('log docSnapshot metadata', docSnapshot.metadata);
        let profile = docSnapshot.data();
        if (isEmpty(profile)) {
          return true;
        };        
        let { purchased_items_list, isMonthlyMember, countStr, profile_doc_id, displayName, username, 
          profilePhotoURL, onlineStatus, logOutTime, loginTime, invoicePaidState 
        } = profile;
        if (invoicePaidState === 'open') {
          // if invoicePaidState is open maybe there was a problem with the user's card 
          // we can use this codeblock to detect when user's invoice payment has any problems 
          // show button to user on dashboard to pay invoice          
        };
        // use this chance to save the latest profile for user in redux
        // this means everytime page refreshes redux profile is updated 
        // or everytime snapshot updates redux is updated
        dispatch(updateProfile(profile));
        return true;
      });       
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };     
};

const firebaseSer = new FirebaseService();
export default firebaseSer;