import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NotifyModal from './NotifyModal'; 
import Search from './Search'; 
import CompanyCards from './CompanyCards'; 

export default function Dashboard({}) {    
      const useReduxState = () => {
          return useSelector(
            (state) => ({        
              loginTime: state.loginTime,
              profile: state.profile,
              uuid: state.uuid,
            }),
            shallowEqual
          );
        };        
    const { loginTime, profile, uuid } = useReduxState();    
      useEffect(() => {
        console.log('mounted Dashboard', loginTime, profile, uuid);                       
      }, [loginTime]);      
      
      return <>                    
    <div className="dashboard-wrapper">             
        <div 
            style={{                                      
                justifyContent: 'center',      
                marginBottom: '30rem'            
            }}
            className="row"
        >                      
        <hr/>                
        <NotifyModal/>        
        <Search/>
        <CompanyCards/>
        </div>            
        </div>
      </>
  };  