import React, { useEffect, useState } from "react";
import{ Modal, Container, Button, Row, Col }  from "react-bootstrap";

export default function BootstrapScrollingModal(props) {      
let questiontext, questionReq, ansFullText, ansText; 
if(props.props) {
  const {question, req, ans_full_text, ans} = props.props; 
  console.log('scol modal props')
  console.log(question)
  questiontext = question; 
  questionReq = req;  
  ansFullText = ans_full_text; 
  ansText = ans; 
}; 
  
return <>           
  <Modal 
    style={{
        marginTop: '5rem'
    }}    
    {...props} aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header
      style={{
        background: 'teal',
        color: '#fff'
      }}     
        closeButton
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {`Product Tips`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container
        style={{
          padding: '3rem',          
        }}
        >         

        <Row 
          id={'question-heading-box'}                 
          style={{            
            marginBottom: '2rem',                
            padding: '1rem',        
            // border: '2px solid indigo',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem',
          }}
          >              
            <Col               
              xs={12} md={8}
              style={{            
                fontSize: '1.5rem',
                fontWeight: 'bold',
                width: `100%`
              }}
              >                
              {`Shopper's Guide:`}
              {/* {`More Product Infomation:`} */}
            </Col>
            <Col xs={6} md={4}>              
            </Col>
          </Row>
          <Row>
          <p                      
            style={{
              marginTop: '0.5rem'
            }}                           
              dangerouslySetInnerHTML={{
                __html: `${questiontext}`,
              }}
            >                
            </p>
            <p>           
            {questionReq}             
            </p>                   
          </Row>                     
        </Container>        
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal> 
</>
}
