const latestBlogs = [

]; 


export const COMPONENT_STATIC_DATA = {
    // !! the number of items you put in the navbarTopProps will affect the navbar spacing !!
    // !! the BUSINESS NAME should go at the start !!
    navbarTopProps: [
      "Remote Upskill",
      "Modules",   
      "Pricing",              
      "Blog",
      "Newsletter",                    
      // "Login",      
      // "Books",          
      // "Youtube Tutorials",      
      // "Help",      
      // "Pricing",       
      // "Testimonial"
    ],    
    navbarBottomProps: ["Search"],
    navbarTopAuthProps: ["Dashboard", "My Profile", "Upgrade Plan", "Blog"],    
    navbarBottomAuthProps: ["Documentation", "Home", "Youtube Tutorials"],
    sidebarProps: [
      "Question of the Day!",
      "Light",
      "Dark",            
    ],       
    jumbotronProps: {
      bigHeading: 'Remote Upskill', leadText: 'Helping humans prepare for and find remote work', scrollButtonText: 'Test Yourself!' 
    },    
    static_blog_posts_data: [
      {
          blog_post_title: "",
          blog_post_img: "../public/vercel.svg",
          blog_post_body: [

              { 
              subtitle: 'Math is good' 
              },
              'NextJs is fast because it uses edge technology',
          ],
          created_at: 1613955109060,
      },

      {
          blog_post_title: "",
          blog_post_img: "../public/vercel.svg",
          blog_post_body: [

              { subtitle: 'Calvin is the best at Math' },
              'NextJs is fast because it uses edge technology',
          ],
          created_at: 1613955109060,
      },


      {
        blog_post_title: "",
        blog_post_img: "../public/vercel.svg",
        blog_post_body: [

            { subtitle: 'Calvin is the best at Math' },
            'NextJs is fast because it uses edge technology',
        ],
        created_at: 1613955109060,
    },

    {
      blog_post_title: "",
      blog_post_img: "../public/vercel.svg",
      blog_post_body: [

          { subtitle: 'Calvin is the best at Math' },
          'NextJs is fast because it uses edge technology',
      ],
      created_at: 1613955109060,
  },

  {
    blog_post_title: "",
    blog_post_img: "../public/vercel.svg",
    blog_post_body: [

        { subtitle: 'Calvin is the best at Math' },
        'NextJs is fast because it uses edge technology',
    ],
    created_at: 1613955109060,
},

{
  blog_post_title: "",
  blog_post_img: "../public/vercel.svg",
  blog_post_body: [

      { subtitle: 'Calvin is the best at Math' },
      'NextJs is fast because it uses edge technology',
  ],
  created_at: 1613955109060,
},

    ],

    pricingCardsProps: [
      {        
        plan_title: "Free",
        amount: "0",          
        product_items: [                      
            "100% free forever!",            
            "You will get a daily free usage limit of 300.",                        
            "Sharing, following and unfollowing will all count towards this daily free limit.",
            "You will receive new daily free usage limit of 300 credit after 12AM local time.",            
          ]                
      },    
      { 
        // price_id: "price_1KbQjoCXj0cKCM7grZVbfTTe", // prod
          price_id: "price_1KcaTJCXj0cKCM7gDVqUNFdk", // test          
          plan_title: "Monthly",
          amount: "19.99",          
          product_items: [
            "Billed monthly.",            
              "You get everything in the Free plan but unlimited.",
              "Unlimited sharing, following and unfollowing (subject to safety limits).",  
              "Safety limits refers to the limit you should stay within to lower your chances of being put in share jail.",
              "See our documentation and blog pages for more information on these limits and share jail.",
            ]        
      },
      {
        // price_id: "price_1KbQlBCXj0cKCM7g49Nkf9Fp", // prod
        price_id: "price_1KcaStCXj0cKCM7ggjnWYrtW", // test 
        plan_title: "Quarterly",
        amount: "45",          
        product_items: [
          "Billed quarterly.",            
            "You save 10% on this plan!",              
            "You get everything in the Monthly plan and a discount!",                        
          ]        
    },  
    {
      // price_id: "price_1KbQnpCXj0cKCM7gqFvXpJTu", // prod
      price_id: "price_1KcaSLCXj0cKCM7gL3L5OOJf", // test 
      plan_title: "Half-yearly",
      amount: "96",          
      product_items: [
        "Billed half-yearly.",
        "You save 20% on this plan!",                                                                                             
        "You get everything in the Monthly plan and a discount!",                            
        ]        
  },  
      {
        // price_id: "price_1KbQcJCXj0cKCM7gFgUGdLoN", // prod
          price_id: "price_1KcaTwCXj0cKCM7gi4Ab4obz", // test          
          plan_title: "Yearly",
          amount: "120",          
          product_items: [
          "Billed annually",
          "You save 30% on this plan!",
          "You get everything in the Monthly plan and a discount!",                                    
      ]              
      },                 
    //  {
    //   plan_title: "Yearly To Lifetime",
    //   amount: "120",          
    //   product_items: [
    //     "Billed once!",
    //     "Upgrade to lifetime access!",              
    //     "You get everything in the yearly plan but for the duration (6 years) of your education at secondary school",
    //     "That is equivalent to US$40 per secondary school year!",                    
    //     ]           
    //   },     
      ],

    testimonyProps: [        
    ],    

    landingHeroProps: {
      heroType: 'left',    
      // heroType: 'right',    
      businessName: 'Remote Upskill',
      copyrightYears: '2020-2021',
      interLinks: [
        {name: 'Terms', url: 'https://companyrepo.com/terms'},
        {name: 'Privacy Policy', url: 'https://companyrepo.com/privacy_policy'},        
      ],      
      blogLinks: [
        {name: 'Why is Typescript so Popular?', url: 'https://grokkingcoding.io/blog/why-is-typescript-so-popular'},        
        {name: 'What is the Best Tech Stack?', url: 'https://grokkingcoding.io/blog/what-is-the-best-tech-stack'},
        {name: 'How Poweerful Is Javascript?', url: 'https://grokkingcoding.io/blog/how-powerful-is-javascript'},
        {name: 'Being A Programmer Can Change Your Life', url: 'https://grokkingcoding.io/blog/being-a-programmer-can-change-your-life'},        
      ],
      tutorialLinks: [
        {name: 'Dynamic', url: '/blog/dynamic'},
        {name: 'Dynamic', url: '/blog/dynamic'},
        {name: 'Dynamic', url: '/blog/dynamic'},      
      ],      
      socialLinks: [
        {name: 'Twitter', url: 'https://twitter.com/?lang=en'},        
        {name: 'team@companyrepo.com', url: ''},
      ],    
    },

    footerProps: {      
      businessName: 'Company Repo',
      copyrightYears: '2023-2024',
      features: [
        {name: 'Product research', url: `https://companyrepo.com/`},
        {name: 'Identifying Niche Opportunities', url: `https://companyrepo.com/`},
        {name: 'Analyzing Competition', url: `https://companyrepo.com/`},
      ],   
      knowledgeHub: [
        {name: 'Starting with Shopify', url: `https://www.shopify.com/hk-en/blog/topics/starting-up`},
        {name: 'Shopify Courses', url: `https://www.shopify.com/learn`},
        // {name: 'How to invest like Peter Lynch?', url: `https://companyrepo/blog/how-to-invest-like-peter`},
        // {name: 'How to invest like Peter Lynch?', url: `https://companyrepo/blog/how-to-invest-like-peter`},
        // {name: 'How to invest like Peter Lynch?', url: `https://companyrepo/blog/how-to-invest-like-peter`},
      ],         
      legal: [        
        {name: 'Terms', url: 'https://companyrepo.com/terms'},
        {name: 'Privacy Policy', url: 'https://companyrepo.com/privacy_policy'},        
        // {name: 'Getting Started', url: '/documentation/Getting-Started'},      
      ],
      social: [
        // {name: 'Twitter', url: 'https://twitter.com/?lang=en'},        
        {name: 'team@companyrepo.com', url: ''},
      ],    
    },                    
}; 

