import axios from 'axios'
import isEmpty from 'lodash.isempty'

class BaseService {
  constructor() {    
    this.headers = {      
      'Content-Type': 'application/json',      
    }          
  }  

  // merge input headers with default this.headers
  get = (url, params, headers) => {
    return axios({
      method: 'get',
      headers: Object.assign({}, this.headers, headers),
      url,
      params: params || {},
      responseType: 'json',      
    })
  }

  post = (url, payload, headers) => {
    if(isEmpty(url) || isEmpty(payload)) return `empty args cannot make POST request`;
    const data = JSON.stringify(payload);        
    return axios({
      method: 'post',
      headers: Object.assign({}, this.headers, headers),
      url,
      data,
      body: data,
      responseType: 'json',
    })
  }  
}

export default BaseService
