class HelperService {
  constructor() {}

  getRandomInt(min, max) {
    let funcName = `getRandomInt`;
    try {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  };   
}; 

export default HelperService;
