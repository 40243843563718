import React, { useEffect } from 'react'; 
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from "lodash.isempty";
import firebaseSer from './utils/services/FirebaseService';
import {  getDataAndReplenish } from './redux/actions';

export default function User({}) {
  const dispatch = useDispatch();
  const useReduxState = () => {
    return useSelector(
      (state) => ({        
        loadingTypes: state.loadingTypes,
        loginTime: state.loginTime,
        profile: state.profile,
        uuid: state.uuid,
      }),
      shallowEqual
    );
  };    
  const { loadingTypes, loginTime, profile, uuid } = useReduxState();
  useEffect(() => {        
    // replenish reducers
    dispatch(getDataAndReplenish());    
    console.log('log isAuthenticated in User', loginTime, profile, uuid); 
    // if(isEmpty(loginTime)) {      
    // }
    firebaseSer.listenAuthChange(dispatch, loadingTypes);
    firebaseSer.listenLiveUpdates(uuid, dispatch);
  }, [loadingTypes, loginTime, profile, uuid]);
  return null;
};
