/* eslint-disable jsx-a11y/img-redundant-alt */
// use this to display specific products in a category
// ------------------------------------------------------------------------------------------------------------------
import React, {useEffect, useState} from 'react';
// import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import axios from 'axios';
// ------------------------------------------------------------------------------------------------------------------
// test data:
// import {sampleData} from './sampleData123';
// import {sampleData} from './sampleData1234';
import {sampleData} from './sampleData12345';
// import {sampleData} from './sampleData123456';
// import {emojis} from './EmojiService';
// ------------------------------------------------------------------------------------------------------------------
import {sponsored} from './sampleData123456';
// ------------------------------------------------------------------------------------------------------------------
import Loading from "./Loading";
import BootstrapScrollingModal from './BootstrapScrollingModal';
// import CategoryProducts4 from "./CategoryProducts4";
// ------------------------------------------------------------------------------------------------------------------
import SearchService from './search/SearchService'; 
const searchSer = new SearchService();
// ------------------------------------------------------------------------------------------------------------------
const bigTitle = `Daily Shopping Deals`;
// const bigTitle = `Hourly Shopping Deals`;
const smallTitle = `from around the web.`;
const headers = {'Content-Type': 'application/json'};
const url = `https://veqofmbg38.execute-api.us-west-1.amazonaws.com/v1/cro-public-1/dashboard.json`; // put this in env var
// ------------------------------------------------------------------------------------------------------------------
// {
//     "officialUrl": "https://www.evil-bikes.com",
//     "isActive": true,
//     "location": null,
//     "storeName": "EVIL-BIKES",
//     "keyword": "Special offer",
//     "productTag": "offering",
//     "productUrl": "https://www.evil-bikes.com/collections/all/products/offering?variant=40436767621185",
//     "currencyInfo": null,
//     "symbol": "$",
//     "currencyCode": "USD",
//     "countryOfCurrency": "United States",
//     "nameOfCurrency": "Dollar",
//     "estimatedPrice": 3000,
//     "createdAt": 1702385778429
// },
// ------------------------------------------------------------------------------------------------------------------
// helper functions: 

const destructureApiData = (data) => {
    let funcName = `destructureApiData`;
    return new Promise(async (resolve, reject) => {
      try {      
        // console.log(`exec ${funcName}`);
        if(data) {          
          let {dataArray} = data;
          // dataArray here is actually an object
          if(dataArray) {            
            let products = dataArray;
            return resolve(products);
          }
        }
        return resolve(null);
    } catch (e) {                    
        console.log('error', e, e.message);
        reject(null);
    };
  });
};

// ------------------------------------------------------------------------------------------------------------------

const destructureApiData2 = (data) => {
    let funcName = `destructureApiData2`;
    return new Promise(async (resolve, reject) => {
      try {      
        // console.log(`exec ${funcName}`);
        let results1 = [];
        let results2 = [];
        if(data) {          
          let {dataArray} = data;                    
          if(dataArray) {
            let obj1 = dataArray[0];
            let obj2 = dataArray[1];
            // put sponsored at the front
            if(obj1) {                
                let {sponsored} = obj1;
                if(Array.isArray(sponsored)) {
                    if(sponsored.length) {
                        results1 = results1.concat(sponsored);
                    }
                }
            }
            if(obj2) {
                let {products} = obj2;
                if(Array.isArray(products)) {
                    if(products.length) {
                        results2 = results2.concat(products);
                    }
                }
            }            
          }
        }
        let results3 = results1.concat(results2);
        return resolve(results3);
    } catch (e) {                    
        console.log('error', e, e.message);
        reject(null);
    };
  });
};

// ------------------------------------------------------------------------------------------------------------------

export const Loader = ({isLoading}) => {
    return <>       
            <div         
                id="view-form"             
                className="d-flex justify-content-center mt-3 mb-2"
            >    
                <small
                style={{
                    marginLeft: '1rem',
                    color: 'teal',
                    fontWeight: 'bolder',
                }}
                >{`Fetching the latest deals now, please wait...`}</small>
        </div>
                <div           
                    id="view-form" 
                    style={{
                        marginTop: '5rem',
                        marginBottom: '5rem'
                    }}
                    className="d-flex justify-content-center"
                >    
                <Loading
                        loading={isLoading} 
                        // type={`puff`} // puff does ot show properly
                        // type={`threedots`}
                        type={`threecircles`}                                    
                        height={`2rem`}
                        width={`2rem`}
                        /> 
                </div>          
    </>
};


// ------------------------------------------------------------------------------------------------------------------

export const Title = ({}) => {
    return <>      
     <div
        id="searchbox"
    >
        <div           
                id="view-form" 
                style={{
                    marginTop: '5rem',                    
                }}
                className="d-flex justify-content-around mt-3 mb-2"
            >    
                <h1 
                    style={{marginTop:'10rem'}}
                    className="display-5 fw-bold lh-1 mb-3"
                >
                    {`${bigTitle}`}
                </h1>            
            </div>
            <div         
                id="view-form"             
                className="d-flex justify-content-center mt-3 mb-2"
            >    
            <i 
            style={{                
                color: 'indigo', 
                fontSize: '3rem', 
            }}
            className='fas fa-shopping-bag'/>
            </div>            
            <div         
                id="view-form"             
                className="d-flex justify-content-center mt-3 mb-2"
            >    
                <small
                style={{
                    marginLeft: '1rem',
                    color: 'teal',
                    fontWeight: 'bolder',
                }}
                >{`${smallTitle}`}</small>
        </div>
    </div>
    </>
};

// ------------------------------------------------------------------------------------------------------------------
// here baby 
// we still need the filter key button 

export const SearchBox = ({productList, stateChanger}) => {

    const onSearch = async (filterKey, productList, searchText) => {
        // console.log(`exec onSearch`, filterKey, productList, searchText);
        let results = [];
        if(searchText && productList.length) {            
            let res = await searchSer.quickSearch(filterKey, productList, searchText);
            if(res.length) {
                results = results.concat(res);
                stateChanger(results ? results : []);
                return results;
            }
        }
    };

    const onChange = (e) => {
        let key = e.target.name
        let value = e.target.value        
        let searchLength = 1;     
        console.log(`value`, value);
        if (value && value.length >= searchLength) {
            console.log(`log key`, key);            
            if(productList.length) {
                let productList2 = [...productList];
                onSearch(`filterByCategory` , productList2, value);
            }            
        } else {
            console.log(`return to original list`, key);
            stateChanger([]);
        }
    };

    const onKeyDown = (e) => {        
        if (e.key === 'Enter') {      
            onChange(e);
        }
    };

    return <>
    <div>
    <div class="p-5 mb-4 bg-body-tertiary rounded-3">
        <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Search for your ideal products!</h1>
        <p class="col-md-8 fs-4">{`Try typing "sneakers"`}</p>

        <div           
                id="view-form" 
                style={{
                    marginTop: '5rem',
                    marginBottom: '5rem'
                }}
                className="d-flex justify-content-center"
            >                    
                <input
                    style={{
                        width: '20rem',
                        marginRight: '0.5rem',
                        border: `2px solid teal`
                    }}
                    type="search" 
                    placeholder="Product category or description"
                    aria-label="Search"
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                <button 
                    class="btn btn-outline-success my-2 my-sm-0"                     
                    type="submit"
                    // type="button"
                >
                    Search
                </button>
        </div> 

        {/* <button class="btn btn-primary btn-lg" type="button">Example button</button> */}
        </div>
    </div>

        {/* <div           
                id="view-form" 
                style={{
                    marginTop: '5rem',
                    marginBottom: '5rem'
                }}
                className="d-flex justify-content-center"
            >                    
                <input
                    style={{
                        width: '20rem',
                        marginRight: '0.5rem',
                        border: `2px solid teal`
                    }}
                    type="search" 
                    placeholder="Product category or description"
                    aria-label="Search"
                    onChange={(e) => onChange(e)}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                <button 
                    class="btn btn-outline-success my-2 my-sm-0"                     
                    type="submit"
                    // type="button"
                >
                    Search
                </button>
        </div>          */}
    </div>
    </>
};

// ------------------------------------------------------------------------------------------------------------------

export const SponsoredTag = ({}) => {    
    return <>      
    <span                         
        style={{
            cursor: 'pointer',                                   
            background: 'indigo',
            color: '#fff'
        }}
        id="sponsoredTag"
        className="tagit"                        
        data-object={"sponsoredTag"}        
        >                                    
        {`${`Sponsored`}`}
    </span>    
    </>
};

// ------------------------------------------------------------------------------------------------------------------

export const ProductTips = ({productTip, isSponsored}) => {
    const [modalShow, setModalShow] = useState(false);    
    const [theQuestion, setQuestion] = useState(null);    
    async function openBox(e, productTip) {
        try {
            console.log('exec openBox')            
            setQuestion(productTip)
            setModalShow(true)            
        } catch(err) {
            console.log(`log err in openBox: ${err}`);
        }; 
    }; 
    return <>
    <BootstrapScrollingModal props={theQuestion} show={modalShow} onHide={() => setModalShow(false)} />
    {/* <span                         
        style={{
            cursor: 'pointer',                                      
        }}
        id="productTip"
        className="tagit"                        
        data-object={"operationKeywords"}                                        
        onClick={(e) => openBox(e, productTip)}
        >                                    
        {`${`Product Tips`}`}
    </span> */}
    <a                                    
        onClick={(e) => openBox(e, productTip)}
        style={{
            // background: 'teal',
            background: `${isSponsored ? 'indigo' : 'teal'}`,
            // here baby 
            color: 'white',                                        
        }}
        class="btn"
    >
    {`${`Product Tips`}`}
    </a>    
    </>
};

// ------------------------------------------------------------------------------------------------------------------
// we add the image link on the backend 
// {
//   "symbol": "$",
//   "currencyCode": "USD",
//   "countryOfCurrency": "United States",
//   "nameOfCurrency": "Dollar",
//   "productUrl": "https://omg-olive-oil.myshopify.com/collections/all/products/hot-hot-hot-dipping-oil",
//   "estimatedPrice": 17.99
// },
// ------------------------------------------------------------------------------------------------------------------
// A single card is used to display a keyword + its product links


export const RenderCards = ({obj}) => {
    return <>      
         <div class="card-columns">
                            <div                       
                            className="card"
                            style={{
                                width: '25rem',                                                          
                                margin: '1rem',
                                border: `${obj.isSponsored ? 'indigo 2px solid' : 'teal 2px solid'}`
                            }}
                            >
                            {/* <div
                                id="view-form"                         
                                className="d-flex justify-content-around mt-3 mb-2"
                            >    
                                
                                <h5                            
                                style={{                                                        
                                    color: 'teal'
                                }}
                                >
                                    {`${obj.keyword.toUpperCase()}`}
                                </h5>

                                <>
                                {obj.keyword.toLowerCase() === `jacket` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#129509;
                                    </span>
                                ) : (<></>)}
                                </>

                                <>
                                {obj.keyword.toLowerCase() === `jeans` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128086;
                                    </span>
                                ) : (<></>)}
                                </>

                                <>
                                {obj.keyword.toLowerCase() === `tshirt` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                                                                
                                    </span>
                                ) : (<></>)}
                                </>

                                <>
                                {obj.keyword.toLowerCase() === `dresses` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128087;
                                    </span>
                                ) : (<></>)}
                                </>      

                                <>
                                {obj.keyword.toLowerCase() === `hats` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128082;
                                    </span>
                                ) : (<></>)}
                                </>  

                                <>
                                {obj.keyword.trim().toLowerCase() === `activewear` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#127939;
                                    </span>
                                ) : (<></>)}
                                </>
                                
                                <>
                                {obj.keyword.toLowerCase() === `glasses` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128083;
                                    </span>
                                ) : (<></>)}
                                </>                                              

                                   <>                                
                                { obj.keyword.trim().toLowerCase().match(new RegExp(`sneakers`, 'ig')) 

                                    // || obj.productTag.trim().toLowerCase().match(new RegExp(`sneakers`, 'ig'))

                                    ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128095;
                                    </span>
                                ) : (<></>)}
                                </>  

                                     <>                                
                                { obj.keyword.trim().toLowerCase().match(new RegExp(`shoe`, 'ig')) 

                                    // || obj.productTag.trim().toLowerCase().match(new RegExp(`shoe`, 'ig'))

                                ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128095;
                                    </span>
                                ) : (<></>)}
                                </>      

                                     <>                                
                                { obj.keyword.trim().toLowerCase().match(new RegExp(`fitness`, 'ig')) ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#127940;
                                    </span>
                                ) : (<></>)}
                                </>                                                                                                

                                <>
                                {obj.keyword.trim().toLowerCase() === `gloves` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#129508;
                                    </span>
                                ) : (<></>)}
                                </>                 

                                <>
                                {obj.keyword.toLowerCase() === `gaming console` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#127918;
                                    </span>
                                ) : (<></>)}
                                </>                             
                                
                                <>
                                {obj.keyword.toLowerCase() === `handbag` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128092;
                                    </span>
                                ) : (<></>)}
                                </>

                                <>
                                {obj.keyword.toLowerCase() === `sunglasses` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128374;
                                    </span>
                                ) : (<></>)}
                                </>  

                                <>
                                {obj.keyword.toLowerCase() === `tshirt` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#128085;
                                    </span>
                                ) : (<></>)}
                                </>                                

                                <>
                                {obj.keyword.trim().toLowerCase() === `winter apparel` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}
                                    >                                        
                                        &#129509;
                                    </span>
                                ) : (<></>)}
                                </>                                

                                <>
                                {obj.keyword.trim().toLowerCase() === `wearables` ? (
                                    <span
                                        style={{                                                        
                                            color: 'teal',
                                            fontSize: '30px'
                                        }}                                        
                                    >                                        
                                        &#129343;
                                    </span>
                                ) : (<></>)}
                                </>                                                                 
                            </div>     */}

                            <div class="card-body">
                                
                                {/* <h5 class="card-title">
                                  {`${dataKey.toUpperCase()}`}                                  
                                </h5> */}

                                <p
                                  class="card-text"
                                  style={{                    
                                      textAlign: 'left', 
                                      marginBottom: '1rem',
                                      fontWeight: 'bolder',
                                      color: 'var(--twitter-blue)'
                                    //   --coolBgFBColor4: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
                                    //   --color-theme-3-999: #93ffd8;
                                  }}
                                  >                    
                                      {' '}
                                      <span
                                          style={{
                                          color: 'teal',
                                          fontWeight: 'bold',
                                      }}
                                      >                                      
                                      {`Product details:`}
                                      </span> {' '}
                                      <p                      
                                            style={{
                                            marginTop: '0.5rem'
                                            }}                           
                                            dangerouslySetInnerHTML={{
                                                __html: `${obj.productTag ? obj.productTag : ""}`,
                                            }}
                                            >                
                                        </p>          
                                        {/* {obj.productTag} */}
                                  </p>

                                {/* <p
                                  class="card-text"
                                  style={{                    
                                      textAlign: 'left', 
                                      marginBottom: '1rem',           
                                    //   color: 'indigo',
                                      color: 'var(--twitter-blue)'
                                  }}
                                  >
                                      {' '}
                                      <span
                                          style={{
                                            color: 'teal',             
                                            fontWeight: 'bold',                        
                                      }}
                                      >                                      
                                      {`Store Name:`}
                                      </span> {' '}                    
                                      {`${obj.storeName}`}
                                  </p> */}

                                  <p
                                  class="card-text"
                                  style={{                    
                                      textAlign: 'left', 
                                      marginBottom: '1rem',           
                                  }}
                                  >
                                      {' '}
                                      <span
                                          style={{
                                          color: 'teal',
                                          fontWeight: 'bold',                        
                                      }}
                                      >                                      
                                      {`Store Website:`}
                                      </span> {' '}
                                      <a                                  
                                        style={{
                                            // color: 'blue',        
                                            color: 'var(--twitter-blue)',
                                            cursor: 'pointer'                            
                                            // textDecoration: 'underline',
                                        }}                                                         
                                        onClick={() =>
                                            window.open(
                                                `${obj.officialUrl}`,
                                                "_blank"
                                            )
                                        }
                                    >
                                            {`${obj.officialUrl}`}
                                        </a>
                                      {/* <span
                                        class="text-primary"
                                      >{`${obj.officialUrl}`}</span> */}
                                  </p>
                                  

                                <div                                
                                    className="d-flex justify-content-around"
                                    style={{
                                        marginTop: '2rem'
                                    }}  
                                >              

                                {/* <ProductTips
                                    isSponsored={obj.isSponsored}
                                    productTip={{"question":`       
                                    <p>
                                    Jeans come in a wide variety of styles and cuts, each with its own unique features and characteristics. Here are some of the most popular types of jeans:
                                                    </p>
                                    <table style="width: 100%; border: 0; cellspacing: 0; cellpadding: 0;">
                                    <tr>
                                        <td>    
                                            <ul>
                                                <li>
                                                    <p>
                                                    1. Straight Leg Jeans: Straight leg jeans have a consistent width from the thigh down to the ankle, creating a straight and streamlined silhouette.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                    2. Bootcut Jeans: Bootcut jeans are fitted through the thigh and knee and then slightly flare out from the calf to accommodate wearing boots.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                    3. Skinny Jeans: Skinny jeans are form-fitting and tightly hug the legs from the waist down to the ankles, creating a sleek and slimming look.
                                                    </p>
                                                </li>
                                                <li>
                                                    <p>
                                                    4. Flare Jeans: Flare jeans are fitted through the thigh and knee and then gradually widen from the knee to the ankle, creating a distinct bell-bottom shape.
                                                    </p>
                                                </li>                                    
                                                <li>
                                                    <p>
                                                    5. Wide-Leg Jeans: Wide-leg jeans have a loose and relaxed fit throughout the legs, with a wider opening at the ankles.
                                                    </p>
                                                </li>
                                            </ul>            
                                        </td>   
                                    </tr> 
                                    <p>
                                    These are just a few examples of the many different varieties of jeans available. Fashion trends and styles evolve over time, so new variations may emerge as well.
                                    </p>                                    
                                    </table>`,"req":"Multiple Choice on concepts of integer, fraction and decimal","ans":"D","ans_full_text":"Integer is a number without decimal point(&#34;.&#34;) and fraction bar(&#34;/&#34;), so A and B are wrong<br>There is a letter(&#34;x&#34;) in C, which is not a number, so C is wrong<br>Hence the answer is D."}}
                                /> */}



                                    <ProductTips
                                        isSponsored={obj.isSponsored}
                                        productTip={{"question": obj.productTips ? obj.productTips : "","req":"","ans":"","ans_full_text":""}}
                                    />
                                
                                    <a                                    
                                    onClick={() =>
                                        window.open(                                        
                                            obj.productUrl ? obj.productUrl : `https://companyrepo.com`,
                                            "_blank"
                                        )
                                    }
                                    style={{
                                        background: `${obj.isSponsored ? 'indigo' : 'teal'}`,
                                        color: 'white',                                        
                                    }}
                                    class="btn"
                                >
                                    {`Go see product!`}
                                    </a>
                                </div>

                                {
                                    obj.isSponsored ? <>
                                     <div                                
                                    className="d-flex justify-content-end"
                                    style={{
                                        marginTop: '2rem',                                    
                                    }}  
                                    >                                 
                                        <SponsoredTag/>
                                    </div>
                                    </> : <></>
                                }
                                                           
                                <p 
                                class="card-text"
                                style={{
                                    marginTop: '2rem'
                                }}  
                                >
                                    {/* <small class="text-muted">Last updated 3 mins ago</small> */}
                                    
                                    <small class="text-muted">{`Last updated: ${obj.createdAt ? `${new Date(obj.createdAt).toLocaleTimeString()} ${new Date(obj.createdAt).toLocaleDateString()}` : ''}`}</small>
                                </p>
                            </div> 
                        </div> 
                    </div> 
    </>
};


export const Cards = ({products}) => {
    // ------------------------------------------------------------------------------------------------------------------
      const [data, setData] = useState([]);
      const [filteredData, setFilteredData] = useState([]);
      useEffect(() => {
        // console.log('log Cards', products);
        if(Object.keys(products).length) {
          setData(products)
        }        
      }, [products]);
    // ------------------------------------------------------------------------------------------------------------------
    // if setFilteredData has length we display filtered data 
    // when user removes all string in search box, we make setFilteredData to EMPTY array
    return <>        
        <SearchBox
            productList={data}
            stateChanger={setFilteredData}
        />
        {/* <CategoryProducts4 sponsored={sponsored ? sponsored : []}/> */}
          <div
            className="card-group"
            style={{
              marginTop: '3rem',              
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            //   background: 'indigo',
            //   width: '100%'
            }}
          >            

            {
                filteredData.length ? 
                    <>
                        {
                            filteredData.slice(0, data.length-1).map((obj, index) => (
                                <>
                                <RenderCards 
                                    obj={obj}
                                />
                                </>
                            ))
                        }
                    </> : 
                    <>              
                        {
                            // we display original list from server when there are no filtered lists
                                Array.isArray(data) ? 
                                    data.slice(0, data.length-1).map((obj, index) => (
                                    <>
                                    <RenderCards 
                                        obj={obj}
                                    />
                                    </> )) : <></>
                        }                       
                    </>
            }          
          </div>
    </>
};

// ------------------------------------------------------------------------------------------------------------------
// this version only uses array to show everything 
// the keyword is already in each product object

export default function CategoryProducts() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // ------------------------------------------------------------------------------------------------------------------
      useEffect(() => {        
        // console.log('log sampleData', sampleData);
        // console.log('log products', products);        
        // ------------------------------------------------------------------------------------------------------------------
        setIsLoading(true);
        setTimeout(() => {
        //   testApi(sampleData);
          getData();
        }, 3000);
      }, []);
        // ------------------------------------------------------------------------------------------------------------------
        // test function:
        const testApi = (data) => {
            let funcName = `testApi`;
            return new Promise(async (resolve, reject) => {
                try {      
                // console.log(`exec ${funcName}`);
                let products = await destructureApiData(data);
                if(Array.isArray(products)) {
                    if(products.length) {
                        // console.log(`log products ${funcName}`, products);
                        setProducts(products);
                        setIsLoading(false);
                    }            
                }            
                return resolve(true);
            } catch (e) {                    
                console.log('error', e, e.message);
                reject(null);
                setIsLoading(false);
            };
            });
        };
        // ------------------------------------------------------------------------------------------------------------------
        const getData = (e) => {
            let funcName = `getData`;
            try {                      
                return axios({
                method: 'get',
                headers: headers,
                url,      
                responseType: 'json',
                }).then(async res => {                
                let {data, status, statusText, headers, config} = res;                
                if(!isEmpty(data)) {                    
                    let products = await destructureApiData(data);
                    if(Array.isArray(products)) {
                        if(products.length) {                            
                            setProducts(products);
                            setIsLoading(false);
                        }            
                    }           
                }                  
                }).catch(error => {      
                    console.log(`log err hit api`, error, error.message);
                    setIsLoading(false);
                    return null;                    
                });        
            } catch (e) {                    
                console.log('error', e, e.message);
                setIsLoading(false);
                return null;
            };
        };
    
    // ------------------------------------------------------------------------------------------------------------------
    return <>        
        <Title/>        
        {isLoading ?                
            <>
                <Loader
                    isLoading={isLoading}
                />
            </>
            : 
            <>                
                <Cards 
                    products={products.length ? products : []}
                />
            </>
        }        
    </>
};
