import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { escape } from 'html-escaper';
import { INVESTMENT_QUOTES } from '../../data/investQuotes';

class HelperService {
  constructor() {}


    getRandomQuotes() {
        let funcName = `getRandomQuotes`;
        try {                      
            let list = Object.keys(INVESTMENT_QUOTES)
            let listLen = list.length-1;
            let randomIndex = Math.floor(Math.random() * listLen);
            let randomKey = list[randomIndex];            
            let quote = randomKey;
            let author = INVESTMENT_QUOTES[randomKey]         
            return {quote, author};
        } catch (err) {
            console.log('error', `At ${Date.now()} in ${funcName} error code: ${err}`);
        }
    }; 

  extractedEmail(text) {
    let funcName = `extractedEmail`;
    try {
      return text.match(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
      );
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  onFilter(str) {
    let funcName = `onFilter`;
    try {      
      let searchLen = 3;
      let results;
      let database = { test: 'this is a test' };
      let databaseKeys = Object.keys(database);
      if (!isEmpty(str) && str.length > searchLen) {
        results = databaseKeys.filter((item) =>
          new RegExp(str.toLowerCase()).test(item.toLowerCase())
        );
      }
      return results;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  getRandomInt(min, max) {
    let funcName = `getRandomInt`;
    try {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  async findMinimumInOneArray(arr) {
    let funcName = `getMemberStatus`;
    try {
      let newArr = [...arr];
      let currenMin = Infinity;
      let getMin = (num) => Math.min(num, currenMin);
      return newArr.find(getMin);
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  async mergeTwoSortedArr(arr1, arr2) {
    let funcName = `mergeTwoSortedArr`;
    try {
      if (!arr1.length && !arr2.length) {
        return [];
      }
      if (!arr1.length && arr2.length) {
        return arr2;
      }
      if (arr1.length && !arr2.length) {
        return arr1;
      }
      return [...arr1, ...arr2].sort((a, b) => a - b);
    } catch (err) {      
      console.error(`err in ${funcName}: ${err}`);
    }
  }

  async validEmail(str) {
    // should have standard email format
    // should include .com
    let funcName = `validEmail`;
    try {      
      let re = /^[\w"]+[\w.!#$%&’*+\/=?^_`{|}~-].@[\w-\[]+(?:\.[\w-\]]+)+$/;      
      let re2 = /(.com)/gi;
      let test1 = str.match(re);
      let test2 = str.match(re2);
      if (!test1) {        
        return false;
      }
      if (!test2) {        
        return false;
      }      
      return true;
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  validPassword(str) {
    let funcName = `validPassword`;
    try {
      let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@_&%$"!]).{6,12}$/g;      
      let test1 = str.match(re);      
      if (!test1) {        
        return false;
      }      
      return true;
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 

  async sanitizeData(str) {
    // use this function before displaying data in components or before setting state
    // all the places need cleaning:
    // profile page
    // userwelcomebox
    let funcName = `sanitizeData`;
    try {
      if (isEmpty(str)) return 'arg empty';
      let testData = String.raw`
        <script>
          alert('I am not sanitized!');
        </script>
        `;
      let cleanedData = escape(str);
      //console.log('log cleanedData', cleanedData);
      return cleanedData;
    } catch (err) {
      //console.log(`err in ${funcName}: ${err}`);
      console.error(`err in ${funcName}: ${err}`);
    }
  }; 
  
}; 

export default HelperService;
