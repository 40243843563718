export const trendingSecInCA = {    
        "finance": {
            "result": [
                {
                    "count": 20,
                    "quotes": [
                        {
                            "symbol": "TD.TO"
                        },
                        {
                            "symbol": "SOT-UN.TO"
                        },
                        {
                            "symbol": "ABNB"
                        },
                        {
                            "symbol": "LSPD.TO"
                        },
                        {
                            "symbol": "WAL"
                        },
                        {
                            "symbol": "JNJ"
                        },
                        {
                            "symbol": "SPY"
                        },
                        {
                            "symbol": "LAC.TO"
                        },
                        {
                            "symbol": "PLTR"
                        },
                        {
                            "symbol": "CRWD"
                        },
                        {
                            "symbol": "TSLA"
                        },
                        {
                            "symbol": "NVDA"
                        },
                        {
                            "symbol": "^IXIC"
                        },
                        {
                            "symbol": "AI"
                        },
                        {
                            "symbol": "MG.TO"
                        },
                        {
                            "symbol": "AC.TO"
                        },
                        {
                            "symbol": "AMD"
                        },
                        {
                            "symbol": "BLDP.TO"
                        },
                        {
                            "symbol": "SQQQ"
                        },
                        {
                            "symbol": "BABA"
                        }
                    ],
                    "jobTimestamp": 1680710628802,
                    "startInterval": 202304051500
                }
            ],        
        }    
}