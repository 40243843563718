import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import axios from 'axios';
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
import Loading from './Loading';
import {appleCoMajorShareHolderBreakdown} from './data/appleCompany-majorHoldersBreakdown-1680708046620';
import {marketSummaryCA} from './data/getMarketSummary-CA-1680709543805';
import {marketSummaryJP} from './data/getMarketSummary-JP-1680720267534';
import {marketSummaryUS} from './data/getMarketSummary-US-1680720207959';
import {trendingSecInCA} from './data/getTrendingSecurities-CA-1680713542814';


// using script tags and shows how to load a script inside a component
// const RenderChart = (chartData) => {
//     // <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
//     useEffect(() => {
//       const script = document.createElement('script');
//       script.src = "https://use.typekit.net/foobar.js";
//       script.async = true;    
//       document.body.appendChild(script);    
//       return () => {
//         document.body.removeChild(script);
//       }      
//     }, []);    
//     // google.charts.load('current', {'packages':['corechart']});
//     // google.charts.setOnLoadCallback(drawChart);
//     return (
//         <>
//            <div className='d-flex'>
//            </div>
//         </>
// )};


const RenderChart = ({chartData}) => {
    // leanring points:
    // when cretaing financial charts as each chart is very specific 
    // we need to create specfic types of chart different data types
    // <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
    // const labels = ["January", "February", "March", "April", "May", "June"];
    const [labels, setLabels] = useState([]);  
    const [dataChart, setChartData] = useState([]);
    const [title, setChartTitle] = useState([]);
    const [institutionsTotal, setInstitutionsTotal] = useState(``);    
    useEffect(() => {            
      if(!isEmpty(chartData)) {
        let {data, chartTitle} = chartData;
        let {majorHoldersBreakdown} = data;
        let {insidersPercentHeld, institutionsPercentHeld, institutionsFloatPercentHeld, institutionsCount} = majorHoldersBreakdown;                                      
        let insidersPercent = Math.ceil(insidersPercentHeld.raw*100);        
        let instiPercent = Math.floor(institutionsPercentHeld.raw*100);
        let remainingPercent = 100-instiPercent-insidersPercent;
        let chartLabels = [`Insiders ${insidersPercentHeld.fmt}`, `Institutions ${institutionsPercentHeld.fmt}`, `Remaining ${remainingPercent}%`];
        let chartDataArr = [
            insidersPercent,
            Math.floor(institutionsPercentHeld.raw*100), 
            remainingPercent,
        ];
        console.log(`log RenderChart`, Math.floor(institutionsPercentHeld.raw*100), insidersPercent);
        // insidersPercentHeld.fmt
        // institutionsPercentHeld.fmt
        // institutionsFloatPercentHeld.fmt
        // institutionsCount.fmt
        // "fmt": "0.07%"
        // "fmt": "61.29%"
        setChartTitle(chartTitle)
        setLabels(chartLabels)
        setChartData(chartDataArr)            
        setInstitutionsTotal(institutionsCount.fmt)
      }
      return () => {        
      }      
    }, []);                
    const data = {
    //   backgroundColor: "rgb(255, 99, 132)",
    //   borderColor: "rgb(0,0,255)",
    //   data: [0, 10, 5, 2, 20, 30, 45],
      labels: labels,      
      datasets: [
        {
          label: title,        
          backgroundColor: [
            "#007D9C",
            "#244D70",
            "#D123B3",
            // "#F7E018",
            // "#fff",
            // "#FE452A",
          ],        
        data: dataChart,
        },
      ],
    };
    return (
        <>
           <div 
            style={{
                height: '20rem',
                width: '20rem',                
                // display: 'flex',
                // justifyContent: 'center', // this did not
                margin: '0 auto' // this centred the element                
            }}
            // className='d-flex'
            >
            <Pie data={data} />    
            <p
                style={{                                
                    padding: '0.2rem',
                    marginTop: '1rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}        
            >
            {`Total instituional investors ${institutionsTotal}`}
            </p>            
           </div>
        </>
)};

const renderRevenue = (indexKey, companyRevenue) => {
    return (
        <>
             <tr
                key={indexKey}
                style={{
                background: 'var(--light)',
                color: 'var(--dark)',
                }}
                >
                <th 
                scope="row"                          
                >                    
                <td>Revenue in the region of:</td>
                </th>                                                        
                <td>$ {companyRevenue} </td>
            </tr>
        </>
)};

const renderOperationType = (indexKey, operationTags) => {
    return (
        <>
              <tr
                    key={indexKey}
                    style={{
                    background: 'var(--light)',
                    color: 'var(--dark)',
                    }}
                    >
                    <th 
                    scope="row"                          
                    >                    
                        <td>Operating as:</td>
                    </th>
                    {
                        operationTags.length > 0 ? 
                        <>
                            {operationTags.map(tag => (
                                <td>{tag}</td>
                            ))}                            
                        </> 
                        : 
                        <></>
                    }                    
            </tr>
        </>
)};

const renderCompanyLocation = (indexKey, companyLocation) => {
    console.log(`log renderCompanyLocation`, indexKey, companyLocation)
    return (
        <>
             <tr
                key={indexKey}
                style={{
                    background: 'var(--light)',
                    color: 'var(--dark)',
                }}
                >
                <th scope="row">                    
                <td>Company location:</td>
                </th>        
                <td>
                    {companyLocation}
                </td>
            </tr>   
        </>
)};

const renderCompanyUrl = (indexKey, companyUrl) => {
    console.log(`log renderCompanyUrl`, indexKey, companyUrl)
    return (
        <>
             <tr
                key={indexKey}
                style={{
                    background: 'var(--light)',
                    color: 'var(--dark)',
                }}
                >
                <th scope="row">                    
                <td>Company URL:</td>
                </th>        
                <td>
                <a                                  
                    style={{
                        color: 'blue',                        
                        cursor: 'pointer'                            
                        // textDecoration: 'underline',
                    }}                                                         
                    onClick={() =>
                        window.open(
                            `${companyUrl}`,
                            "_blank"
                        )
                    }
                >
                        {`${companyUrl}`}
                    </a>                                    
                </td>                        
            </tr>
        </>
    )
}; 

const renderTable = ({indexKey, dataObj}) => {
    console.log(`log renderTable`, indexKey, dataObj)
    return (
      <>
         <table 
            id='mobileviewtable1'
            style={{
                color: "#fff",
            }}                             
            className="table"
        >                
            <tbody>            
                {
                    dataObj.companyUrl ? 
                    <>
                        {renderCompanyUrl(indexKey, dataObj.companyUrl)}
                    </> : <></>
                }
                {
                    dataObj.companyLocation ? 
                    <>
                        {renderCompanyLocation(indexKey, dataObj.companyLocation)}
                    </> : <></>
                }
                {
                    dataObj.operationTags ? 
                    <>
                        {renderOperationType(indexKey, dataObj.operationTags)}
                    </> : <></>
                }                       
            </tbody>
        </table> 
      </>
    )
}

const CompanyCardStaticContent = ({dataObj}) => {
    useEffect(() => {                        
      console.log('mounted CompanyCardStaticContent', dataObj);      
    }, [dataObj]);                               
    // destructure dataObj to decide what to redner
    let {title, ticker, companyName, chartData, marketData} = dataObj;
    return (
      <>         
          <div 
            className="card company-card"
           >                        
            {
                <div className="card-header">
                    <h5
                        style={{                                
                            padding: '0.2rem',
                            marginTop: '1rem',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}                
                    >                            
                        {`${companyName} (${ticker})`}
                    </h5>
                </div>
            }

            {
                chartData ? <>
                 <div className="card-body">
                <h5 
                style={{                                
                    padding: '0.2rem',
                    marginBottom: '1rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}           
                className="card-title">
                    {`Major Shareholders Breakdown`}
                </h5>                                            
                <RenderChart chartData={chartData}/>                
                </div>
                </> :<></>
            }
                             
            <div className="card-body">              
            <hr/>
            {
                  <div className="card-body">              
                  <div className="card-body">
                          <h5 className="card-title">Company Profile Highlights</h5>
                          <p className="card-text">
                              {`Below are some essential information you should be aware of before investing in:`}
                              <span                                   
                                  style={{
                                      marginLeft: '0.5rem',
                                      cursor: 'pointer',
                                      fontWeight: 'bolder',
                              }}                                
                              >{`${companyName}`}</span>
                          </p>
                  </div>
              <hr/>
                  <ul className="list-group list-group-flush">
                      {
                          dataObj.companyLatestNews ? 
                              <>
                                  {
                                      dataObj.companyLatestNews.map(newsLink => (
                                          <li className="list-group-item">
                                              {/* <a href="newsLink" className="card-link">
                                                  {newsLink}
                                              </a> */}
                                              <a              
                                                  style={{
                                                      color: 'blue',
                                                      // textDecoration: 'underline',
                                                      cursor: 'pointer'                            
                                                  }}                                 
                                                  // className="card-link"                     
                                                  onClick={() =>
                                                      window.open(
                                                          `${newsLink}`,
                                                          "_blank"
                                                      )
                                                  }
                                                  >
                                                      {`${newsLink}`}
                                                  </a>            
                                          </li>
                                      ))
                                  }                                            
                              </> 
                          : <></>                                    
                      }        
                  </ul>    
                  <p 
                      style={{
                          marginTop: '2rem'
                      }}
                      className="card-text">
                          {`Click the link for the full story.`}
                  </p>
                  <p                                 
                      className="card-text">                                    
                          {`You will be taken to the third party website where the article originates from.`}
                  </p>
                  <small                                 
                  style={{
                      marginBottom: '2rem'
                      }}
                      className="card-text">                                    
                          {`Company Repo is not responsible for the content or practices of third party websites. Company Repo is also not responsible for any information that you might share with such linked websites. You should refer to each website’s respective privacy policy and practices prior to disclosing any information.`}
                  </small>                                                                                               
              </div>
            }                                       
            </div>
        </div>         
      </>
  )}; 

const CompanyCardContent = ({indexKey, dataObj, isChart, chartType, chartData}) => {
    useEffect(() => {                        
      console.log('mounted CompanyCardContent in CompanyCard.js indexKey, dataObj', 
        indexKey, dataObj, isChart, chartType, chartData,
      );
    }, [indexKey, dataObj]);                                                    
    return (
      <>         
          <div 
            className="card company-card"
           >                        
            {
                <div className="card-header">
                    <h5
                        style={{                                
                            padding: '0.2rem',
                            marginTop: '1rem',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}                
                    >                            
                        {`${dataObj.cleanedCompanyName}`}
                    </h5>
                </div>
            }            

        <>
         <table 
            id='mobileviewtable1'
            style={{
                color: "#fff",
            }}                             
            className="table"
        >                
            <tbody>            
                {
                    dataObj.companyUrl ? 
                    <>
                        {renderCompanyUrl(indexKey, dataObj.companyUrl)}
                    </> : <></>
                }
                {
                    dataObj.companyLocation ? 
                    <>
                        {renderCompanyLocation(indexKey, dataObj.companyLocation)}
                    </> : <></>
                }
                {
                    dataObj.operationTags ? 
                    <>
                        {renderOperationType(indexKey, dataObj.operationTags)}
                    </> : <></>
                }                       
            </tbody>
        </table> 
      </>               

            <div className="card-body">              
                <div className="card-body">
                        <h5 className="card-title">News Highlights</h5>
                        <p className="card-text">
                            {`Here are the links to the latest news articles for:`}
                            <span                                   
                                style={{
                                    marginLeft: '0.5rem',
                                    cursor: 'pointer',
                                    fontWeight: 'bolder',
                            }}                                
                            >{`${dataObj.cleanedCompanyName}`}</span>
                        </p>
                </div>
            <hr/>
                <ul className="list-group list-group-flush">
                    {
                        dataObj.companyLatestNews ? 
                            <>
                                {
                                    dataObj.companyLatestNews.map(newsLink => (
                                        <li className="list-group-item">
                                            {/* <a href="newsLink" className="card-link">
                                                {newsLink}
                                            </a> */}
                                            <a              
                                                style={{
                                                    color: 'blue',
                                                    // textDecoration: 'underline',
                                                    cursor: 'pointer'                            
                                                }}                                 
                                                // className="card-link"                     
                                                onClick={() =>
                                                    window.open(
                                                        `${newsLink}`,
                                                        "_blank"
                                                    )
                                                }
                                                >
                                                    {`${newsLink}`}
                                                </a>            
                                        </li>
                                    ))
                                }                                            
                            </> 
                        : <></>                                    
                    }        
                </ul>    
                <p 
                    style={{
                        marginTop: '2rem'
                    }}
                    className="card-text">
                        {`Click the link for the full story.`}
                </p>
                <p                                 
                    className="card-text">                                    
                        {`You will be taken to the third party website where the article originates from.`}
                </p>
                <small                                 
                style={{
                    marginBottom: '2rem'
                    }}
                    className="card-text">                                    
                        {`Company Repo is not responsible for the content or practices of third party websites. Company Repo is also not responsible for any information that you might share with such linked websites. You should refer to each website’s respective privacy policy and practices prior to disclosing any information.`}
                </small>                                                                
                <div 
                    style={{
                        marginTop: '2rem'
                    }}
                    className="d-flex flex-row-reverse">
                    <a href="#" className="btn btn-dark">Save this card</a>              
                </div>                
            </div>
        </div>         
      </>
  )}; 

export default function CompanyCard({isStatic, staticDataType}) {
    // how to use ocmponent?
    // if isStatic is true then we do not make api call 
    // if there is chart data ie chartData filed in return object
    //  then we set chartData in component and pass in to RenderChart
    const useReduxState = () => {
        return useSelector(
          (state) => ({                      
            loadingTypes: state.loadingTypes,
            loginTime: state.loginTime,
          }),
          shallowEqual
        )};    
    const { loadingTypes, loginTime } = useReduxState();
    const [isChart, setIsChart] = useState(false);
    const [chartData, setChartData] = useState({});  
    const [chartType, setChartType] = useState(`pie`);  
    const [dashData, setDashData] = useState({});  
    const [dashDataKeys, setDashDataKeys] = useState([]);     
    const [isLoading, setIsLoading] = useState(false);
    // ================================================================================================================================================================================================================================================  
      useEffect(() => {        
        console.log('mounted CompanyCard', loginTime, isStatic, isChart, chartData);        
        // do not fetch data if from
          if(isEmpty(dashData) && !isStatic) {
              setTimeout(() => {
                  console.log(`exec get dashboard data`);
                  setIsLoading(true);
                  // we need to detect if data is chart data or not
                  getLandingData();
              }, 3000);
          };
          console.log(`this should only log once !`, dashData, dashDataKeys);
        }, [loginTime, dashData, dashDataKeys, chartData]);
  
        console.log(`log dashData 2`, dashData, dashDataKeys);
    // ================================================================================================================================================================================================================================================          
        const getLandingData = (e) => {
          let headers = {'Content-Type': 'application/json'};
          // ADD url to env vars in cludflare in prod        
        //   let url = process.env.LANDING_DATA_URL;
          let url = `https://veqofmbg38.execute-api.us-west-1.amazonaws.com/v1/cro-public-1/dashboard.json`;
          return axios({
            method: 'get',
            headers: headers,
            url,      
            responseType: 'json',
          }).then(res => {
            console.log(`log result hit api`, res);      
            let {data, status, statusText, headers, config} = res;
            console.log(`load data into company cards`, data);  
            if(!isEmpty(data)) {
            // destructure data to see if there is chart data in object
                let {chartData, chartType} = data;
                if(!isEmpty(chartData)) {
                    console.log(`log chart data from api`, chartData, chartType)
                    setIsChart(true)
                }                
                setDashData(data)
                setDashDataKeys(Object.keys(data))
                setIsLoading(false);
            }
          }).catch(error => {      
            console.log(`log err hit api`, error, error.message);      
          }) 
      };
    // ================================================================================================================================================================================================================================================
    // component for showing server data
    const ServerData = () => {
        return (
            <>
        {
        // component for server data
        // we show this company card component ONLY if isStatic is false
        isLoading && !isStatic? 
                <>
                <Loading
                    loading={isLoading} 
                    type={`revolvingdot`}
                />
                </> :
                <>
                <div className="company-card-wrapper">                
                        {        
                            dashDataKeys.length ? 
                                <>                     
                                    {
                                        dashDataKeys.map((dataKey, index) => (
                                            <>
                                                <CompanyCardContent
                                                    indexKey={index}
                                                    dataObj={dashData[dataKey]}
                                                    // newly added or charts
                                                    isChart={isChart}
                                                    chartType={chartType} 
                                                    chartData={chartData} 
                                                />                                       
                                            </>
                                        ))
                                    }
                                </> 
                                : 
                                <></>                     
                        }
                        </div> 
                </>
        }   
            </>            
        )
    }; 
    // ================================================================================================================================================================================================================================================
    // component for showing static data
    const StaticData = ({dataType}) => {
        // leanringn points: 
        // cannot import within a componnt
        // import data here:       
        // <RenderChart/>     
            useEffect(() => {
              console.log(`mounted StaticData`, 
                appleCoMajorShareHolderBreakdown, 
                marketSummaryJP,
                marketSummaryCA, 
                marketSummaryUS,
                trendingSecInCA,                
              )              
              console.log(`log dataType`, dataType)
            }, []);
            let staticDataObj1 = {                
                title: `Markets Data`,
                marketData: [marketSummaryUS, marketSummaryCA, marketSummaryJP],                
            }
            let staticDataObj2 = {
                title: `Company Analysis`,
                companyName: `Apple Inc`,
                ticker: `AAPL`,
                chartData: {
                    data: appleCoMajorShareHolderBreakdown,
                    chartTitle: `Major Shareholders Breakdown`,
                },
            }            
        return (
            <>         
            {isStatic ? 
                <>
                <div className="company-card-wrapper">                    
                {
                    dataType === 'market' ? 
                    <>
                    <CompanyCardStaticContent 
                        dataObj={staticDataObj1}
                    />
                    </> :
                    <></> 
                }
                {
                    dataType === 'company' ? 
                    <>
                    <CompanyCardStaticContent 
                        dataObj={staticDataObj2}
                    />
                    </> :
                    <></> 
                }                                        
                </div>                   
                </>:<></>
            }                                     
            </>            
        )
    };     
                                                    
     return <>       
        <ServerData/>
        <StaticData dataType={staticDataType}/>
     </>      
};         

