import BaseService from './BaseService'
import isEmpty from 'lodash.isempty';

class APIService extends BaseService {  
  createCheckoutSession = (paymentObj) => {
    // the paymentObj will be passed into createCheckoutSession via apiMiddleware
    if(isEmpty(paymentObj)) return `empty args`;    
    console.log(`log createCheckoutSession`, paymentObj)    
    const url = `https://3bxui4yy4m.execute-api.us-west-1.amazonaws.com/Stage/payment-sub`;
    console.log(`log createCheckoutSession url`, url)
    return this.post(url, paymentObj, {});
  }
  updateUserProfile = (updateObj, url) => {
    if(isEmpty(updateObj)) return `empty args`;    
    console.log(`log updateUserProfile`, updateObj, url);
    return this.post(url, updateObj, {});
  }
}

export default new APIService();

// learning points:
// we import and use it like this: 
// import APIService from 'utils/services/APIService';
