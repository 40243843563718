import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import HelperService from './utils/services/HelperService';
const helperSer = new HelperService();

export default function DisplayQuotes({}) {
    const useReduxState = () => {
        return useSelector(
          (state) => ({                      
            loadingTypes: state.loadingTypes,
            loginTime: state.loginTime,
          }),
          shallowEqual
        )};    
    const { loadingTypes, loginTime } = useReduxState();
    const [displayQuote, setDisplayQuote] = useState(`Know what you own, and know why you own it`);
    const [displayAuthor, setDisplayAuthor] = useState(`Peter Lynch`);    
    useEffect(() => {                            
        let getQuote = helperSer.getRandomQuotes();
        console.log(`mounted DisplayQuotes`, getQuote)
        let {quote, author} = getQuote;
        setDisplayQuote(quote);
        setDisplayAuthor(author)
    }, [loginTime]);
     return <>
     {
        !loginTime ? <>
         {/* <blockquote class="blockquote text-center">
            <p style={{
                // marginBottom: '1rem',
                padding: '1rem',
                maxWidth: '50em',                
                // fontWeight: 'bold'
                // max-width: 50em; 
                // word-wrap: break-word;     
                // text-align: justify;
            }}>
                {displayQuote}
            </p>
            <footer class="blockquote-footer"><cite title="Source Title">{displayAuthor}</cite></footer>
        </blockquote> */}
            <article className="about-us">                            
                <p
                style={{
                    color: 'indigo',
                    textAlign: 'center', 
                    marginTop: '1rem',                    
                }}
                >                  
                  {displayQuote}                  
                </p>
                <p
                style={{                    
                    textAlign: 'center',                    
                    marginTop: '1rem',
                    marginBottom: '3rem',                    
                }}
                >                                    
                <footer class="blockquote-footer"><cite title="Source Title">{displayAuthor}</cite></footer>                  
                </p>
            </article>
        </>
        :
        <></>
     }    
     </>      
};         

