import React, { useEffect } from 'react';
import logo from './RU.PNG';

export const CompanyIcon = ({}) => {  
    return (
      <>       
         <div
              style={{
                // margin: '0 auto',
                marginTop: '5rem',              
              }}
            >
            <span>
            <img
                key={`business-icon-img`}
                className="business-icon-img"                 
                src={logo}
                width="50"
                height="50"
                alt="business icon"
                />  
              </span>
            </div>          
      </>
)}; 

export default function AboutUs({}) {
  useEffect(() => {
    // console.log(`mounted AboutUs`);
  },[]); 
  return (
      <>               
        {/* <div 
        style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '3rem',    
                }}
        >
            <CompanyIcon/>
        </div> */}
         <article 
            className="about-us"
            id="aboutus"
            style={{                
                marginTop: '10rem',
            }}
         >
                <h2
                style={{
                    color: 'indigo',
                    textAlign: 'center',                    
                    marginBottom: '3rem',
                    // marginTop: '2rem',
                }}
                >
                  {`About CompanyRepo`}
                </h2>
                <p
                style={{                    
                    textAlign: 'center',                    
                    marginBottom: '3rem',                    
                }}
                >                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold',                        
                    }}
                    >                        
                    {/* {` 
                        Create your eCommerce roadmap here.
                    `}   */}
                    {` 
                        CompanyRepo, a Repository of Company Product Information.
                    `}
                    </span> {' '}                    
                </p> 

                <p> 
                    { // updated on 180424
                    `
                        If you are looking to find great product deals on a wide range of products or just hoping to learn about the products before buying, then you are in the right place. 
                    `}                                                          
                    {/* {`
                        Welcome to CompanyRepo, your ultimate destination for unbeatable deals on a wide range of products! If you love saving money, you've come to the right place.
                    `} */}
                </p>
                <p>
                    { // updated on 180424
                    `
                    Our mission is to empower consumers with objective, in-depth product reviews and information. We believe that making informed purchasing decisions is crucial, so we dedicate ourselves to providing impartial information on a wide range of products across various product categories.

                    `}                      
                </p>

                <p>
                    { // updated on 180424
                    // Whether you're in the market for the latest consumer tech, home appliances, outdoor gear, or anything in between, you can count on us to deliver comprehensive, up-to-date information to guide your buying choices.
                    `
                    Whether you're in the market for the latest consumer tech, home appliances, outdoor gear, or anything in between, you can count on us to give you the straight facts to help you find the right products to meet your needs.

                    `}                      
                </p>

                

                {/* <p>                    
                    {`
                        We understand the thrill of finding incredible discounts and bargains. That's why our dedicated team scours the web to bring you the hottest deals on electronics, fashion, home decor, beauty products, and so much more.
                    `}                                       
                </p> */}

                <p>                    
                {`
                    With a simple search feature and carefully curated product lists, you can easily navigate through
                    `}                                        
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        a vast selection of products that are updated hourly.
                    `}  
                    </span> {' '}                    
                </p>

                {/* <p>                    
                {`
                    With intuitive search features and carefully curated categories, you can easily navigate through
                    `}                                        
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        a vast selection of products that are updated hourly.
                    `}  
                    </span> {' '}                    
                </p> */}

                {/* <p>
                    {`
                        Discover jaw-dropping discounts that will leave you amazed.
                    `}
                </p> */}

                <p>
                    {`
                        We pride ourselves on providing accurate and up-to-date information about deals, ensuring that you are caught up on the latest offers or special promotions from around the web.
                    `}
                </p>

                {/* <p>
                    {`
                        But that's not all! We also offer a range of helpful resources, including product reviews, buying guides, and expert tips to help you make informed purchasing decisions. Our goal is to empower you with the knowledge you need to get the best value for your hard-earned money.
                    `}
                </p> */}
                
                {/* <p>
                    {`
                        Whether you're a savvy shopper, a bargain hunter, or simply someone who loves a good deal, CompanyRepo is your go-to destination. Start exploring our website today and unlock a world of incredible savings.
                    `}
                </p> */}

                 <p>                    
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        Happy product hunting on our site - where every day is a sale day!
                    `}  
                    </span> {' '}                    
                </p>
                
                {/* <p>                    
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        Get ready to experience the thrill of finding amazing deals at CompanyRepo – where every day is a sale day!
                    `}  
                    </span> {' '}                    
                </p> */}
               
             
                {/* <p>
                    {`
                        With better product research, you can:
                    `}                                        
                </p>                                    
                <p>                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        *   Stay ahead of market trends and consumer demands
                    `}  
                    </span> {' '}                    
                </p> 

                <p>                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        *   Make data-driven decisions about product offerings
                    `}  
                    </span> {' '}                    
                </p> 

                <p>                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        *   Identify niche opportunities that cater to specific customer needs
                    `}  
                    </span> {' '}                    
                </p> 

                <p>                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        *   Differentiate your brand and stand out from your competition
                    `}  
                    </span> {' '}                    
                </p> 
                

                <p>                    
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >                        
                    {` 
                        *   Optimize your ecommerce store for increased visibility and traffic
                    `}  
                    </span> {' '}                    
                </p>                  */}

                                          
                {/* <p>                    
                    Education alone is not enough.
                    it is important to develop habits.
                    Your should focus on taking action.                    
                    No matter how small the improvement, keep going.
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold'
                    }}
                    >
                        Whether they are stepping stones or milestones, the key point is to keep moving forward.
                    </span> {' '}                    
                    And focus on achieving those short-term and long-term goals you have set for yourself. 
                </p>                              
                <p>                    
                    It all starts with self belief.                     
                    We want to provide a place where people can come to learn new skills without discrimination.
                    We hope you could use this place to share your learning journey with us if you wish to.                     
                </p> */}

                <p
                    style={{
                        marginTop: '3rem',
                    }}
                >                    
                    {`For business enquires, partnerships or sponsorships, please contact us at `}
                    {' '}
                    <span
                        style={{
                        color: 'indigo',                        
                        fontWeight: 'bold',                        
                    }}
                    >
                        {`team@companyrepo.com`}
                    </span> {' '}   
                </p>
                {/* <p>                    
                    We aim to write more motivational content in the coming year through our blog content either on this website or through our Medium blog. 
                </p> */}

                {/* <div className='d-flex column'>
                    <a        
                        style={{
                            marginBottom: '2rem'                        
                        }}                             
                        className="btn btn-warning btn-lg" href="#" role="button"
                        aria-hidden="true"
                        alt="Click to go to our Medium blog"
                        aria-label="Click to go to our Medium blog"
                        onClick={() =>
                            window.open(                                        
                                'https://medium.com/@remoteupskill',
                                "_blank"
                            )
                        }
                    >
                        Medium Blog
                    </a>
                    <a        
                        style={{
                            marginBottom: '2rem',
                            marginLeft: '1rem'
                        }}                             
                        className="btn btn-success btn-lg" href="#" role="button"
                        aria-hidden="true"
                        alt="Click to go to our Medium blog"
                        aria-label="Click to go to our Medium blog"
                        onClick={() =>
                            window.open(                                        
                                'https://remoteupskillnow.gumroad.com',
                                "_blank"
                            )
                        }
                    >
                        Books on Gumroad
                    </a>
                </div>                */}

                {/* <p>                    
                    Here are some of our more popular blogs on Medium:
                </p>      
                <ul>
                    <li 
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >
                        <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 1
                        </a>
                    </li>
                    <li
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >       
                    <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 2
                        </a>
                    </li>                    
                    <li
                        style={{
                            marginBottom: '2rem'                        
                        }}   
                    >                        
                        <a              
                        style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'                            
                        }}                                                         
                          onClick={() =>
                            window.open(                                        
                                'https://medium.com',
                                "_blank"
                            )
                        }
                        >
                            Article 3
                        </a>                 
                    </li>                                        
                </ul>                 */}
            </article>           
      </>
)}; 