// NOTES:
// https://www.educative.io/courses/beginning-nodejs-express-mongodb-development/gkXLRq69ovG
// This allows us to retrieve the results of a particular page by providing the page argument. It’s supported by MoviesDAO in the backend, where we have the following:
// cursor = await movies.find(query).limit(moviesPerPage).skip(moviesPerPage * page
// MovieDataService.getAll(currentPage)
// get results by a max of 20 
// cursor = await movies.find(query).limit(moviesPerPage).skip(moviesPerPage * page)    
// learning points:
// need a useEffect hook for each type of change

// EDGE CASE:
// what if user is just clicking around different search type?
// we would erase their page count for a particular search type
// so we need to persist searchType and its page count 
// page count !== to limitResults 
// page count affects what part of the database we are searching 
// limitResults  affects how many credits to deduct from user

// EDGE CASE:
// what if user limits results to 3 
// but we still get 20 results from db 
// we return 3 results that matches in the 20 results 
// BUT the next search will get the next 20 results 
// this means we would have missed some results from the previous 20 matching results 

// EDGE CASE:
// user can search for 20 results 
// but the results that comes back from db can be less than 20
// in that case, we will just return back whatever mongo returns 
// when the results come back we need to set a variable that it is the end of the search
// 500 is the max a user can get each day
// whatever we return we deduct from credit

// EDGE CASE:
// if the results are less than 20 
// then we need to mark that it is the end of the search
// the user does not have enough credit 
// not enough results from db returned
// the server will send a message back saying 'noMoreResults'

// EDGE CASE:
// the results returned from any search could be 0 results

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash.isempty';
import {  
    startLoading,
    finishLoading,    
    addNotification,
} from '../src/redux/actions';
import SearchService from './utils/services/SearchService';

const ShowSearchResults = ({}) => {
    // how do we pick up new search results ? 
    // we need to do the following with the results 
    // store it in the component, redux  and local storage 
    // What is the flow of receiving the JSON search results data?
    // 
    const optionList = [1,2,3];
    const selectOption = () => {
        // handle what happens when user clicks on a search result
    }
    return (
        <>        
            <div className="joblist"
                style={{                                                                        
                    width: '80%',         
                    marginTop: '2rem',           
                }}
            >             
                {optionList.length > 0 ? <>                
                    {optionList.map((option, index) => (
                    <>
                        <p
                            style={{                                    
                                borderBottom: '2px solid black',
                                width: '100%',                                
                            }}                            
                            onClick={(e) => selectOption(e, option)}
                        >   
                        <div
                        style={{                                    
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}                            
                        >
                            <>
                                <div
                                    style={{                                    
                                        display: 'flex',
                                        flexDirection: 'column',                                    
                                    }}                            
                                    >
                                        {option}                                                                                                          
                            </div>
                            </>                                                               
                        </div>
                        </p>                        
                        <div class="card" 
                        // style="width: 18rem;"
                        >
                        <div class="card-body">
                            <h5 class="card-title">Card title</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                            <a href="#" class="card-link">Card link</a>
                            <a href="#" class="card-link">Another link</a>
                        </div>
                        </div>
                    </>                    
                ))}
                </> : <></>
                }                
            </div>                              
        </>
    )
};

export default function Search({})  {
    console.log(`execute search`);    
    // =====================================================================================================================================================================
    
    const dispatch = useDispatch();        
    const [period, setPeriod] = useState([]);
    const [searchString, setSearchString] = useState("");    
    const [companyName, setCompanyName] = useState("");    
    const [searchType, setSearchType] = useState("funding"); // default is funding
    const [isCompanyProfileOnly, setIsCompanyProfileOnly] = useState(false);
    const [limitResults, setLimitResults] = useState(0);        
    const [currentPage, setCurrentPage] = useState(0); 
    const [entriesPerPage, setEntriesPerPage] = useState(0);
    const [results, setResults] = useState([]);        

    // =====================================================================================================================================================================

    useEffect(() =>{
        console.log(`log current searchType`, searchType);
    },[searchType])

    useEffect(() =>{
        console.log(`log companyName`, companyName);
    },[companyName])

    useEffect(() =>{
        // retrieveNextPage();
    }, [currentPage]);    

    // =====================================================================================================================================================================
    
    const onChangeSearchTerm = e => {
        let searchTermInput = e.target.value
        setSearchString(searchTermInput);
    };    

    const onChangeCompanyName = e => {
        let companyNameInput = e.target.value;
        setCompanyName(companyNameInput);
    };    

    const onChangeCompanyProfileOnly = () => {
        let newValue = !isCompanyProfileOnly;
        setIsCompanyProfileOnly(newValue);
    };        
    
    const onChangeSearchType = (newSearchType) => {
        // this should be executed when user finally clicks search button
        // search string okay, check if searchType is the same
        console.log(`onChangeSearchType`, newSearchType, searchType);
        // check local strage everytime user changes searchType to see if they have made this type of search before         
        if(newSearchType !== searchType) {
            setSearchType(newSearchType);
            setCurrentPage(0);
        }
    };

    // =====================================================================================================================================================================    
    // what is flow for persisting previous search results?
    // we only persist new search key if user actually makes a seach api call

    const checkPreviousSearchTypeInLocalStorage = (key) => {
        // key === searchType + keywords + companyName 
        if(!key) return {};
        const lsObj = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : {};
        console.log(`does keyExist ?`, lsObj);
        if(isEmpty(lsObj)) {
            // no previous search key return empty object
            return {};
        } else {
            // have previous search key return previous search results 
            // the typical saved search obj could look like:
            let {searchResults, previousSearchTimeStamp, pageNumber, entriesPerPage, totalResults} = lsObj;        
            // we use the timeStamp to purge previous search results periodically
            console.log(`log vals in lsObj:`, searchResults, previousSearchTimeStamp, pageNumber, entriesPerPage, totalResults);        
            // set everything back to previous search results for the previous search type            
            return 'search key in local storage';
        }
    }; 
    
    const makeSearch = (searchKey, searchObj) => {        
        // make api call to search api
        console.log(`execute make search`, searchKey, searchObj);        
        SearchService.hitSearchApi(currentPage, searchObj)
          .then(response => {
            
            console.log(`log hitSearchApi res`, response);
            
            // save search results to component 
            setResults(response.data.searchResults)
            setCurrentPage(response.data.page)
            setEntriesPerPage(response.data.entries_per_page);
            
            // dispatch search results to redux so the SearchResults component can be updated
            // dispatch(setReduxSearchResults());

            // in production we should ONLY persist the search key if an actual search has been made 
            // we need to wait for search results to come back before persisting more data to the searchKey
            // persist new search results   
            searchObj.searchResults = response.data.searchResults;          
            semiPersistentState(searchKey, searchObj);

          })
          .catch( e =>{
            console.log(e)
          })
    };
    
    // =====================================================================================================================================================================
    // search types ie search labels

    const labels = [`growth`, `funding`, `hiring`];

    // =====================================================================================================================================================================
    // on submit search    
    // build search obj / filters object for api request          
    // {
    //     "movies":[ ,20,],
    //     "page": 0,
    //     "filters":{},
    //     "entries_per_page":20,
    //     "total_results": 23530
    // }    

    const semiPersistentState = (key, value) => {        
        console.log(`log semiPersistentState`, key, value);
        if(!key) {
            return null;
        }
        if(isEmpty(value) || !value) {
            return null;
        }
        let persistObj = JSON.stringify(value);
        localStorage.setItem(key, persistObj);        
        console.log(`object persisted`, key, value);        
    };     
        
    const onSearchSubmit = e => {
        e.preventDefault();        
        // set search key when user clicks the 'Search' buttton
        // searchKey = searchType + keywords + companyName
        // need to clean search string and company name           
        let cleanedSearchString = ``;
        if(searchString) {
            cleanedSearchString = SearchService.cleanSearchString(searchString);
            console.log(`log cleanedSearchString`, cleanedSearchString);
            if(!cleanedSearchString) {
                // notify user something is wrong with search term 
                dispatch(addNotification(`Please remove symbols or special characters from your search term!`));
                return null;
            }   
        }   
        let cleanedCompanyName = ``;
        if(companyName) {
            cleanedCompanyName = SearchService.removeSymbols(companyName);
            console.log(`log cleanedCompanyName`, cleanedCompanyName);
            if(!cleanedCompanyName) {
                // notify user something is wrong with search term 
                dispatch(addNotification(`Please remove all symbols or special characters from the company name!`));
                return null;
            }
        }        
        // check for new searchKey in local storage        
        // for searchkey we need to ALSO replace spaces with the underscore symbol
        // let spaceEndRe = /^\d+\s+/;
        // keywords 875765
        // log newSearchKey funding-keywords_-company_name687878
        let spaceRe = /\s/;                
        let spaceEndRe = /\s+/;
        console.log(`log cleanedSearchString before replacing with underscore`, cleanedSearchString);
        cleanedSearchString = cleanedSearchString.replace(spaceRe, "_");
        cleanedCompanyName = cleanedCompanyName.replace(spaceRe, "_");
        cleanedSearchString = cleanedSearchString.replace(spaceEndRe, "");
        cleanedCompanyName = cleanedCompanyName.replace(spaceEndRe, "_");
        let newSearchKey = `${searchType}-${cleanedSearchString ? cleanedSearchString : `na`}-${cleanedCompanyName ? cleanedCompanyName : `na`}`;
        console.log(`log newSearchKey`, newSearchKey);
        // console.log(`log searchKey`, searchKey);
        let checkKeyRes = checkPreviousSearchTypeInLocalStorage(newSearchKey);
        console.log(`log checkKeyRes`, checkKeyRes);
        if(!isEmpty(checkKeyRes)) {
            // key already in local storage return results to user first 
            // then if user still wants more results, they can click 'load more'
        } else {
            let searchKeyValues = {
                searchString: cleanedSearchString,
                companyName: cleanedCompanyName,
                isCompanyProfileOnly: isCompanyProfileOnly,
                searchType: searchType,
                limitResults: limitResults
            };
            // setSearchKey(newSearchKey, searchKeyValues);
            semiPersistentState(newSearchKey, searchKeyValues);
        }   
        return true;     
        // build searchObj to be passed into makeSearch to hit api
        let searchObj = {
            searchString: searchString,
            isCompanyProfileOnly: isCompanyProfileOnly,
            searchType: searchType,
            limitResults: limitResults,
            // limitResults: limitResults,
            // limitResults: limitResults,
            period: period,
        }                       
        makeSearch(searchObj);
    };    

    return <>
              <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    marginTop: '1rem',
                    width: '100%'                                
                }}                
              >                                  
                <input 
                    className="form-control mr-sm-2 search-bar" 
                    type="text"                    
                    aria-label="Search by keywords"
                    placeholder="Search by keywords"
                    value={searchString}
                    onChange={onChangeSearchTerm}
                />                
              </div>   
              <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    marginTop: '1rem',
                    width: '100%'                                
                }}                
              >                                  
                <input 
                    className="form-control mr-sm-2 search-bar" 
                    type="text"                    
                    aria-label="Enter company name"                    
                    placeholder="Enter company name"
                    value={companyName}
                    onChange={onChangeCompanyName}
                />                
              </div>   
              <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginTop: '1rem',
                    marginBottom: '5rem', // set this in style sheet
                    width: '80%'                                
                }}                
              >
                {
                    labels.length > 0 ? 
                        labels.map(label => (
                            <>
                                <div
                                    style={{                    
                                        marginRight: '1rem'
                                    }}
                                    className="form-check form-check-inline"
                                >
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox" 
                                        id="inlineCheckboxex" 
                                        value="option1"
                                        checked={searchType === label ? true : false}
                                        onChange={e => onChangeSearchType(label)}
                                    />
                                    <label className="form-check-label" for="inlineCheckbox1">
                                        {label}
                                    </label>
                                </div>
                            </>
                    )) : <></>
                }
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-switch"
                >
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        id="flexSwitchCheckDefault"
                        onChange={onChangeCompanyProfileOnly}
                    />
                    <label className="form-check-label" for="flexSwitchCheckDefault">get company profile only</label>
                </div>
        </div>              

        <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    marginTop: '1rem',
                    width: '100%'
                }}                
        >     
        <button                   
            className="btn btn-success mb-3"
            type="button"   
            // onClick={() => {setCurrentPage(currentPage + 1)}}
            onClick={(e) => {onSearchSubmit(e)}}
            >                                          
                {`Search it!`}
        </button>            
        </div>        
        <div 
            style={{                                      
                justifyContent: 'center',
                marginTop: '3rem',
                marginBottom: '1rem',
            }}
            className="row"
        >       
        {`Total number of search results ${currentPage}`}
        </div>        
        <br/>
        <>
        <ShowSearchResults/>
        </>
        <br/>
        <div 
            style={{                                      
                justifyContent: 'center',
                marginTop: '3rem',
                marginBottom: '1rem',
            }}
            className="row"
        >       
        {`Showing ${currentPage} number of search results.`}
        </div>        
        <div 
                style={{
                    display: 'flex',                
                    justifyContent: 'center',
                    marginTop: '1rem',
                    width: '100%'
                }}                
        >     
        <button                   
            className="btn btn-success mb-3"
            type="button"   
            onClick={() => {setCurrentPage(currentPage + 1)}}    
            >                                          
                {/* {`Get next ${entriesPerPage} results`} */}
                {`Load more search results`}
        </button>            
        </div>        
    </>                       
}; 


{/* <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-check-inline"
                >
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                    <label className="form-check-label" for="inlineCheckbox2">2</label>
                </div> 
                
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check form-check-inline"
                >
                    <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" disabled/>
                    <label className="form-check-label" for="inlineCheckbox3">3 (disabled)</label>
                </div>
                <div 
                    style={{                    
                        marginRight: '1rem'
                    }}
                    className="form-check"
                >
                    <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                    <label className="form-check-label" for="defaultCheck1">
                        Default checkbox
                    </label>
                </div> */}

                // const useSemiPersistentState = (
                //     key,
                //     initialState,
                // ) => {
                //     const [value, setValue] = useState(      
                //       localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : initialState
                //     );  
                //     useEffect(() => {
                //       let persistObj = JSON.stringify(value);
                //       localStorage.setItem(key, persistObj);
                //     }, [value, key]);  
                //     return [value, setValue];
                // };
            
                // const [searchKey, setSearchKey] = useSemiPersistentState();