import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Modal, Container, Row } from 'react-bootstrap';
import isFilter from 'lodash.filter';
import isEmpty from 'lodash.isempty';
import {  
  removeNotification
} from './redux/actions';

export default function NotifyModal({}) {    
    const useReduxState = () => {
        return useSelector(
          (state) => ({        
            notifyMessage: state.notifyMessage,
          }),
          shallowEqual
        );
      };    
    const { notifyMessage } = useReduxState();
    const dispatch = useDispatch();
    useEffect(() => {             
      console.log('mounted NotifyModal', notifyMessage);            
    }, [notifyMessage]);  
    
    const removeNotifyMessage = () => {
        let funcName = `removeNotifyMessage`;
        try {
            dispatch(removeNotification(``, funcName));
        } catch (err) {
            console.log(`err in ${funcName}`, err);
        }
    }; 

    return <>
    {
        notifyMessage ? 
        <>
               <div 
                id="overlay"
                // learning points:
                // we dont want user to be able to close the notification by clickin the card
                // onClick={(e) => removeNotifyMessage(e)}
            >
            <div 
                id="overlayclick"
                onClick={(e) => removeNotifyMessage(e)}
            />   
            <div 
                style={{                                      
                    display: 'flex',
                    justifyContent: 'center',      
                    marginTop: '5rem',                                            
                    marginBottom: '30rem'            
                }}                
            >                  
            <div className="card w-50">
                <div className="card-body">
                    <h5 
                        style={{                                      
                            fontWeight: 'bold',
                        }}
                        className="card-title">
                            Notification Message
                    </h5>
                    <p className="card-text">{notifyMessage}</p>
                    <button 
                        className="btn btn-primary"                        
                        onClick={(e) => removeNotifyMessage(e)}
                    >Close</button>
                </div>
            </div>
        </div>
      </div>
        </> : 
        <>

        </>
    }   
    </>
};


// const ReactBootstrapModal = ({}) => {
//     return <>
//         <Modal
//             style={{
//             marginTop: '5rem',
//             }}    
//             aria-labelledby="contained-modal-title-vcenter"
//         >
//     <Modal.Header
//       style={{
//         // --indigo: #6610f2;
//         // --color-theme-7-3378-custom: #7900ff;
//         // --googleBgColor5: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
//         backgroundColor: 'var(--indigo)',
//         color: 'white',
//       }}      
//     >
//         closeButton
//       <Modal.Title id="contained-modal-title-vcenter">
//         Title
//       </Modal.Title>
//     </Modal.Header>                  
//             <Modal.Body className="show-grid">
//               <Container
//                 style={{
//                   padding: '3rem',
//                 }}
//               >            
//                 <br />
//                 <Row style={{ marginBottom: '0.5rem' }}>
//                     'Sign in with email'
//                 </Row>
//                 <Row style={{ borderBottom: 'solid 2px indigo' }} />
//                 <br />        
//                 <>             
//                     <Row style={{ marginBottom: '8rem' }}>
//                       <div
//                         style={{
//                           backgroundColor: 'var(--indigo)',
//                           color: 'white',
//                           textAlign: 'center',                      
//                           padding: '1rem',
//                           border: '2px solid var(--indigo)',
//                           borderTopLeftRadius: '0.5rem',
//                           borderTopRightRadius: '0.5rem',
//                           borderBottomRightRadius: '0.5rem',
//                           borderBottomLeftRadius: '0.5rem',
//                         }}
//                         type="button"                    
//                         // onClick={(e) => reserPassword(e)}
//                       >
//                         Reset Password
//                       </div>
//                     </Row>{' '}
//                   </>                
//               </Container>
//             </Modal.Body>        
//     <Modal.Footer>          
//     </Modal.Footer>
//   </Modal>
//     </>
// }