import firebase from 'firebase/app'
import 'firebase/analytics';
import "firebase/auth";
import "firebase/firestore"; 

const config = {         
  apiKey: "AIzaSyCYP7Lcwj77SYaqMHvCI0U1mvvRwIK1PbE",
  authDomain: "company-repo-v1.firebaseapp.com",
  projectId: "company-repo-v1",
  storageBucket: "company-repo-v1.appspot.com",
  messagingSenderId: "960216955959",
  appId: "1:960216955959:web:798fdf5ae28599f57ad3b2",
  measurementId: "G-H9HG9SP55K"
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  // Check that `window` is in scope for the analytics module!
  // Enable analytics. https://firebase.google.com/docs/analytics/get-started
  if (typeof window !== 'undefined') {    
    if ('measurementId' in config) {
      firebase.analytics()      
    }
  }
};

export default firebase;
