import FilterService from './FilterService';
const filterSer = new FilterService();

// we need to break user input into individual words before pass into filter functions 
// we clean the user input string first 
// we then use each word in user input and check it against our list of keywords 

class SearchService  {    
    
    quickSearch(filterKey, mainList, userInput) {
        return new Promise(async (resolve, reject) => {
            try {                
                let results = [];                        
                if(Array.isArray(mainList)) {
                    // at the minimum we need a product list + user input search text
                    // console.log(`log filterKey, mainList, userInput`, filterKey, mainList, userInput);
                    if(mainList.length && userInput) {
                        let regexpWordsOnly = /\b\w+\b/g; // this will only leave text + text numbers 
                        let searchText = userInput.match(regexpWordsOnly);
                        // console.log(`log user searchText`, searchText);
                        let res = null;
                        if(searchText) {
                            // check if user searchText is array type 
                            if(Array.isArray(searchText)) {
                                if(searchText.length) {
                                    if(filterKey === 'filterByCategory') {
                                        res = await filterSer.filterByCategory(mainList, searchText);
                                    }
                                    if(filterKey === 'filterByProductType') {
                                        res = await filterSer.filterByProductType(mainList, searchText);
                                    }
                                    if(filterKey === 'filterByStoreLocation') {
                                        res = await filterSer.filterByStoreLocation(mainList, searchText);
                                    }
                                    if(filterKey === 'filterByPrice') {
                                        res = await filterSer.filterByPrice(mainList, searchText);
                                    }
                                    if(filterKey === 'filterByStoreName') {
                                        res = await filterSer.filterByStoreName(mainList, searchText);
                                    }
                                    // check if res is array 
                                    // console.log(`log res`, res);
                                    if(Array.isArray(res)) {
                                        if(res.length) {
                                            results = results.concat(res);                                            
                                        }
                                    }                                    
                                }
                            }                            
                        }                                                       
                    }
                }; 
                // console.log(`log results in quick search`, results);
                return resolve(results);             
            } catch(e) {                
                console.error(e);
                reject(e);
            }        
        })        
    }; 
};

export default SearchService;
