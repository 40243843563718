export const INVESTMENT_QUOTES = {            
        "Know what you own, and know why you own it.": 'Peter Lynch',        
        "You get recession, you have stock market declines. If you don't understand that's going to happen, then you're not ready, you won't do well in the markets.": 'Peter Lynch',        
        "People who succeed in the stock market also accept periodic losses, setbacks, and unexpected occurrences. Calamitous drops do not scare them out of the game.": 'Peter Lynch',        
        "I've said before that an amateur who devotes a small amount of study to companies in an industry he or she knows something about can outperform 75% of the paid experts who manage mutual funds, plus have fun in the process.": 'Peter Lynch',
        "Amateur stock picking is a dyng art like pie baking": 'Peter Lynch',
        "Remember, things are never clear until it's too late.": 'Peter Lynch',                
        "There's no shame in losing money on a stock. Everybody does it. What is shameful is to hold on to a stock, or worse, to buy more of it when the the fundamentals are deteriorating.": 'Peter Lynch',
        "My idea of a great business is one that has a shortage of competitors": 'Peter Lynch',
        "Your investor's edge is not something you get from Wall Street experts. It's something you already have": 'Peter Lynch',
        "You can outperform the experts if you use your edge by investing in companies or industries you already understand.": 'Peter Lynch',
        "Behind every stock is a company. Find out what it's doing.": 'Peter Lynch',
        "Never invest in any company before you've done the homework on the company's earnings prospects, financial condition, competitive position, etc.": 'Peter Lynch',
        "Owning stocks is like having children. Don't get involved with more than you can handle.": 'Peter Lynch',
        "Invest in What You Know.": 'Peter Lynch',
        "This is one of the keys to successful investing: focus on the companies, not on the stocks.": 'Peter Lynch',
        "Understand the nature of the companies you own and the specific reasons for holding the stock.": 'Peter Lynch',
        "Actually Wall Street thinks just as the Greeks did. The early Greeks used to sit around for days and debate how many teeth a horse has. They thought they could figure it out by just sitting there, instead of checking the horse.": 'Peter Lynch',
        "Find something you enjoy doing and give it everything you've got, and the money will take care of itself.": 'Peter Lynch',
        "Look for small companies that are already profitable and have proven that their concept can be replicated. Be suspicious of companies with growth rates of 50 to 100 percent a year.": 'Peter Lynch',
        "When you sell in desperation, you always sell cheap.": 'Peter Lynch',
        "Big companies have small moves, small companies have big moves.": 'Peter Lynch',
        "The trick is not to learn to trust your gut feelings, but rather to discipline yourself to ignore them. Stand by your stocks as long as the fundamental story of the company hasn’t changed.": 'Peter Lynch',        
        "Moderately fast growers (20 to 25 percent) in nongrowth industries are ideal investments.": 'Peter Lynch',
        "Base your purchases on the company’s prospects, not on the president’s resume or speaking ability": 'Peter Lynch',
        "When looking at the same sky, people in mature industries see clouds where people in immature industries see pie.": 'Peter Lynch',                
        "stick with a steady and consistent performer": 'Peter Lynch',
        "You have to keep your priorities straight, if you plan to do well in stocks.": 'Peter Lynch',
        "Distrust diversifications, which usually turn out to be diworseifications.": 'Peter Lynch',
        "Invest in simple companies that appear dull, mundane, out of favor, and haven’t caught the fancy of Wall Street.": 'Peter Lynch',
        "The best way to handle a situation in which you love the company but not the current price is to make a small commitment and then increase it in the next sell-off.": 'Peter Lynch',        
        "Investing without research is like playing stud poker and never looking at the cards.": 'Peter Lynch',
        "All else being equal, invest in the company with the fewest color photographs in the annual report.": 'Peter Lynch',
        "If it's a choice between investing in a good company in a great industry, or a great company in a lousy industry, I'll take the great company in the lousy industry any day.": 'Peter Lynch',
        "Gentlemen who prefer bonds don't know what they're missing.": 'Peter Lynch',
        "The secret of his success is that he never went to business school. Imagine all the lessons he never had to unlearn.": 'Peter Lynch',
        "If you can't find any companies that you think are attractive, put your money in the bank until you discover some.": 'Peter Lynch',
        "Buy only what you understand, believe in, and intend to stick with - even when others are chasing the next miracle.": 'Peter Lynch',
        "If you don't study any companies, you have the same success buying stocks as you do in a poker game if you bet without looking at your cards.": 'Peter Lynch',
        
        "learn what you know and what you don't.": 'Warren Buffet',
        "The best thing you can do is to be exceptionally good at something": 'Warren Buffet',
        "Risk comes from not knowing what you are doing": 'Warren Buffet',
        "Wall Street is the only place that people ride to in a Rolls Royce to get advice from those who take the subway": 'Warren Buffet',
        "The best thing I did was to choose the right heroes": 'Warren Buffet',
        "A public-opinion poll is no substitute for thought.": 'Warren Buffet',
        "We enjoy the process far more than the proceeds.": 'Warren Buffet',
        "Wide diversification is only required when investors do not understand at they are doing.": 'Warren Buffet',        
        "I will tell you how to become rich. Close the doors. Be fearful when others greedy. Be greedy when others are fearful.": 'Warren Buffet',
        "The stock market is a device for transferring money from the impatient to the patient": 'Warren Buffet',
        "Wide diversification is only required when investors do not understand what they are doing.": 'Warren Buffet',
        "Remember that the stock market is a manic depressive.": 'Warren Buffet',
};