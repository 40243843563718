// we can change things to async later 

// if(filterKey === 'filterByKeywords') {                    
//     res = await filterSer.filterByKeywords(mainList, searchText);
// }
// if(filterKey === 'filterByProductType') {
//     res = await filterSer.filterByProductType(mainList, searchText);
// }
// if(filterKey === 'filterByStoreLocation') {
//     res = await filterSer.filterByStoreLocation(mainList, searchText);
// }
// if(filterKey === 'filterByPrice') {
//     res = await filterSer.filterByPrice(mainList, searchText);
// }

class FilterService {    

    filterByCategory(list, searchText) {
        return new Promise((resolve, reject) => {
            try {                
                let results = [];       
                const searchLength = 2;
                if (Array.isArray(list) && Array.isArray(searchText)) {
                    if (list.length && searchText.length) {
                        // we only care about matching everything in user search text 
                        // so when we have finihsed looping user search text we can end loop
                        for(let searchWord of searchText) {
                            if(!searchWord) continue;                            
                            // if one of the words entered by the user in search text only has 1 letter 
                            // we DO NOT perform matching 
                            if(searchLength > searchWord) continue;
                            for(let productObj of list) {
                                if(!productObj) continue;
                                // destructure everything we need ins each product obj
                                let {
                                    officialUrl, isActive, location, storeName, keyword, productTag, productUrl, currencyInfo,
                                    symbol, currencyCode, countryOfCurrency, nameOfCurrency, estimatedPrice, createdAt,
                                } = productObj;
                                // we cannot filter by category without keyword
                                // console.log(`log keyword`, keyword);
                                // compare length of keyword + the word we are currently companring of user search text 
                                // we add product tag + keyword together + store name / we should also add location
                                let searchableText = ``;
                                if(keyword) {
                                    searchableText = searchableText.concat(keyword);
                                }
                                if(productTag) {
                                    searchableText = searchableText.concat(productTag);
                                }
                                if(storeName) {
                                    searchableText = searchableText.concat(storeName);
                                }                                
                                if(!searchableText) continue;
                                let re1 = searchWord.length > searchableText ? new RegExp(`${searchableText}`, 'ig') : new RegExp(`${searchWord}`, 'ig');
                                let isMatch = searchWord.length > searchableText ? searchWord.match(re1) : searchableText.match(re1);
                                // console.log(`log isMatch`, isMatch);
                                if(isMatch) {
                                    results.push(productObj);
                                }                                
                            }
                        }
                    }
                }
                return resolve(results);
            } catch (e) {
              console.error(e);
              reject(null);
            }
        })        
    };

};

export default FilterService;