import React from 'react';
import { Audio, BallTriangle, Bars, Circles, Grid, Hearts, Oval, Puff, Rings, TailSpin, ThreeDots, 
    ThreeCircles, RotatingLines, RotatingSquare, RevolvingDot  
} from 'react-loader-spinner'; 

export default function Loading({
    // type, color, height, width, radius
    loading,     
    type,
    color,
    height, 
    width,
    radius,
}) {
// learning points:
// for this Loading component we need to import it to the individual ocmponent we want to use it in 
// this component is nopt the same as the full page component 
// the full page component will show and hide when startPageLoading and finishedPageLoading are done across the whole application 
// nothing else can be seen when the full page loading starts
// on the other hand, Loading component is affected by different types of loading in place 
// and user will be able to see which areas opf the application is loading when this happens 
// so each individual component needs to listen for their own loading type from redux in their own component
// this is why we do not need to listen to redux inside this component 

// updated Loading component on 2mar23:
const LoaderType = ({type, color, height, width, radius}) => {
    let defaultColor = "#00BFFF";
    let defaultHeight = 80; 
    let defaultWidth = 80;
    // new defaults:
    let defaultColor2 = "gray";     
    let defaultHeight2 = 200; 
    let defaultWidth2 = 100;
    let defaultRadius = 9;    
    return <>
        {
            type === 'audio' ?
            <Audio                
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'balltriangle' ? 
            <BallTriangle               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'bars' ? 
            <Bars               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'circles' ?
            <Circles               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'grid' ?
            <Grid               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'hearts' ?
            <Hearts               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'oval' ?
            <Oval               
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'puff' ?
            <Puff      
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'rings' ?
            <Rings
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'tailspin' ?
            <TailSpin
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'threedots' ?
            <ThreeDots
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }        
        {
            type === 'threecircles' ?
            <ThreeCircles
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }
        {
            type === 'rotatinglines' ?
            <RotatingLines
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }        
        {
            type === 'rotatingsquare' ?
            <RotatingSquare
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }          
        {
            type === 'revolvingdot' ?
            <RevolvingDot
                color={color ? color : defaultColor2} 
                height={height ? `${height}` : `${defaultHeight2}`} 
                width={width ? `${width}` : `${defaultWidth2}`}
                radius={radius ? `${radius}` : `${defaultRadius}`}
                ariaLabel="loading animation"
            /> : 
            <></>
        }  
    </>        
};
    return <>      
        <div className="loading">
        {
            loading ? 
                <LoaderType 
                    type={type}
                    color={color}
                    height={height}
                    width={width}        
                    radius={radius}                                        
                />
                :
                <></>                        
        }        
        </div>
    </>
}; 

