import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import AboutUs from './AboutUs';
import LandingHero from './LandingHero'; 
import Footer from './Footer';
import NotifyModal from './NotifyModal'; 
// import Dashboard from './Dashboard'; 
import Dashboard2 from './Dashboard2'; 
import CompanyCards from './CompanyCards';
import CategoryProducts from './CategoryProducts';
import CategoryProducts2 from './CategoryProducts2';
import CategoryProducts3 from './CategoryProducts3';
import SmallTilesGrid from './SmartGrid/SmallTilesGrid';


// import PrivacyPolicy from './PrivacyPolicy';
// import Terms from './Terms';

// ===========================================================================================================================================================================================
// import all static images for different components on landing page here:
// import logo from './logo.svg';
import desktopLogo from './crologo1.png';
// import crologo from './crologo1mobile.png';
import mobileLogo from './crologo1mobile2.png';
// import landingHeroImag1 from './bootstrap-themes.png';

// import landingHeroImag2 from './crolanding1.jpeg';
import landingHeroImag5 from './crolanding6mobile.jpeg';
import landingHeroImag4 from './crolanding3mobile.jpeg';
import landingHeroImag3 from './crolanding7mobile.jpeg';

// ===========================================================================================================================================================================================
// LANDING PAGE
// ===========================================================================================================================================================================================



const EmailSubBox = ({}) => {
  return (
    <>     
      <div 
      style={{
        marginTop: '4rem',
        border: '2px solid black'
      }}
      className="container">        
        <div className="row">
          <div className="col-md-4">
            <h2>Basic Profile</h2>
            <p>This is the starting point for developing a story for the companies you are interested in.</p>
            <p>This includes basic informationn about the company such as the industry, what are they operating as, the size of the company. </p>
            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
          </div>
          <div className="col-md-4">
            <h2>Latest News</h2>
            <p>The latest news related to the company can range from the latest online news articles to certain performance details related to the stock market. </p>
            <p>Rather than providing real-time data, we provide a bigger picture on how a company is doing by building up a histroy of key stock market metrics for the company</p>            
            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
          </div>
          <div className="col-md-4">
            <h2>Historical Data</h2>
            <p>
              This includes historical news articles about the company to how it has performed on the stock market in the past.              
            </p>
            <p>
            The past does not dictate how a company will perform in the future but it does tell us how a company responds to external factors.
            </p>            
            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
          </div>
        </div>        
      </div>       
    </>
  )
}; 



// ========================================================================================================================================
// different layouts
// these are the different components that go inside the layout wraps 
// the main advanatge here is to test out different combination of landing components on a layout 

const heroText1 = [  
  `* Will the product be a success in the market?`,
  `* What are similar products in the market?`,
  `* What’s the best way to develop and sell the product?`,
  `* What’s your potential selling price?`,
  `* Is your product seasonal? `,
]; 
   
const heroText2 = [
  `While it may seem tempting to jump on the bandwagon of trending products, it is important to consider niche opportunities as well.`,
  `Niches cater to specific customer needs and often have less competition compared to broader product categories.`,
  `Conduct thorough market research to identify potential niches based on your interests, passions, and existing gaps in the market.`,
  `Look for products that solve a problem or fulfill a unique need and have a target audience willing to spend money on them.`
];                     
const heroText3 = [  
  `Analyzing competition is a crucial component of product research.`,
  `By studying successful eCommerce businesses selling similar products, store owners can gain insights into their competitors' product listings, pricing strategies, customer reviews, and overall branding.`,  
  `This analysis helps identify both strengths and weaknesses, enabling businesses to position their products effectively and differentiate their brand.`,
  `Understanding the competitive landscape can guide pricing decisions, marketing campaigns, and product enhancements, giving businesses a strategic advantage.`,  
];                    

const SomeCenterText = ({}) => {
  return (
    <>
    <div 
style={{
  width: '80%',        
  margin: '0 auto',
  marginTop: '2rem',              
  // marginBottom: '2rem',
}}
>
     <blockquote       
  class="blockquote text-center">
  <p 
  style={{            
      padding: '0.5rem',                       
  }}
  >
      {`Company Repo provides a place for discovering and researching companies around the world mainly through online news articles. Company Repo provides a place for discovering and researching companies around the world mainly through online news articles.`}
  </p>        
</blockquote> 
</div>
    </>
  )}


const PromoCard1 = ({}) => {
  return (
    <>
         <blockquote 
        style={{
          marginTop: '3rem',              
          // marginBottom: '2rem',          
      }}
      class="blockquote text-center">
        <h4
          style={{            
            padding: '1rem'
        }}
        >
          {`The market is beyond your control so search out profitable companies instead.`}
        </h4>
        <footer class="blockquote-footer"><cite title="Source Title">{`something cool`}</cite></footer>        
    </blockquote>       
    <article className="about-us">     
      <p>
          {`
              At the core of our mission, we aim to provide a starting point where 
              users can discover new companies and develop the story behind each of these 
              companies through carrying out research on our platform.   
              At the core of our mission, we aim to provide a starting point where 
              users can discover new companies and develop the story behind each of these 
              companies through carrying out research on our platform.   
              At the core of our mission, we aim to provide a starting point where 
              users can discover new companies and develop the story behind each of these 
              companies through carrying out research on our platform.                                                 
          `}                                        
      </p>                    
    </article> 
    {/* <CompanyCards
        isStatic={true}
        staticDataType={`company`}
    /> */}
    </>
)}

const PromoCard2 = ({}) => {
  return (
    <>
         <blockquote 
        style={{
          marginTop: '3rem',              
          // marginBottom: '2rem',          
      }}
      class="blockquote text-center">
        <h4
          style={{            
            padding: '1rem'
        }}
        >
            {`The market is beyond your control so search out profitable companies instead.`}
        </h4>
        <footer class="blockquote-footer"><cite title="Source Title">{`something cool`}</cite></footer>        
    </blockquote>       
    <article className="about-us">     
    <p>
                    {`
                        At the core of our mission, we aim to provide a starting point where 
                        users can discover new companies and develop the story behind each of these 
                        companies through carrying out research on our platform.   
                        At the core of our mission, we aim to provide a starting point where 
                        users can discover new companies and develop the story behind each of these 
                        companies through carrying out research on our platform.   
                        At the core of our mission, we aim to provide a starting point where 
                        users can discover new companies and develop the story behind each of these 
                        companies through carrying out research on our platform.                                                 
                    `}                                        
                </p>                    
    </article> 
    <CompanyCards
        isStatic={true}
        staticDataType={`market`}
    />
    </>
)}

// ===========================================================================================================================================================================================
// LANDING PAGE LAYOUT
// ===========================================================================================================================================================================================

const Layout1 = ({  
}) => {
  useEffect(() => {                        
    // console.log('Layout1');
  }, []);          
  return <>
      <NotifyModal/>            
      
      {/* <LandingServiceIntro/>     */}

      {/* <div         
        style={{
                    display: 'flex',
                    justifyContent: 'center',
                    // marginTop: '3rem',
                }}
        >
            <img 
            id='crologo-desktop'
            style={{background: '0% 0% / cover', height: '30rem'}} className="card-img-right flex-auto d-none d-md-block" src={desktopLogo} alt="Card image cap"/>            
      </div>    

      <div      
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem',
      }}
      >
        <img 
        id='crologo-mobile'
        style={{height: '15rem'}} className="" src={mobileLogo} alt="Card image cap"/>
      </div> */}

      {/* <div 
        style={{
          width: '50%'
        }}
        className="container">
      <form>
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>         
      <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </div> */}      


      {/* <PromoCard1/>
      <blockquote class="blockquote text-center">      
        <p 
          style={{
            marginTop: '2rem',              
            marginBottom: '2rem',
            padding: '1rem'
        }}
        class="lead">
          {`First, you need to be aware of the companies. 
          Then you have to find out the story behind the companies.`}
        </p>
      </blockquote> */}

      {/* <Terms/>
      <PrivacyPolicy/>    */}
      
      {/* <blockquote class="blockquote text-center">
        <h4
          style={{
            marginTop: '2rem',              
            marginBottom: '2rem',
            padding: '1rem'
        }}
        >
          {`The market is beyond your control so search out profitable companies instead.`}
        </h4>
        <footer class="blockquote-footer"><cite title="Source Title">{`something cool`}</cite></footer>        
      </blockquote> */}

      {/* <CompanyCards
        isStatic={false}
      /> */}            

    {/* <CategoryProducts2/> */}
          
    {/* <CategoryProducts/> */}

    {/* <SmallTilesGrid props={{}}/> */}

    <CategoryProducts3/>


      <AboutUs/>      
      {/* <LandingHero 
        heroTitle={`Product Research Questions`}
        heroText={heroText1} // use this to import text form staticData.js
        imgUrl={landingHeroImag3}
        heroType={`left`} // heroType = `right` // means image is on right side
        emailBox={true}
        linkButton={true} // provide a link to something which is used in the link button        
      />             
      <LandingHero 
        heroTitle={`Identifying Niche Opportunities`} 
        heroText={heroText2} // use this to import text form staticData.js
        imgUrl={landingHeroImag4}
        heroType={`right`} 
        emailBox={false}
        linkButton={true} // provide a link to something which is used in the link button        
      />
      <LandingHero 
        heroTitle={`Analyzing Competition`} 
        heroText={heroText3} // use this to import text form staticData.js
        imgUrl={landingHeroImag5}
        heroType={`left`} 
        emailBox={false}
        linkButton={true} // provide a link to something which is used in the link button
      />               */}
      <Footer/>
  </>
};  

// ========================================================================================================================================
// layout wraps
// these are the parent layouts that dictate the overview of the layout 
// create different layout wrappers to quickly change style of app 
// see Layouts folder

const LayoutWrap1 = ({children}) => {
  useEffect(() => {                        
    // console.log('LayoutWrap1');
  }, []);          
  return <>                            
  <body
      className={`layoutwrap1`}
  >        
      {children}                                                  
  </body>
  </>
};  

// ========================================================================================================================================
// this is the component that controls the conditional rendering of layouts 
// before and after authentication

export const LandingNavigation = ({}) => {

  const toTop = () => {
    window.scrollTo(0, 0);
  };  

  const toBottom = () => {
    window.scrollTo(0, 0);
  }; 

  const scrollToItem = (item) => {
    if(item) {
      let scrollItem = `#${item}`;
      let eles = document.querySelectorAll(scrollItem); 
      let eleArr = Array.from(eles);           
      eleArr[eleArr.length-1].scrollIntoView({ behavior: "smooth" }); 
    }
  };  
  
    return <>
    <div
        style={{
          width: "fit-content",
          height: "fit-content"
        }}
        id="above-posh-ui"
      >
        <button 
        style={{
          marginRight: "1rem",
        }}
        onClick={() => scrollToItem(`searchbox`)}
        type="button" class="btn btn-light">Search</button>
        <button 
        onClick={() => scrollToItem(`aboutus`)}
        type="button" class="btn btn-dark">About Us</button>
      </div>
    </>
};

// ========================================================================================================================================

export default function FinalLayout({}) {  
    const useReduxState = () => {
        return useSelector(
          (state) => ({        
            loginTime: state.loginTime,
            profile: state.profile,
            uuid: state.uuid,
          }),
          shallowEqual
        );
      };    
    const { loginTime, profile, uuid } = useReduxState();
    useEffect(() => {
      // console.log('mounted Layout', loginTime, profile, uuid);
    }, [loginTime]);
    return <>                                                  
          <LayoutWrap1>             
            {!loginTime ? 
              <><Layout1/></> : 
              // <><Dashboard/></>
              <><Dashboard2/></>
            }
            <LandingNavigation/>
          </LayoutWrap1>
    </>
};  
