export const marketSummaryCA = {
        "marketSummaryResponse": {
            "result": [
                {
                    "fullExchangeName": "Toronto",
                    "symbol": "^GSPTSE",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709531,
                        "fmt": "11:45AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.6306503,
                        "fmt": "-0.63%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 20275.76,
                        "fmt": "20,275.76"
                    },
                    "exchangeTimezoneName": "America/Toronto",
                    "regularMarketChange": {
                        "raw": -127.86914,
                        "fmt": "-127.87"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 299511000000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 20147.89,
                        "fmt": "20,147.89"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ca_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "TSI",
                    "shortName": "S&P/TSX Composite index",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "S&P/TSX Composite index"
                },
                {
                    "fullExchangeName": "SNP",
                    "symbol": "^GSPC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709542,
                        "fmt": "11:45AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.45530325,
                        "fmt": "-0.46%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 4100.6,
                        "fmt": "4,100.60"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -18.670166,
                        "fmt": "-18.67"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": -1325583000000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 4081.93,
                        "fmt": "4,081.93"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "SNP",
                    "shortName": "S&P 500",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "S&P 500"
                },
                {
                    "fullExchangeName": "DJI",
                    "symbol": "^DJI",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709542,
                        "fmt": "11:45AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.11023261,
                        "fmt": "0.11%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 33402.38,
                        "fmt": "33,402.38"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 36.820312,
                        "fmt": "36.82"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 694362600000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 33439.2,
                        "fmt": "33,439.20"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 120,
                    "exchange": "DJI",
                    "shortName": "Dow 30",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "Dow Jones Industrial Average"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "CADUSD=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709542,
                        "fmt": "4:45PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.03346061,
                        "fmt": "-0.03%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "currency": "USD",
                    "regularMarketPreviousClose": {
                        "raw": 0.7437931,
                        "fmt": "0.7438"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": -0.000248909,
                        "fmt": "-0.0002"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 0,
                    "firstTradeDateMilliseconds": 1063753200000,
                    "exchangeTimezoneShortName": "BST",
                    "regularMarketPrice": {
                        "raw": 0.74354416,
                        "fmt": "0.7435"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "marketState": "REGULAR",
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "sourceInterval": 15,
                    "exchange": "CCY",
                    "region": "US",
                    "shortName": "CAD/USD",
                    "triggerable": false,
                    "longName": "USD/CAD"
                },
                {
                    "fullExchangeName": "NY Mercantile",
                    "symbol": "CL=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "headSymbolAsString": "CL=F",
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708942,
                        "fmt": "11:35AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.30975097,
                        "fmt": "-0.31%"
                    },
                    "headSymbol": true,
                    "quoteType": "FUTURE",
                    "typeDisp": "Futures",
                    "contractSymbol": false,
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 80.71,
                        "fmt": "80.71"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.25,
                        "fmt": "-0.25"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": 967003200000,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 80.46,
                        "fmt": "80.46"
                    },
                    "customPriceAlertConfidence": "NONE",
                    "market": "us24_market",
                    "exchange": "NYM",
                    "sourceInterval": 30,
                    "region": "CA",
                    "shortName": "Crude Oil",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "CCC",
                    "symbol": "BTC-CAD",
                    "gmtOffSetMilliseconds": 0,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709526,
                        "fmt": "3:45PM UTC"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.06960333,
                        "fmt": "0.07%"
                    },
                    "quoteType": "CRYPTOCURRENCY",
                    "typeDisp": "Cryptocurrency",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 37855.242,
                        "fmt": "37,855.24"
                    },
                    "exchangeTimezoneName": "UTC",
                    "regularMarketChange": {
                        "raw": 26.253906,
                        "fmt": "26.25"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 1410912000000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "UTC",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 37744.344,
                        "fmt": "37,744.34"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ccc_market",
                    "quoteSourceName": "CoinMarketCap",
                    "sourceInterval": 15,
                    "exchange": "CCC",
                    "shortName": "Bitcoin CAD",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "Bitcoin CAD"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^CMC200",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709467,
                        "fmt": "11:44AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.4960124,
                        "fmt": "-1.50%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 634.2952,
                        "fmt": "634.30"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -9.489136,
                        "fmt": "-9.49"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 1546266600000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 624.8061,
                        "fmt": "624.81"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "NIM",
                    "shortName": "CMC Crypto 200",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "CMC Crypto 200 Index by Solacti"
                },
                {
                    "fullExchangeName": "COMEX",
                    "symbol": "GC=F",
                    "gmtOffSetMilliseconds": -14400000,
                    "headSymbolAsString": "GC=F",
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708942,
                        "fmt": "11:35AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -0.16680907,
                        "fmt": "-0.17%"
                    },
                    "headSymbol": true,
                    "quoteType": "FUTURE",
                    "typeDisp": "Futures",
                    "contractSymbol": false,
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 2038.2,
                        "fmt": "2,038.20"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -3.3999023,
                        "fmt": "-3.40"
                    },
                    "cryptoTradeable": false,
                    "exchangeDataDelayedBy": 10,
                    "firstTradeDateMilliseconds": 967608000000,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 2034.8,
                        "fmt": "2,034.80"
                    },
                    "customPriceAlertConfidence": "NONE",
                    "market": "us24_market",
                    "quoteSourceName": "Delayed Quote",
                    "exchange": "CMX",
                    "sourceInterval": 15,
                    "region": "CA",
                    "shortName": "Gold",
                    "triggerable": false
                },
                {
                    "fullExchangeName": "Chicago Options",
                    "symbol": "^RUT",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708640,
                        "fmt": "11:30AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.4310466,
                        "fmt": "-1.43%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 1769.6548,
                        "fmt": "1,769.65"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -25.324585,
                        "fmt": "-25.32"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 558279000000,
                    "exchangeDataDelayedBy": 15,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 1744.3302,
                        "fmt": "1,744.33"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "WCB",
                    "shortName": "Russell 2000",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "Russell 2000"
                },
                {
                    "fullExchangeName": "ICE Futures",
                    "symbol": "^TNX",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708633,
                        "fmt": "11:30AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.8279924,
                        "fmt": "-1.83%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 3.337,
                        "fmt": "3.3370"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -0.06100011,
                        "fmt": "-0.0610"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": -252356400000,
                    "exchangeDataDelayedBy": 10,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 3.2759998,
                        "fmt": "3.2760"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us24_market",
                    "priceHint": 4,
                    "sourceInterval": 30,
                    "exchange": "NYB",
                    "shortName": "10-Yr Bond",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "Treasury Yield 10 Years"
                },
                {
                    "fullExchangeName": "Nasdaq GIDS",
                    "symbol": "^IXIC",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709540,
                        "fmt": "11:45AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.3390689,
                        "fmt": "-1.34%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 12126.327,
                        "fmt": "12,126.33"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": -162.37988,
                        "fmt": "-162.38"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 34612200000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 11963.947,
                        "fmt": "11,963.95"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Free Realtime Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "NIM",
                    "shortName": "Nasdaq",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "NASDAQ Composite"
                },
                {
                    "fullExchangeName": "Chicago Options",
                    "symbol": "^VIX",
                    "gmtOffSetMilliseconds": -14400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708631,
                        "fmt": "11:30AM EDT"
                    },
                    "regularMarketChangePercent": {
                        "raw": 4.3684206,
                        "fmt": "4.37%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 19,
                        "fmt": "19.00"
                    },
                    "exchangeTimezoneName": "America/New_York",
                    "regularMarketChange": {
                        "raw": 0.8299999,
                        "fmt": "0.83"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 631290600000,
                    "exchangeDataDelayedBy": 15,
                    "exchangeTimezoneShortName": "EDT",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 19.83,
                        "fmt": "19.83"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "us_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "WCB",
                    "shortName": "Vix",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "CBOE Volatility Index"
                },
                {
                    "fullExchangeName": "FTSE Index",
                    "symbol": "^FTSE",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680708599,
                        "fmt": "4:29PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.24965629,
                        "fmt": "0.25%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 7634.52,
                        "fmt": "7,634.52"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": 19.060059,
                        "fmt": "19.06"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 441964800000,
                    "exchangeDataDelayedBy": 15,
                    "exchangeTimezoneShortName": "BST",
                    "marketState": "POST",
                    "regularMarketPrice": {
                        "raw": 7653.58,
                        "fmt": "7,653.58"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "gb_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 15,
                    "exchange": "FGI",
                    "shortName": "FTSE 100",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "FTSE 100"
                },
                {
                    "fullExchangeName": "Osaka",
                    "symbol": "^N225",
                    "gmtOffSetMilliseconds": 32400000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680675303,
                        "fmt": "3:15PM JST"
                    },
                    "regularMarketChangePercent": {
                        "raw": -1.6762227,
                        "fmt": "-1.68%"
                    },
                    "quoteType": "INDEX",
                    "typeDisp": "Index",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 28287.42,
                        "fmt": "28,287.42"
                    },
                    "exchangeTimezoneName": "Asia/Tokyo",
                    "regularMarketChange": {
                        "raw": -474.16016,
                        "fmt": "-474.16"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": -157420800000,
                    "exchangeDataDelayedBy": 20,
                    "exchangeTimezoneShortName": "JST",
                    "marketState": "PREPRE",
                    "regularMarketPrice": {
                        "raw": 27813.26,
                        "fmt": "27,813.26"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "jp_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 2,
                    "sourceInterval": 20,
                    "exchange": "OSA",
                    "shortName": "Nikkei 225",
                    "region": "CA",
                    "triggerable": false,
                    "longName": "Nikkei 225"
                },
                {
                    "fullExchangeName": "CCY",
                    "symbol": "CADEUR=X",
                    "gmtOffSetMilliseconds": 3600000,
                    "language": "en-US",
                    "regularMarketTime": {
                        "raw": 1680709461,
                        "fmt": "4:44PM BST"
                    },
                    "regularMarketChangePercent": {
                        "raw": 0.29472065,
                        "fmt": "0.29%"
                    },
                    "quoteType": "CURRENCY",
                    "typeDisp": "Currency",
                    "tradeable": false,
                    "regularMarketPreviousClose": {
                        "raw": 0.6786,
                        "fmt": "0.6786"
                    },
                    "exchangeTimezoneName": "Europe/London",
                    "regularMarketChange": {
                        "raw": 0.0019999743,
                        "fmt": "0.0020"
                    },
                    "cryptoTradeable": false,
                    "firstTradeDateMilliseconds": 1110931200000,
                    "exchangeDataDelayedBy": 0,
                    "exchangeTimezoneShortName": "BST",
                    "marketState": "REGULAR",
                    "regularMarketPrice": {
                        "raw": 0.6806,
                        "fmt": "0.6806"
                    },
                    "customPriceAlertConfidence": "LOW",
                    "market": "ccy_market",
                    "quoteSourceName": "Delayed Quote",
                    "priceHint": 4,
                    "sourceInterval": 15,
                    "exchange": "CCY",
                    "shortName": "CAD/EUR",
                    "region": "US",
                    "triggerable": false,
                    "longName": "CAD/EUR"
                }
            ],            
        }    
}