import React, {useEffect, useState} from 'react'; 
import { COMPONENT_STATIC_DATA } from './data/staticData';
// const jumbotronProps = COMPONENT_STATIC_DATA.jumbotronProps
// const footerProps = COMPONENT_STATIC_DATA.footerProps

export default function Footer() {                     
        let {        
            businessName,
            copyrightYears,
            legal,
            knowledgeHub,
            features,
            social
        } = COMPONENT_STATIC_DATA.footerProps;        
        const goToFooterLink = (name, url) => {                          
          if(name === 'Twitter') return window.open(url);                     
          window.open(
                `${url}`,
                "_blank"
          );           
        }; 
        useEffect(() => {            
        }, []);
       return  <>        
       <footer 
         className="container py-5 mb-5"                  
       >
         <div className="row">
           <div className="col-12 col-md">
             {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="d-block mb-2"><circle cx="12" cy="12" r="10"></circle><line x1="14.31" y1="8" x2="20.05" y2="17.94"></line><line x1="9.69" y1="8" x2="21.17" y2="8"></line><line x1="7.38" y1="12" x2="13.12" y2="2.06"></line><line x1="9.69" y1="16" x2="3.95" y2="6.06"></line><line x1="14.31" y1="16" x2="2.83" y2="16"></line><line x1="16.62" y1="12" x2="10.88" y2="21.94"></line></svg> */}
             <span>{businessName}</span>
             <small className="d-block mb-3 text-muted">
               &copy; {copyrightYears}
             </small>
           </div>
          
           {/* <div className="col-6 col-md">
             <h5>Features</h5>
             <ul className="list-unstyled text-small">             
             {
               features.length > 0 ? features.map((link, index) =>
                       <li
                       style={{                          
                        // color: '#007bff',
                        cursor: 'pointer',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem'
                      }}
                       key={index}
                       onClick={() => goToFooterLink(link.name, link.url)}
                       >{link.name}</li>
                   ) : <></>
             }                                    
             </ul>
           </div> */}

           {/* <div className="col-6 col-md">
             <h5>Knowledge Hub</h5>             
             <ul className="list-unstyled text-small">                    
               {
                 knowledgeHub.length > 0 ? knowledgeHub.map((link, index) =>
                     <li
                     style={{                          
                      // color: '#007bff',
                      cursor: 'pointer',
                      marginTop: '0.5rem',
                      marginBottom: '0.5rem'
                    }}
                     key={index}
                     onClick={() => goToFooterLink(link.name, link.url)}
                     >                                                
                     {link.name}                                             
                     </li>                                                
                 ) : <></>
               }                                
             </ul>
           </div> */}
           
           {/* <div className="col-6 col-md">
             <h5>Legal & Privacy</h5>
             <ul className="list-unstyled text-small">                               
                {
                   legal.length > 0 ? legal.map((link, index) =>
                       <li
                        style={{                          
                          // color: '#007bff',
                          cursor: 'pointer',
                          marginTop: '0.5rem',
                          marginBottom: '0.5rem'
                        }}
                         key={index}
                         onClick={() => goToFooterLink(link.name, link.url)}
                       >{link.name}                                      
                       </li>
                   ) : <></>
                 }          
             </ul>
           </div> */}
           <div className="col-6 col-md">
             {/* <h5>Social Media</h5> */}
             <h5>Contact Us</h5>
             <ul className="list-unstyled text-small">
             {
               social.length > 0 ? social.map((link, index) =>
                 <li
                 key={index}
                 onClick={() => goToFooterLink(link.name, link.url)}
                 >

                   {
                   link.name === 'Twitter' ?                             
                   <i 
                   style={{
                     fontSize: '2rem',
                     color: '#007bff',
                     cursor: 'pointer',
                     marginTop: '1rem',
                     marginBottom: '1rem'
                   }}
                   className="fa fa-twitter" aria-hidden="true"></i> : <>
                   <small>
                   {link.name}
                   </small> 
                 </>                                                                                  
                   }
                   
                   </li>
                   ) : 
                   <>                            
                   </>
             }                     
             </ul>
           </div>
         </div>
       </footer>
   </>;      
};         