import axios from 'axios'
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import { loadStripe } from '@stripe/stripe-js';
import APIService from '../utils/services/APIService';
import ValidateService from '../utils/services/ValidateService';
import {  
  startLoading,
  finishLoading,
  startPageLoading,  
  finishPageLoading,
  addNotification,
  response401,
} from '../redux/actions';

const STRIPE_PUBLISHABLE_DEV_KEY = 'pk_test_'; // CRO
const STRIPE_PUBLISHABLE_PROD_KEY = 'pk_live_51Hw43gDdmiUkeaWODue8r94YUiWmXd2eumzPql2BWtYc4efNPXaulKYTdNRrTrTMebjfcirlTUXkYfxnd8mPfMwE00DJPSjVn9'; // CRO

const apiMiddleware = ({ getState, dispatch }) => next => action => {
  
  // ====================================================================================================================================================================================
  // standard api middleware checks
  // ====================================================================================================================================================================================  
  console.log(`log apiMiddleware`, getState());    
  // ensure we are in the browser 
  if(typeof window === 'undefined') {    
    return next(action)
  }
  // ensure we have internet connection    
  if(!window.navigator.onLine) {    
    return next(action)
  }    
  const { type, payload, meta } = action;  
  if (type !== 'API') {
    return next(action)
  }     
  if (isEmpty(meta)) {
    return next(action)
  }
  const { label } = meta;  
  console.log(`log internet connection status`, window.navigator.onLine); 
  console.log(`log action`, type, payload, meta, action, label);

  // ====================================================================================================================================================================================
  // MAKE_PAYMENT
  // ====================================================================================================================================================================================  
    if (label === 'makePayment') {      
      console.log(`execute ${label}`);
        dispatch(startPageLoading(label));        
        // ====================================================================================================================================================================================
        // validate data here:
        
        // ====================================================================================================================================================================================
        // make api call here:
        APIService.createCheckoutSession(payload)
          .then(async res => {              
            // ====================================================================================================================================================================================
            // old method
            // if (res.data) {
            //   let id = await res.data.id;
            //   const result = await stripe.redirectToCheckout({
            //     sessionId: id,
            //   });
            //   if (result.error) {
            //     //console.log(`log err ${result.error.message}`);
            //     alert('Something went wrong. Please refresh the page and try again!');
            //   }
            // }
            // ====================================================================================================================================================================================
            // caused errs as response is res.data so no need to do response.data.id
            let { id } = res.data;
            console.log(`log createCheckoutSession res`, res, id);              
              const stripe = await loadStripe(STRIPE_PUBLISHABLE_PROD_KEY);          
              if (!isEmpty(id)) {            
                dispatch(finishPageLoading(label));
                stripe.redirectToCheckout({
                  sessionId: id,
                }).then(res2 => {
                  // user would have been redirect to stripe checkout page 
                  dispatch(finishPageLoading(label));
                  console.log(`does this log after user has come back to or app?`);
                  console.log(`log result after redirecting user to stripe checkout page:`, res2);
                }).catch(error => {
                  dispatch(finishPageLoading(label));
                  console.log(`log err after redirectToCheckout`, error, error.message);
                  // alert('Something went wrong. Please refresh the page and try again!');
                  // dispatch(failure('searchPeople', BaseService.getError(error)))
                  // dispatch(success('searchPeople', response))                
                })                          
              }
            }).catch(error => {
              dispatch(finishPageLoading(label));
              console.log(`log err in createCheckoutSession`, error, error.message);
              // dispatch(failure('searchPeople', BaseService.getError(error)))
            });  
    }; 
    
    // ====================================================================================================================================================================================
    // UPDATE_USER_PROFILE
    // ====================================================================================================================================================================================    
    // handle user profile updates        
    if (label === 'updateUserProfile') {      
      const { url, delay } = meta;  
      console.log(`execute updateUserProfile ${label} !!!`, url, delay);
      if(isEmpty(url) || delay < 0) {
        return next(action);
      }
      // ====================================================================================================================================================================================
      let cleanedDataObj = ValidateService.whiteListDataObj(payload);
      console.log(`log cleanedDataObj in api middleware`, cleanedDataObj);
      let validationRes = ValidateService.validateObjRecursiveWrapper(cleanedDataObj);    
      console.log(`result returned to api middleware`, validationRes);
      // ====================================================================================================================================================================================
      // dispatch redux actions to clear previous error data fields 
      // dispatch redux actions to notify user
      // if everything ok validateMsg, validateKey, dataType, === undefined
      let {res, validateMsg, validateKey, dataType, dataObj} = validationRes;      
      if(!res) {        
        console.log(`an error was picked up by validation`, res, validateMsg, validateKey, dataType, dataObj);
        return next(action);
      }   
      // ====================================================================================================================================================================================
      // last check before hitting api
      if(isEmpty(dataObj)) return next(action);
      // ====================================================================================================================================================================================
      // make api call here:            
      setTimeout(() => {
            console.log(`make api call`);            
            APIService.updateUserProfile(dataObj, url)
            .then(async res => {
                console.log(`log res in updateUserProfile`, res);
                dispatch(finishLoading(`completedUserPrefFlow`));
                dispatch(addNotification(`Your preferences have been saved!`));
              })
              .catch(error => {
                dispatch(finishLoading(`completedUserPrefFlow`));
                dispatch(addNotification(`Your preferences have been saved!`));
                dispatch(finishPageLoading(label));                
                console.log(`log err in updateUserProfile`, error, error.message);                
              });
      }, delay)
      // ====================================================================================================================================================================================
      return next(action);
    }

    // ====================================================================================================================================================================================
    // UPDATE_USER_PREF
    // ====================================================================================================================================================================================    
    // learning points:
    // integers, truthy and falsy are all consided to be empty
    // for isEmpty cannot use
    // learning points:
    // in middleware we should make vars in each section as unique as possible
    if (label === 'updateUserPref') {
      const { url, delay } = meta;
      console.log(`execute ${label}`, url, delay);      
      if(isEmpty(url) || delay < 0) {
        return next(action);
      }
      // ====================================================================================================================================================================================      
      // validate data here:
      let cleanedDataObj = ValidateService.whiteListDataObj(payload);
      console.log(`log cleanedDataObj in api middleware`, cleanedDataObj);
      let validationRes = ValidateService.validateObjRecursiveWrapper(cleanedDataObj);    
      console.log(`result returned to api middleware`, validationRes);
      // ====================================================================================================================================================================================      
      // if everything ok validateMsg, validateKey, dataType, === undefined
      let {res, validateMsg, validateKey, dataType, dataObj} = validationRes;      
      if(!res) {        
        console.log(`an error was picked up by validation`, res, validateMsg, validateKey, dataType, dataObj);
        return next(action);
      }   
      // ====================================================================================================================================================================================
      // validation was susccessful so not we extract the data we need from the cleanedDataObj
      // extract just userPref            
      let { userPref, firebaseID } = cleanedDataObj;
      let { 
        companyType, companyLocation, sector, operationType,
        staffSize, revenueSize, operationKeywords, otherKeywords,
        companyNames, companyDomains,
       } = userPref;      
      let updateUserPrefObj = {
        firebaseID, companyType, companyLocation, sector, operationType,
        staffSize, revenueSize, operationKeywords, otherKeywords, 
        companyNames, companyDomains,
      };            
      console.log(`log ready to make api call with data:`, updateUserPrefObj);
      // ====================================================================================================================================================================================
      // make api call here:      
          setTimeout(() => {
            console.log(`made api call`);                    
            APIService.updateUserProfile(updateUserPrefObj, url)
              .then(async res => {
                console.log(`log res in updateUserProfile`, res);
                dispatch(finishLoading(`completedUserPrefFlow`));
                dispatch(addNotification(`Your preferences have been saved!`));
              })
              .catch(error => {
                dispatch(finishLoading(`completedUserPrefFlow`));
                dispatch(addNotification(`Your preferences have been saved!`));
                dispatch(finishPageLoading(label));
                console.log(`log err in updateUserProfile`, error, error.message);
                // dispatch(failure('searchPeople', BaseService.getError(error)))
              });
      }, delay)
      // ====================================================================================================================================================================================
      return next(action);
    }

    // ====================================================================================================================================================================================
    // GET_DASHBOARD_DATA
    // ====================================================================================================================================================================================
    // handle user profile updates
    if (label === 'getDashboardData') {
      console.log(`execute ${label}`);
      return next(action);
    }
    // ====================================================================================================================================================================================
    // go to next middleware     
    return next(action);        
};  

export default apiMiddleware;